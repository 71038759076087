export default {
    ////--------------------------------------------Live configuration-------------------------------------------///
    USER_API:process.env.VUE_APP_USER_API || "https://webapi.protrader.com.au/",
    CHARTING_API: process.env.VUE_APP_CORE || "https://core.protrader.com.au/api/v1",

    ////-------------live stripe price ids-------------
    Monthly_priceId:process.env.VUE_APP_MONTHLY_PRICE ||"price_1OCCGXG6YGnUUug3EU4QcoxV",
    Yearly_priceId: process.env.VUE_APP_YEARLY_PRICE || "price_1OCCGXG6YGnUUug3lbzjuNIh",


    // ////--------------------------------------------Developer configuration-------------------------------------------///
    // USER_API:process.env.VUE_APP_USER_API || "http://localhost:9090",
    // CHARTING_API: process.env.VUE_APP_CORE || "http://localhost:5000/api/v1",

    // ////-------------test stripe price ids-------------
    // Monthly_priceId:"price_1OKEcwG6YGnUUug3mkoZ0McK",
    // Yearly_priceId:"price_1OKEgkG6YGnUUug3fZfAGEdD",


}