<template>
  <guru-base-modal
  class="fontSize"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    header="Pocket Pivot"
    :valid="isValid"
    :scanType="scanType"
    id="pocketPivot"
    :isLoading="isLoading"
  >
  <form @submit.prevent>
    <div class="input-container fontSize">
      <span>
        Find pocket pivot with period of
      </span>
      <input
        class="small-input"
        type="number"
        min="1"
        v-model="settings.period"
        @keypress="preventDecimal"
      />
    </div>
    </form>
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'PocketPivot',
  methods: {
    ...mapActions([
      'setPocketPivotScanSettings',
      'sendPocketPivotScanRequest',
      'cancelGuruScans',
      'saveguruScanRequest',
      'getUserScanList'
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendPocketPivotScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
    },
    async saveScanSettings(name) {
      await this.saveguruScanRequest({
        scanName:name,
        scanType:'pocketPivot'
      })
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
  },
  data() {
    return {
      scanType:"pocketPivotScan",
      isLoading: false,
      settingsData: {
        period: 5
      }
    }
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return this.settings.period > 0
    },
    settings: {
      get() {
        return this.gurusScanSettings.pocketPivot || this.settingsData
      },
      set(value) {
        this.setPocketPivotScanSettings(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container{
  font-size: 13.5px;
}
.fontSize{
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width:870px){
.fontSize{
  font-size: 11px;
  margin-bottom: 5px;
}
}
@media screen and (max-width:510px){
.fontSize{
  font-size: 10px;
  margin-bottom: 7px;
}
}
</style>
