<template>
  <dialog-box
  class="fontSize"
    modalHeader="Trade Planner"
    :open="open"
    @onClose="onClose"
    @ok="onClose"
    size="small"
  >
    <div style="height: 20vh; width: 40vw; padding: 8px; " class="tradeplanner-font">
      <sui-form :loading="loading"  class="fontSize">
        <sui-form-field  class="fontSize">
          <label :style="themeFontColor" class="tradeplanner-font" style="font-size:14px">Create a new trade plan</label>
          <sui-form-field>
            <sui-button negative @click.prevent="isPlannerDialogOpened = true"
                        :style="{...themeFontColor,...themeSecondaryColor}" style="font-size:14px"
              >Create a plan</sui-button
            >
          </sui-form-field>
        </sui-form-field>
        <div v-if="getSavedTradePlans.length">
          <h3 style="margin-bottom: 8px">Saved Plans:</h3>
        </div>
        <!-- past plans -->
        <div
          style="display: flex; justify-content: space-between"
          v-for="plan of getSavedTradePlans"
          :key="plan.name"
        >
          <h4>{{ plan.name }}</h4>
          <div>
            <sui-button
              icon="download"
              color="green"
              @click.prevent="() => generatePDF(plan)"
            />
            <sui-button
              icon="delete"
              negative
              @click.prevent="() => deletePlan(plan.name)"
            />
          </div>
        </div>
      </sui-form>
    </div>
    <trade-planner-stepper-dialog
      :open="isPlannerDialogOpened"
      @onClose="isPlannerDialogOpened = false"
    />

    <div ref="document" style="display: none" v-if="selectedTradePlan !== null">
      <div style="text-align: center">
        <h2 style="color: red">Trading Plan</h2>
        <h3 style="color: #aaa">{{ selectedTradePlan.name }}</h3>
        <div>{{ selectedTradePlan.date }}</div>
      </div>
      <!-- account row -->
      <div>
        <h3 style="color: red">Account</h3>
        <div style="padding-left: 16px">
          <div>
            Account Balance:
            <b>{{
              selectedTradePlan.plan.accountBalanceData.accountBalance
            }}</b>
          </div>
          <div>
            Stock Code:
            <b>{{ selectedTradePlan.plan.accountBalanceData.stockCode }}</b>
          </div>
          <div>
            Current Price:
            <b>{{ selectedTradePlan.plan.accountBalanceData.currentPrice }}</b>
          </div>
          <div>
            Purchase Price:
            <b>{{ selectedTradePlan.plan.accountBalanceData.purchasePrice }}</b>
          </div>
        </div>
      </div>
      <br />
      <!-- position size row -->
      <div>
        <h3 style="color: red">Poistion Size</h3>
        <div style="padding-left: 16px">
          <div>
            Entry Price:
            <b>{{ selectedTradePlan.plan.positionSize.entryPrice }}</b>
          </div>
          <div>
            Stop Loss: <b>{{ selectedTradePlan.plan.positionSize.stopLoss }}</b>
          </div>
          <div>
            Risk per share:
            <b>{{
              riskPerShare
            }}</b>
          </div>
          <div>
            Portfolio Risk(%):
            <b>{{ portfolioRisk }}</b>
          </div>
          <div>
            Portfolio Risk($):
            <b>{{
              portfolioRiskInCash
            }}</b>
          </div>
          <div>
            Trade Risk($):
            <b>{{
              tradeRiskInCash
            }}</b>
          </div>
          <div>
            Position Size:
            <b>{{ selectedTradePlan.plan.positionSize.positionSize }}</b>
          </div>
          <div>
            Cost of a position:
            <b>{{ selectedTradePlan.plan.positionSize.costOfPosition }}</b>
          </div>
        </div>
      </div>
      <br />

      <!-- entry signal row -->
      <div>
        <h3 style="color: red">Entry Signal</h3>
        <div style="padding-left: 16px">
          <div v-if="selectedTradePlan.plan.entrySignalData.darvas">Darvas</div>
          <div v-if="selectedTradePlan.plan.entrySignalData.ascendingTriangle">
            Ascending Triangle
          </div>
          <div v-if="selectedTradePlan.plan.entrySignalData.breakResistance">
            Break Resistance
          </div>
          <div v-if="selectedTradePlan.plan.entrySignalData.newsletter">
            Newsletter
          </div>
          <div v-if="selectedTradePlan.plan.entrySignalData.other">
            Other
          </div>
        </div>
      </div>
      <br />

      <!-- confirmation signal row -->
      <div>
        <h3 style="color: red">Confirmation Signal</h3>
        <div style="padding-left: 16px">
          <div v-if="selectedTradePlan.plan.confirmationSignalData.obv">
            OBV
          </div>
          <div v-if="selectedTradePlan.plan.confirmationSignalData.marketDepth">
            Market Depth
          </div>  
          <div v-if="selectedTradePlan.plan.confirmationSignalData.other">
            Other
          </div>  
        </div>
      </div>
      <br />

      <!-- Risk management signal row -->
      <div>
        <h3 style="color: red">Risk Management</h3>
        <div style="padding-left: 16px">
          <div v-if="selectedTradePlan.plan.riskManagementSignalData.atr">
            ATR
          </div>
          <div v-if="selectedTradePlan.plan.riskManagementSignalData.pctValue">
            % Value
          </div>
          <div v-if="selectedTradePlan.plan.riskManagementSignalData.dataPoint">
            Data Points
          </div>
          <div
            v-if="selectedTradePlan.plan.riskManagementSignalData.supportLevel"
          >
            Support Level
          </div>
          <div
            v-if="selectedTradePlan.plan.riskManagementSignalData.other"
          >
          Other
          </div>
        </div>
      </div>
      <br />

      <!-- Exit signal row -->
      <div>
        <h3 style="color: red">Exit Signal</h3>
        <div style="padding-left: 16px">
          <div v-if="selectedTradePlan.plan.exitSignalData.gapFilling">
            Gap filling
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.patternTarget">
            Pattern Target
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.movingAverageBreach">
            Moving Average Breach
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.movingAverageCrossover">
            Moving Average Crossover
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.breakOfSupport">
            Break of Support
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.breakOfResistance">
            Break of Resistance
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.fibonacciTarget">
            Fibonacci Target
          </div>
          <div v-if="selectedTradePlan.plan.exitSignalData.other">
            Other
          </div>
        </div>
      </div>
    </div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import TradePlannerStepperDialog from './TradePlannerStepperDialog.vue'
import { mapGetters, mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'

export default {
  name: 'TradePlanner',
  components: {
    DialogBox,
    TradePlannerStepperDialog
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['deletePlan']),
    onClose() {
      this.$emit('change', !open)
    },
    generatePDF(plan) {
      this.loading = true
      this.selectedTradePlan = plan
      this.$nextTick(() => {
        const clonedNode = this.$refs.document.cloneNode(true)
        clonedNode.style.display = 'block'
        html2pdf(clonedNode, {
          margin: [1, 1],
          filename: `${plan.name}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        }).then(() => (this.loading = false))
        clonedNode.remove()
      })
    }
  },
  data() {
    return {
      isPlannerDialogOpened: false,
      selectedTradePlan: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getSavedTradePlans','themeFontColor','themeSecondaryColor']),
    portfolioRisk(){
      return this.selectedTradePlan.plan.positionSize.portFolioRisk
    },
    portfolioRiskInCash() {
      return (this.selectedTradePlan.plan.accountBalanceData.accountBalance *
                this.selectedTradePlan.plan.positionSize.portFolioRisk *
                0.01).toFixed(3)
    },
    riskPerShare(){
      return (this.selectedTradePlan.plan.positionSize.entryPrice -
                this.selectedTradePlan.plan.positionSize.stopLoss).toFixed(3)
    },
    tradeRiskInCash() {
      return (this.selectedTradePlan.plan.positionSize.positionSize *
                (this.selectedTradePlan.plan.positionSize.entryPrice -
                  this.selectedTradePlan.plan.positionSize.stopLoss)).toFixed(3)
    }
  }
}
</script>

<style>
.tradeplanner-font{
/* font-size: 60px; */
}
@media screen and (max-width: 800px) {
  .tradeplanner-font{
    width:60vw;
}
}
@media screen and (max-width: 400px) {
  .tradeplanner-font{
    width:80vw;
}
}
</style>
