var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-h",style:(_vm.themeDarkMode
      ? {
          background: '#151515 !important',
          borderColor: '#505050',
          color: '#A2A2A2',
        }
      : {})},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }