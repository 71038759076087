<template>
  <div class="image-check-box-container">
    <i class="icon">
      <img class="imgg" :src="path" />
    </i>
    <base-check-box
      v-model="checked"
      :label="label"
      @click="handleUserChoice"
    />
  </div>
</template>

<script>
// style="max-height: 100%; max-width: 100%; "
import BaseCheckBox from './BaseCheckBox.vue'
import { mapGetters } from 'vuex'
export default {
  components: { BaseCheckBox },
  name: 'CustomImageCheckBox',
  methods: {
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
  },
  props: {
    srcResolutionFunction: {
      type: Function,
    },
    label: {
      type: String,
      default: '',
    },
    updateState: {
      type: Function,
    },
    fromProps: {
      type: Boolean,
    },
  },
  data() {
    return {
      checked: false,
    }
  },
  watch: {
    checked(newValue) {
      this.updateState(this.label, newValue)
    },
    fromProps(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checked = newVal
      }
    },
  },
  computed: {
    ...mapGetters(['userChoice']),
    path() {
      return this.srcResolutionFunction(this.label)
    },
  },
}
</script>

<style>
.image-check-box-container {
  display: flex;
  align-items: center;
}
.image-check-box-container > * {
  /* margin-left: 0.5rem; */
}
.imgg {
  width: 12px;
  height: 12px;
}
@media screen and (max-width: 360px) {
  .imgg {
    width: 8px;
    height: 10px;
  }
}
</style>
