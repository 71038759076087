<template>
  <sui-table basic="very" compact="very">
    <sui-table-body>
      <sui-table-row>
        <sui-table-cell>
          <h4 :style="themeFontColor">MFI</h4>
        </sui-table-cell>
        <sui-table-cell/>
      </sui-table-row>
      <sui-table-row>
        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
        <sui-table-cell>
          <sui-table-row>
            <sui-table-cell :width="15">
              <input
                  placeholder="20"
                  type="number"
                  v-model="mfiLineWidth"
              />
            </sui-table-cell>
            <sui-table-cell :width="1">
              <color-picker
                  type="color"
                  :isSettingsPicker="true"
                  v-model="mfiPeriodColor"
              />
            </sui-table-cell>
            <sui-table-cell :width="1">
              <color-picker
                  type="color"
                  :isSettingsPicker="true"
                  v-model="mfiBandColor"
              />
            </sui-table-cell>
            <sui-table-cell :width="1">
              <color-picker
                  type="color"
                  :isSettingsPicker="true"
                  v-model="mfiBackColor"
              />
            </sui-table-cell>
          </sui-table-row>
        </sui-table-cell>
      </sui-table-row>
    </sui-table-body>
  </sui-table>
</template>
<script>
import ColorPicker from '@/baseComponents/ColorPicker.vue'
import {mapGetters} from "vuex";
import IndicatorSettingsComputed from "@/components/dialogBoxes/helper/IndicatorSettingsComputed"
export default {
  name: 'MFI_SettingsWindow',
  components: {ColorPicker},
  computed: {
    ...mapGetters(["themeFontColor"]),
    mfiLineWidth:{
      get: IndicatorSettingsComputed.mfiLineWidth.get,
      set(value) {
        this.$emit('runGeneralSetting', 'mfiLineWidth', value)
      }
    },
    mfiPeriodColor:{
      get: IndicatorSettingsComputed.mfiPeriodColor.get,
      set(value) {
        this.$emit('runGeneralSetting', 'mfiPeriodColor', value)
      }
    },
    mfiBandColor:{
      get: IndicatorSettingsComputed.mfiBandColor.get,
      set(value) {
        this.$emit('runGeneralSetting', 'mfiBandColor', value)
      }
    },
    mfiBackColor:{
      get: IndicatorSettingsComputed.mfiBackColor.get,
      set(value) {
        this.$emit('runGeneralSetting', 'mfiBackColor', value)
      }
    },
  }
}
</script>
<style scoped>
/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;

} */

div[role='listbox'] {
  background-color: v-bind(inputBackColor) !important;
}

/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
.ui.form select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
input[type="number"]{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
} */
</style>