<template>
  <sui-table basic="very" compact="very">
    <sui-table-body>
      <sui-table-row>
        <sui-table-cell>
          <h4 :style="themeFontColor">MOM</h4>
        </sui-table-cell>
        <sui-table-cell/>
      </sui-table-row>
      <sui-table-row>
        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
        <sui-table-cell>
          <sui-table-row>
            <sui-table-cell :width="15">
              <input
                  placeholder="20"
                  type="number"
                  v-model="momLineWidth"
              />
            </sui-table-cell>
            <sui-table-cell :width="1">
              <color-picker
                  type="color"
                  :isSettingsPicker="true"
                  v-model="momPeriodColor"
              />
            </sui-table-cell>
          </sui-table-row>
        </sui-table-cell>
      </sui-table-row>
    </sui-table-body>
  </sui-table>
</template>
<script>
import ColorPicker from '@/baseComponents/ColorPicker.vue'
import {mapGetters} from "vuex";
import IndicatorSettingsComputed from "@/components/dialogBoxes/helper/IndicatorSettingsComputed"
export default {
  name: 'MOM_SettingsWindow',
  components: {ColorPicker},
  computed: {
    ...mapGetters(["themeFontColor"]),
    momLineWidth:{
      get: IndicatorSettingsComputed.momLineWidth.get,
      set(value) {
        this.$emit('runGeneralSetting', 'momLineWidth', value)
      }
    },
    momPeriodColor:{
      get: IndicatorSettingsComputed.momPeriodColor.get,
      set(value) {
        this.$emit('runGeneralSetting', 'momPeriodColor', value)
      }
    },
  }
}
</script>
<style scoped>
/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;

} */

div[role='listbox'] {
  background-color: v-bind(inputBackColor) !important;
}

/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
.ui.form select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
input[type="number"]{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
} */
</style>