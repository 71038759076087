<template>
  <div class="fontSize">
    <sui-header dividing :style="themeFontColor">Events</sui-header>
    <multiple-checker
      @selectAll="updateAllSelections(true)"
      @clearAll="updateAllSelections(false)"
    />
    <!-- 1st filter -->
    <div class="input-container fontSize">
      <base-check-box
        label=""
        v-model="breakComputed"
        @click="handleUserChoice"
      />
      <sui-input
        style="width: 20%"
        type="number"
        tabindex="1"
        class="hidden"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_break_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>period</span>
      <select
        style="width: 20%"
        type="select"
        v-model="settings_break_direction"
        @change="handleUserChoice"
      >
        <option
          v-for="direction of breakDirectionOptions"
          :value="direction.value"
          :key="direction.value"
        >
          {{ direction.text }}
        </option>
      </select>
      <select
        style="width: 20%"
        type="select"
        v-model="settings_break_type"
        @change="handleUserChoice"
      >
        <option
          v-for="type of breakingTypeOptions"
          :value="type.value"
          :key="type.value"
        >
          {{ type.text }}
        </option>
      </select>
    </div>

    <!-- pre-breakout filter -->
    <div class="input-container fontSize">
      <base-check-box
        label=""
        v-model="prebreakComputed"
        @click="handleUserChoice"
      />
      <span>Within</span>
      <sui-input
        style="width: 10%"
        type="number"
        min="0"
        step="1"
        max="100"
        tabindex="1"
        class="hidden"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_prebreak_pct"
        @input="handleUserChoice"
      />
      <span>% of last</span>
      <sui-input
        style="width: 10%"
        type="number"
        tabindex="1"
        class="hidden"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_prebreak_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>period(s)</span>
      <select
        style="width: 20%"
        type="select"
        v-model="settings_prebreak_type"
        @change="handleUserChoice"
      >
        <option
          v-for="type of peakTypeOptions"
          :value="type.value"
          :key="type.value"
        >
          {{ type.text }}
        </option>
      </select>
    </div>

    <!-- 2nd filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="Increase volume for"
        v-model="volumeIncreaseComputed"
        @click="handleUserChoice"
      />
      <sui-input
        style="width: 15%"
        type="number"
        tabindex="1"
        class="hidden"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_volumeIncrease_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>day(s) of data</span>
    </div>

    <!-- 3rd filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="TCI Break "
        v-model="tciBreakComputed"
        @click="handleUserChoice"
      />
      <input
        type="radio"
        :id="'tciBreakDirection~up' + dialogId"
        name="tciBreakDirection"
        value="up"
        :style="{ cursor: 'pointer' }"
        v-model="settings_tciBreak_direction"
        @input="handleUserChoice"
      />
      <label
        :for="'tciBreakDirection~up' + dialogId"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >Up</label
      >

      <input
        type="radio"
        :id="'tciBreakDirection~down' + dialogId"
        name="tciBreakDirection"
        value="down"
        :style="{ cursor: 'pointer' }"
        v-model="settings_tciBreak_direction"
        @input="handleUserChoice"
      />
      <label
        :for="'tciBreakDirection~down' + dialogId"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >Down</label
      >

      <input
        type="radio"
        :id="'tciBreakDirection~all' + dialogId"
        name="tciBreakDirection"
        value="all"
        :style="{ cursor: 'pointer' }"
        v-model="settings_tciBreak_direction"
        @input="handleUserChoice"
      />

      <label
        :for="'tciBreakDirection~all' + dialogId"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >All</label
      >
    </div>

    <!-- 4th filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="TCI "
        @click="handleUserChoice"
        v-model="tciTrendComputed"
      />

      <template v-for="(v, i) of tciBreakProps">
        <input
          type="radio"
          :id="'tciTrendDirection~' + dialogId + i"
          
          name="tciTrendDirection"
          :value="v.value"
          :style="{ cursor: 'pointer' }"
          v-model="settings_tciTrend_direction"
          @input="handleUserChoice"
        />
        <label
          :for="'tciTrendDirection~' + dialogId + i"
          
          :style="{ ...themeFontColor, cursor: 'pointer' }"
          >{{ v.label }}</label
        >
      </template>

      <sui-input
        style="width: 15%"
        type="number"
        tabindex="1"
        class="hidden"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_tciTrend_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
    </div>

    <!-- 5th filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="MACD "
        v-model="macdComputed"
        @click="handleUserChoice"
      />
      <sui-input
        style="width: 15%"
        type="number"
        tabindex="1"
        class="hidden"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_macd_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>bars from a crossover</span>
    </div>

    <!-- 6th filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="OBV "
        v-model="obvComputed"
        @click="handleUserChoice"
      />

      <sui-input
        style="margin-left: 1rem; width: 15%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_obv_pctAbove"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>% above the level of</span>

      <sui-input
        style="width: 15%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_obv_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>bars ago</span>
    </div>

    <!-- 7th filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="Volume more than"
        v-model="volumeMoreComputed"
        @click="handleUserChoice"
      />

      <sui-input
        style="width: 15%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_volumeMore_pct"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>% above highest vol. of the previous</span>

      <sui-input
        style="width: 12%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_volumeMore_period"
        @input="handleUserChoice"
        @keypress="preventDecimal"
      />
      <span>bar(s)</span>
    </div>

    <!-- 8th filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="Trend line crossing in the last "
        v-model="trendLineCrossingComputed"
        @click="handleUserChoice"
      />

      <sui-input
        style="width: 12%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        @input="handleUserChoice"
        v-model="settings_trendLineCrossing_period"
        @keypress="preventDecimal"
      />
      <span>bar(s)</span>
    </div>

    <!-- 9th filter -->
    <div class="input-container fontSize">
      <base-check-box
        label="Find any stock that left a gap up by at least "
        v-model="gapUpComputed"
        @click="handleUserChoice"
      />

      <sui-input
        style="margin-left: 1rem; width: 12%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_gapUp_cents"
        @input="handleUserChoice"
      />
      <span>cents</span>
    </div>

    <!-- 10th filter -->
    <div class="input-container fontSize">
      <base-check-box
        @click="handleUserChoice"
        label="Find any stock that left a gap down by at least "
        v-model="gapDownComputed"
      />

      <sui-input
        style="margin-left: 1rem; width: 12%"
        type="number"
        tabindex="1"
        :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
        v-model="settings_gapDown_cents"
        @input="handleUserChoice"
      />
      <span>cents</span>
    </div>

    <!-- 11th filter -->
    <base-check-box
      label="Match selected events in combination "
      v-model="settings_matchCombination"
      @click="handleUserChoice"
    />
  </div>
</template>

<script>
import MultipleChecker from '../../../baseComponents/MultipleChecker.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseCheckBox from '../../../baseComponents/BaseCheckBox.vue'
import BaseTextBox from '../../../baseComponents/BaseTextBox.vue'
import BaseRadioBox from '../../../baseComponents/BaseRadioBox'
import { DEFAULT_EVENT_SETTINGS } from '../../../settings/USER_SETTINGS_DEFAULT'

export default {
  components: { MultipleChecker, BaseCheckBox, BaseTextBox, BaseRadioBox },
  props: {
    dialogId: {
      type: String,
      default: 'checkbox',
    },
    id: {
      type: String,
      default: null,
    },
  },
  name: 'EventsFilter',
  data() {
    return {
      breakDirectionOptions: [
        {
          text: 'breakup',
          value: 'breakup',
        },
        {
          text: 'breakdown',
          value: 'breakdown',
        },
      ],
      breakingTypeOptions: [
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Low',
          value: 'low',
        },
        {
          text: 'Close',
          value: 'close',
        },
      ],
      peakTypeOptions: [
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Low',
          value: 'low',
        },
      ],
      settings: {
        break: {
          isActive: false,
          period: 30,
          direction: 'breakup',
          type: 'close',
        },
        volumeIncrease: {
          isActive: false,
          period: 4,
        },
        tciBreak: {
          isActive: false,
          direction: 'down',
        },
        tciTrend: {
          isActive: false,
          direction: 'up',
          period: 21,
        },
        macd: {
          isActive: false,
          period: 2,
        },
        obv: {
          isActive: false,
          pctAbove: 2,
          period: 2,
        },
        volumeMore: {
          isActive: false,
          pct: 1300,
          period: 1,
        },
        trendLineCrossing: {
          isActive: false,
          period: 1,
        },
        gapUp: {
          isActive: false,
          cents: 10,
        },
        gapDown: {
          isActive: false,
          cents: 10,
        },
        matchCombination: false,
      },
      tciBreakProps: [
        { label: 'Up Trend', value: 'up' },
        { label: 'Down Trend', value: 'down' },
        { label: 'Horizontal', value: 'neutral' },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'eventUpdates',
      'eventsSettings',
      'themeFontColor',
      'userChoice',
      'themeDarkMode',
    ]),
    eventSettingsData() {
      return this.eventsSettings(this.id)
    },
    breakComputed: {
      get() {
        return this.getSettingCheck('break')
      },
      set(value) {
        this.setSettingCheck('break', value)
      },
    },
    prebreakComputed: {
      get() {
        return this.getSettingCheck('prebreak')
      },
      set(value) {
        this.setSettingCheck('prebreak', value)
      },
    },
    volumeIncreaseComputed: {
      get() {
        return this.getSettingCheck('volumeIncrease')
      },
      set(value) {
        this.setSettingCheck('volumeIncrease', value)
      },
    },
    tciBreakComputed: {
      get() {
        return this.getSettingCheck('tciBreak')
      },
      set(value) {
        this.setSettingCheck('tciBreak', value)
      },
    },
    tciTrendComputed: {
      get() {
        return this.getSettingCheck('tciTrend')
      },
      set(value) {
        this.setSettingCheck('tciTrend', value)
      },
    },
    macdComputed: {
      get() {
        return this.getSettingCheck('macd')
      },
      set(value) {
        this.setSettingCheck('macd', value)
      },
    },
    obvComputed: {
      get() {
        return this.getSettingCheck('obv')
      },
      set(value) {
        this.setSettingCheck('obv', value)
      },
    },
    volumeMoreComputed: {
      get() {
        return this.getSettingCheck('volumeMore')
      },
      set(value) {
        this.setSettingCheck('volumeMore', value)
      },
    },
    trendLineCrossingComputed: {
      get() {
        return this.getSettingCheck('trendLineCrossing')
      },
      set(value) {
        this.setSettingCheck('trendLineCrossing', value)
      },
    },
    gapUpComputed: {
      get() {
        return this.getSettingCheck('gapUp')
      },
      set(value) {
        this.setSettingCheck('gapUp', value)
      },
    },
    gapDownComputed: {
      get() {
        return this.getSettingCheck('gapDown')
      },
      set(value) {
        this.setSettingCheck('gapDown', value)
      },
    },
    settings_break_period: {
      get() {
        return this.getSetting('break', 'period')
      },
      set(value) {
        this.setSetting('break', 'period', value)
      },
    },
    settings_break_direction: {
      get() {
        return this.getSetting('break', 'direction')
      },
      set(value) {
        this.setSetting('break', 'direction', value)
      },
    },
    settings_break_type: {
      get() {
        return this.getSetting('break', 'type')
      },
      set(value) {
        this.setSetting('break', 'type', value)
      },
    },
    settings_prebreak_pct: {
      get() {
        return this.getSetting('prebreak', 'pct')
      },
      set(value) {
        this.setSetting('prebreak', 'pct', Math.max(0, Math.min(100, value)))
      },
    },
    settings_prebreak_period: {
      get() {
        return this.getSetting('prebreak', 'period')
      },
      set(value) {
        this.setSetting('prebreak', 'period', value)
      },
    },
    settings_prebreak_type: {
      get() {
        return this.getSetting('prebreak', 'type')
      },
      set(value) {
        this.setSetting('prebreak', 'type', value)
      },
    },
    settings_volumeIncrease_period: {
      get() {
        return this.getSetting('volumeIncrease', 'period')
      },
      set(value) {
        this.setSetting('volumeIncrease', 'period', value)
      },
    },
    settings_tciBreak_direction: {
      get() {
        return this.getSetting('tciBreak', 'direction')
      },
      set(value) {
        this.setSetting('tciBreak', 'direction', value)
      },
    },
    settings_tciTrend_direction: {
      get() {
        return this.getSetting('tciTrend', 'direction')
      },
      set(value) {
        this.setSetting('tciTrend', 'direction', value)
      },
    },
    settings_tciTrend_period: {
      get() {
        return this.getSetting('tciTrend', 'period')
      },
      set(value) {
        this.setSetting('tciTrend', 'period', value)
      },
    },
    settings_macd_period: {
      get() {
        return this.getSetting('macd', 'period')
      },
      set(value) {
        this.setSetting('macd', 'period', value)
      },
    },
    settings_obv_pctAbove: {
      get() {
        return this.getSetting('obv', 'pctAbove')
      },
      set(value) {
        this.setSetting('obv', 'pctAbove', value)
      },
    },
    settings_obv_period: {
      get() {
        return this.getSetting('obv', 'period')
      },
      set(value) {
        this.setSetting('obv', 'period', value)
      },
    },
    settings_volumeMore_pct: {
      get() {
        return this.getSetting('volumeMore', 'pct')
      },
      set(value) {
        this.setSetting('volumeMore', 'pct', value)
      },
    },
    settings_volumeMore_period: {
      get() {
        return this.getSetting('volumeMore', 'period')
      },
      set(value) {
        this.setSetting('volumeMore', 'period', value)
      },
    },
    settings_trendLineCrossing_period: {
      get() {
        return this.getSetting('trendLineCrossing', 'period')
      },
      set(value) {
        this.setSetting('trendLineCrossing', 'period', value)
      },
    },
    settings_gapUp_cents: {
      get() {
        return this.getSetting('gapUp', 'cents')
      },
      set(value) {
        this.setSetting('gapUp', 'cents', value)
      },
    },
    settings_gapDown_cents: {
      get() {
        return this.getSetting('gapDown', 'cents')
      },
      set(value) {
        this.setSetting('gapDown', 'cents', value)
      },
    },
    settings_matchCombination: {
      get() {
        let settings = this.eventSettingsData
        return settings && settings.matchCombination
          ? settings.matchCombination
          : this.settings.matchCombination
      },
      set(value) {
        let settings = this.eventSettingsData
        settings['matchCombination'] = value
        this.setEventsFilterSettings({
          l: 'settings_single',
          id: this.id,
          settings,
        })
      },
    },
  },
  methods: {
    ...mapActions(['setEventsFilterSettings']),
    handleUserChoice() {
      if (!this.userChoice) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    preventDecimal(event) {
    if (event.key === '.' || event.key === ',' || event.key === '-') {
      event.preventDefault();
    }
  },
    setSettingCheck(key, value) {
      let settings = this.eventSettingsData
      settings[key].isActive = value
      this.setEventsFilterSettings({
        l: 'settings_single',
        id: this.id,
        settings,
      })
    },
    getSettingCheck(key) {
      let settings = this.eventSettingsData
      if (settings && settings[key]) {
        return settings[key].isActive
      } else {
        return this.settings[key] ? this.settings[key].isActive: false
      }
    },
    setSetting(key1, key2, value) {
      let settings = this.eventSettingsData
      settings[key1][key2] = value
      this.setEventsFilterSettings({
        l: 'settings_single',
        id: this.id,
        settings,
      })
    },
    getSetting(key1, key2) {
      let settings = this.eventSettingsData
      if (settings && settings[key1]) {
        return settings[key1][key2]
      } else {
        if (this.settings[key1] == null || this.settings[key1] == undefined) {
          return DEFAULT_EVENT_SETTINGS[key1][key2] }
        return this.settings[key1][key2]
      }
      // this.setEventsFilterSettings({l:"settings_single",id:this.id,settings})
    },
    updateAllSelections(state) {
      this.handleUserChoice()
      this.breakComputed = state
      this.prebreakComputed = state
      this.volumeIncreaseComputed = state
      this.tciBreakComputed = state
      this.tciTrendComputed = state
      this.macdComputed = state
      this.obvComputed = state
      this.volumeMoreComputed = state
      this.trendLineCrossingComputed = state
      this.gapUpComputed = state
      this.gapDownComputed = state
      this.settings_matchCombination = state
    },
  },
  watch: {
    eventSettingsData: {
      handler: function () {
        // this.setEventsFilterSettings({l:"settings",id:this.id,settings:newSettings})
        const isSelected =
          this.breakComputed ||
          this.prebreakComputed ||
          this.volumeIncreaseComputed ||
          this.tciBreakComputed ||
          this.tciTrendComputed ||
          this.macdComputed ||
          this.obvComputed ||
          this.volumeMoreComputed ||
          this.trendLineCrossingComputed ||
          this.gapUpComputed ||
          this.gapDownComputed ||
          this.settings_matchCombination
        this.$emit('isValid', isSelected)
      },
      deep: true,
    },
    eventUpdates: {
      handler: function (newFilter) {
        // if (newFilter.status && newFilter.dialogId === this.dialogId) {
        //   this.settings = this.eventSettingsData
        //   this.$store.commit('UPDATE_FILTER_EVENT_SETTINGS', {status: false,dialogId: ''})
        // }
      },
      deep: true,
    },
  },
}
</script>

<style>
.input-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  flex-flow: wrap;
}

.input-container > * {
  margin-right: 0.3rem;
}
/* 
.fontSize{
  font-size: 20px;
} */
</style>

<style scoped>
.input-container {
  font-size: 13.5px !important;
}
</style>
