import { isClientAccountActive } from '../services/user'

export default {
  namespaced: true,
  state: () => ({
    subscriptionStatus: 'live',
    trialEndsIn: '',
    subscriptionList: [],
  }),
  mutations: {
    updateSubscriptionState(state, data) {
      state.subscriptionStatus = data.status
      if (data.status === 'trialing') {
        state.trialEndsIn = data.trialEndsIn
      } else {
        state.trialEndsIn = ''
      }
    },
    setSubscriptionList(state, data) {
      state.subscriptionList = data
    },
  },
  actions: {
    async retrieveUserSubscriptionStatus(context) {
      const isClientAccountAuthenticated = await isClientAccountActive()
      if(isClientAccountAuthenticated){
        context.commit("setSubscriptionList",[isClientAccountAuthenticated])
        context.commit('updateSubscriptionState', isClientAccountAuthenticated)
        return true
      }
      else{
        return false
      }
    },
  },
  getters: {
    subscriptionList: (state) => state.subscriptionList,
  },
}
