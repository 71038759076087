<template>
  <sui-button
    icon
    secondary
    :negative="isToggled"
    @click="$emit('click')"
    :data-tooltip="tooltipText"
    :data-position="tooltipPosition"
    data-inverted=""
    
  >
    <span v-if="isText">{{ imageSrc }}</span>
    <i class="icon" v-else>
      <img
        :src="imageSrc"
        style="max-height: 100%; max-width: 100%; margin: auto"
      />
    </i>
  </sui-button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'CustomImageIconButton',
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    isToggled: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: false
    },
    tooltipPosition: {
      type: String,
      default: 'top center'
    },
    tooltipText: {
      type: String,
      default: null
    },
    toolColor :{
      type:String,
      default: " "
    }
    
  },
  computed : {
    ...mapGetters(['themeSecondaryColor',])
  }
}
</script>
