<template>
  <guru-base-modal
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    header="Frank's Pattern"
    :valid="isValid"
    customStyle=""
    :scanType="scanType"
    id="franksPattern"
    :isLoading="isLoading"
  >
  
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'FranksPattern',
  data() {
    return {
      scanType: "franksPattern",
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['sendFranksPatternRequest', 'cancelGuruScans', 'setFranksSettings', 'saveguruScanRequest',
    'getUserScanList']),
    async onScanClick() {
      this.isLoading = true
      await this.sendFranksPatternRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    async saveScanSettings(name) {
      await this.saveguruScanRequest({
        scanName:name,
        scanType:'franksPattern'
      })
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return true
    },
    settings: {
      get() {
        return this.gurusScanSettings.franksPattern
      },
      set(value) {
        this.setFranksSettings(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
