var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-box',{staticStyle:{"border-radius":"10px !important"},attrs:{"modalHeader":"","open":_vm.open,"action":false,"scrolling":false},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('sui-modal-actions',{style:({ ..._vm.themePrimaryColor, ..._vm.themeFontColor })},[_c('sui-button',{style:(_vm.themeSecondaryColor),attrs:{"disabled":_vm.cannotUpdateSymbol},nativeOn:{"click":function($event){return _vm.onSaveSettings.apply(null, arguments)}}},[_vm._v("OK ")]),_c('sui-button',{staticStyle:{"background-color":"#414343","filter":"none","border":"1px solid #2e2f2f","opacity":"1","font-weight":"bold","color":"white"},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v("Cancel")])],1)]},proxy:true}])},[_c('div',{staticStyle:{"height":"60vh","padding":"8px","font-size":"13.5px","display":"flex","flex-direction":"column","justify-content":"space-between"}},[_c('div',{staticClass:"ui",staticStyle:{"height":"10vh","display":"flex","align-items":"center"}},[_c('div',{staticClass:"sixteen wide column",staticStyle:{"flex-grow":"1"}},[_c('sui-input',{staticStyle:{"height":"35px"},attrs:{"placeholder":"Filtered","icon":"","fluid":""},on:{"keyup":_vm.keyUpSearch},model:{value:(_vm.filteredtext),callback:function ($$v) {_vm.filteredtext=$$v},expression:"filteredtext"}})],1),_c('sui-button',{ref:"clearSearch",staticClass:"right-header-button",attrs:{"id":"clearSearchId","icon":"trash alternate","secondary":""},on:{"click":_vm.clearSymbolSearch}})],1),_c('sui-segment',{style:(_vm.themeDarkMode
          ? { 'background-color': '#151515', border: '1px solid #2C2929' }
          : {}),attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"ui grid",staticStyle:{"height":"52vh"},style:(_vm.themeDarkMode
            ? { 'background-color': '#151515', border: '1px solid #2C2929' }
            : {})},[_c('div',{staticClass:"four wide column",staticStyle:{"height":"52vh","overflow-y":"scroll"}},_vm._l((_vm.markets),function(metadata,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(metadata.show),expression:"metadata.show"}],key:metadata.id,staticClass:"ui middle aligned selection list",staticStyle:{"margin":"0"},style:(metadata.selected
                ? { ..._vm.themeSecondaryColor }
                : { ..._vm.themeFontColor })},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"content"},[_c('div',{ref:`marketDiv_${metadata.code}`,refInFor:true,staticClass:"header",style:(metadata.selected ? { color: 'white' } : _vm.themeFontColor),on:{"click":function($event){$event.preventDefault();return _vm.changeMarketState(i, 'markets', 'showCategory')}}},[_vm._v(" "+_vm._s(metadata.desc)+" ")])])])])}),0),_c('div',{staticClass:"four wide column",staticStyle:{"height":"52vh"}},_vm._l((_vm.categoryByMarket),function(metadata,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCategory && metadata.show),expression:"showCategory && metadata.show"}],key:metadata.id,staticClass:"ui middle aligned selection list",staticStyle:{"margin":"0"},style:(metadata.selected
                ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                : { ..._vm.themeFontColor })},[_c('a',{ref:`categoryDiv_${metadata.desc}`,refInFor:true,staticClass:"item",on:{"click":function($event){$event.preventDefault();return _vm.updateContract(i, metadata.desc, 'category', 'showContracts')}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header",style:(metadata.selected ? { color: 'white' } : _vm.themeFontColor)},[_vm._v(" "+_vm._s(metadata.title)+" ")])])])])}),0),_c('div',{staticClass:"eight wide column",staticStyle:{"overflow-y":"scroll !important","height":"50vh","/* background-color":"red","*/\n            /* flex-basis":"60%","*/\n            flex-grow":"1"}},[_c('sui-table',{key:3,attrs:{"basic":"very","compact":"very"}},[_c('sui-table-body',[_c('sui-table-row',[_c('sui-table-cell',[_c('sui-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContracts),expression:"showContracts"}]},_vm._l((_vm.symbolData),function(symbol,i){return _c('sui-list-item',{key:i,style:(symbol.selected
                          ? {
                              cursor: 'pointer',
                              color: 'white',
                              ..._vm.themeSecondaryColor,
                            }
                          : {
                              cursor: 'pointer',
                              ..._vm.themeFontColor,
                              padding: '5px',
                            }),on:{"click":function($event){return _vm.symbolChange(i, symbol)}}},[(symbol.selected)?[_c('div',{style:({
                            display: 'flex',
                            gap: '.5vw',
                            color: 'white',
                            ..._vm.themeSecondaryColor,
                          })},[_c('div',{staticStyle:{"flex-basis":"20%","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.getSymbolName(symbol))+" ")]),_c('div',[_vm._v(_vm._s(_vm.getCompanyName(symbol)))])])]:_vm._e(),(!symbol.selected)?[_c('div',{staticClass:"header",style:({
                            display: 'flex',
                            ..._vm.themeFontColor,
                            gap: '.5vw',
                          })},[_c('div',{staticStyle:{"font-weight":"bolder","flex-basis":"20%"}},[_vm._v(" "+_vm._s(_vm.getSymbolName(symbol))+" ")]),_c('div',{staticStyle:{"flex-basis":"80%"}},[_vm._v(" "+_vm._s(_vm.getCompanyName(symbol))+" ")])])]:_vm._e()],2)}),1)],1)],1)],1)],1)],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }