<template>
  <dialog-box
    id="mainForm"
    modalHeader="Update Profile Settings"
    :open="open"
    :action="false"
  >
    <sui-modal-content :style="{ ...themeFontColor }">
      <sui-message style="background-color: #FDF6F6; box-shadow: 0 0 0 1px #e0b4b4 inset,0 0 0 0 transparent;" v-if="isError">
        <sui-message-header style="color: #912D2B">Action Forbidden</sui-message-header>
        <p style="color: red;">
          {{ errorMessage }}
        </p>
      </sui-message>
      <sui-modal-description>
        <div v-if="!passwordVerified">
          <sui-form @submit.prevent="submit" :loading="showLoader">
            <sui-form-field class="profile-font">
              <label :style="themeFontColor">Please verify your password</label>
              <sui-form-field>
                <input
                  type="password"
                  placeholder="Password"
                  :style="
                    themeDarkMode
                      ? { backgroundColor: '#505050', color: '#A2A2A2' }
                      : {}
                  "
                  v-model="password"
                />
              </sui-form-field>
            </sui-form-field>
          </sui-form>
        </div>

        <div v-if="passwordVerified">
          <sui-form @submit.prevent="submit" :loading="showLoader">
            <sui-form-field class="profile-font">
              <label :style="themeFontColor">Enter your new email address</label>
              <sui-form-field>
                <input
                  :readonly="emailVerified"
                  type="email"
                  placeholder="Email"
                  :style="
                    themeDarkMode
                      ? { backgroundColor: '#505050', color: '#A2A2A2' }
                      : {}
                  "
                  v-model="email"
                />
              </sui-form-field>
            </sui-form-field>

            <sui-form-field v-if="emailVerified" class="profile-font">
              <label :style="themeFontColor">Verify new email address</label>
              <sui-form-field>
                <input
                @keypress.enter="submit"
                  type="text"
                  placeholder="Enter 6-digit OTP code"
                  :style="
                    themeDarkMode
                      ? { backgroundColor: '#505050', color: '#A2A2A2' }
                      : {}
                  "
                  v-model="userOtp"
                />
              </sui-form-field>
            </sui-form-field>
          </sui-form>

          <!-- <sui-form @submit.prevent="submit" :loading="showLoader">
            <sui-form-field v-if="emailVerified" class="profile-font">
              <label :style="themeFontColor">OTP</label>
              <sui-form-field>
                <input
                  type="number"
                  placeholder="OTP"
                  :style="
                    themeDarkMode
                      ? { backgroundColor: '#505050', color: '#A2A2A2' }
                      : {}
                  "
                  v-model="userOtp"
                />
              </sui-form-field>
            </sui-form-field>
          </sui-form> -->
        </div>
      </sui-modal-description>
    </sui-modal-content>
    <template v-slot:action>
      <sui-modal-actions
        :style="
          themeDarkMode
            ? {
                background: '#151515 !important',
                borderColor: '#151515',
                color: '#A2A2A2',
              }
            : {}
        "
      >
        <div>
          <sui-button
            :disabled="showLoader"
            @click.native="submit"
            :style="themeSecondaryColor"
            >OK
          </sui-button>
          <sui-button
            :disabled="showLoader"
            @click.native="onClose"
            :style="
              themeDarkMode
                ? {
                    background: '#505050 !important',
                    borderColor: '#151515',
                    color: '#A2A2A2',
                  }
                : {}
            "
            >Close
          </sui-button>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import {
  SendOneTimePassword,
  verifyOTP,
  VerifyPassword,
} from '../../services/user'
const notificationTimeout = 5000
export default {
  name: 'ProfileSettings',
  components: {
    DialogBox,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    ...mapActions([]),
    setErorrMethod(errorCheck, errorMessage) {
      this.isError = errorCheck
      this.errorMessage = errorMessage
    },

    async handleVerifyPassword() {
      try {
        const response = await VerifyPassword({
          userId: this.getUserId,
          password: this.password,
        })
        if (response.data.success) {
          this.passwordVerified = response.data.data
          this.setErorrMethod(false, '')
        } else {
          this.setErorrMethod(true, 'Password you entererd was incorrect')
        }
      } catch (err) {
        this.setErorrMethod(true, err.message)
      }
    },
    async handleSendOTP() {
      try {
        const response = await SendOneTimePassword({
          userId: this.getUserId,
          email: this.email,
        })
        if (response.data.success) {
          this.emailVerified = true
          this.setErorrMethod(false, '')
        } else {
          this.setErorrMethod(true, response.data.msg || 'Your OTP could not be generated')
        }
      } catch (err) {
        this.setErorrMethod(true, err.message)
      }
    },
    async handleVerifyOtp() {
      try {
        const response = await verifyOTP({
          userId: this.getUserId,
          email: this.email,
          otp: this.userOtp,
        })
        if (response.data.success) {
          const token = response?.data?.data?.accessToken
          localStorage.setItem('state', token)
          location.reload()
          this.password = ''
          this.email = ''
          this.userOtp = ''
          this.passwordVerified = false
          this.emailVerified = false
          this.setErorrMethod(false, '')
          this.$emit('change', !this.open)
        } else {
          this.setErorrMethod(true, response.data.msg)
        }
      } catch (err) {
        this.setErorrMethod(true, err.message)
      }
    },

    async submit(event) {
      event.preventDefault()
      if (!this.passwordVerified) {
        if (this.password == '') return
        this.showLoader = true
        await this.handleVerifyPassword()
      } else if (this.passwordVerified && !this.emailVerified) {
        if (this.email == '') return
        this.showLoader = true
        await this.handleSendOTP()
      } else {
        if (this.userOtp == '') return
        this.showLoader = true
        await this.handleVerifyOtp()
      }
      this.showLoader = false
    },
    onClose(event) {
      event.preventDefault()
      this.passwordVerified = false
      this.emailVerified = false
      this.setErorrMethod(false, '')
      this.password = ''
      this.email = ''
      this.userOtp = ''
      this.$emit('change', !open)
    },
  },
  data() {
    return {
      password: '',
      email: '',
      passwordVerified: false,
      emailVerified: false,
      showLoader: false,
      userOtp: '',
      isError: false,
      errorMessage: '',
    }
  },
  computed: {
    ...mapGetters([
      'themeFontColor',
      'themeDarkMode',
      'themeSecondaryColor',
      'getUserId',
    ]),
  },
  watch: {},
}
</script>

<style>
.profile-font {
  font-size: 14px;
}
</style>
