<template>
  <div class="top-container top-container2">
    <div class="left">
      <!-- top image -->
      <div class="logo">
        <img :src="themeDarkMode ? '/images/Icon-ProTrader-darkmode.png' : '/images/Icon-ProTrader-classicmode.png'"
          alt="ProTrader" srcset="" />
      </div>

      <!-- responsive tab -->
      <div id="responsiveTab" style="gap: 3px">
        <!-- imageSrc="/images/bar.svg" icon="chart bar icon" -->
        <!-- <sui-dropdown class="button secondary" @click="RightScroll()">right</sui-dropdown> -->
        <sui-dropdown style="display: flex; justify-content: center; align-items: center"
          class="button secondary mini width-change width-change-max">
          <sui-image class="mob-tab-chart-img" :src="chartImgAddress ? chartImgAddress : '/images/bar.svg'"
            size="large" />
          <sui-dropdown-menu :style="themePrimaryColor" style="
              z-index: 101;
              width: 170px;
              left: 0;
              top: 37px;
              padding-bottom: 8px;
            ">
            <sui-dropdown-item @click="changeType('Bars', '/images/bar.svg')" :style="selectedChartType === 'Bars'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg id="bar" xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 20.005 20"
                class="svgImage">
                <line id="Line_1" data-name="Line 1" y2="17.015" transform="translate(3.849 2.985)" stroke-width="2" />
                <line id="Line_2" data-name="Line 2" x2="3.947" transform="translate(4 6)" stroke-width="2" />
                <line id="Line_3" data-name="Line 3" x2="3.947" transform="translate(0 16.985)" stroke-width="2" />
                <line id="Line_4" data-name="Line 4" y2="17" transform="translate(16.067)" stroke-width="2" />
                <line id="Line_5" data-name="Line 5" x2="4" transform="translate(16.005 3)" stroke-width="2" />
                <line id="Line_6" data-name="Line 6" x2="3.948" transform="translate(12.031 12.031)" stroke-width="2" />
              </svg>
              Bar Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('LineChart', '/images/line.svg')" :style="selectedChartType === 'LineChart'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 19.995 19.992"
                class="svgImage">
                <g id="line" transform="translate(0 0)">
                  <line id="Line_27" data-name="Line 27" y1="11.951" x2="7.574" transform="translate(0.845 7.505)"
                    stroke-width="2" />
                  <line id="Line_28" data-name="Line 28" x2="3.158" y2="4.982" transform="translate(8.419 7.505)"
                    stroke-linecap="round" stroke-width="2" />
                  <line id="Line_29" data-name="Line 29" y1="11.952" x2="7.574" transform="translate(11.577 0.535)"
                    stroke-width="2" />
                </g>
              </svg>
              Line Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('Candles', '/images/candlestick.svg')" :style="selectedChartType === 'Candles'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg id="candlestick" xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 20 20.015"
                class="svgImage">
                <rect id="Rectangle_1" data-name="Rectangle 1" width="6.029" height="11.015"
                  transform="translate(1 5.029)" stroke-linejoin="round" stroke-width="2" />
                <line id="Line_7" data-name="Line 7" y2="4.261" transform="translate(3.985 1)" stroke-linejoin="round"
                  stroke-width="2" />
                <line id="Line_8" data-name="Line 8" y2="3.015" transform="translate(3.985 17)" stroke-linejoin="round"
                  stroke-width="2" />
                <line id="Line_9" data-name="Line 9" y1="3" transform="translate(15.985)" stroke-linejoin="round"
                  stroke-width="2" />
                <rect id="Rectangle_2" data-name="Rectangle 2" width="5.971" height="9.044"
                  transform="translate(13.029 3)" stroke-linejoin="round" stroke-width="2" />
                <line id="Line_10" data-name="Line 10" y1="6" transform="translate(16.015 13.029)"
                  stroke-linejoin="round" stroke-width="2" />
              </svg>
              Candlestick Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('Kagi', '/images/kagi.svg')" :style="selectedChartType === 'Kagi'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg id="kagi" xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 20.033 20.011"
                class="svgImage">
                <line id="Line_17" data-name="Line 17" y1="13" transform="translate(1 7.011)" stroke-width="2" />
                <line id="Line_18" data-name="Line 18" x1="5" transform="translate(0 8)" stroke-width="2" />
                <line id="Line_19" data-name="Line 19" y1="5" transform="translate(5 7)" stroke-width="2" />
                <line id="Line_20" data-name="Line 20" x1="5" transform="translate(4 12)" stroke-width="2" />
                <line id="Line_21" data-name="Line 21" y1="13" transform="translate(9)" stroke-width="2" />
                <line id="Line_22" data-name="Line 22" x1="5.437" transform="translate(8.563 1)" stroke-width="2" />
                <line id="Line_23" data-name="Line 23" y1="17" transform="translate(13 0.031)" stroke-width="2" />
                <line id="Line_24" data-name="Line 24" x1="5" transform="translate(12 17.031)" stroke-width="2" />
                <line id="Line_25" data-name="Line 25" y1="17" transform="translate(17 1)" stroke-width="2" />
                <line id="Line_26" data-name="Line 26" x1="4.033" transform="translate(16 1)" stroke-width="2" />
              </svg>
              Kagi Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('HeikenAshi', '/images/heikenAshi.svg')" :style="selectedChartType === 'HeikenAshi'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 19.128 18.329"
                class="svgImage">
                <g id="heikenAshi" transform="translate(-0.33 -0.075)">
                  <rect id="rect3" width="6.029" height="11.015" transform="translate(0.83 5.283)" stroke-width="1" />
                  <line id="line5" y2="4.261" transform="translate(3.731 1.594)" stroke-width="1" />
                  <line id="line7" y2="3.015" transform="translate(3.731 15.389)" stroke-width="1" />
                  <rect id="rect3-5" width="6.029" height="11.015" transform="translate(12.93 2.21)" stroke-width="1" />
                  <line id="line7-2" y2="3.015" transform="translate(16.029 12.656)" stroke-width="1" />
                  <line id="line5-7" y2="4.261" transform="translate(15.859 0.075)" stroke-width="1" />
                </g>
              </svg>
              Heikin Ashi Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('UpAndDown', '/images/updown.svg')" :style="selectedChartType === 'UpAndDown'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg viewBox="0 0 20.005 20" xmlns="http://www.w3.org/2000/svg" width="15" height="13" class="svgImage">
                <line id="Line_1" data-name="Line 1" y2="19.689" stroke-width="2" x1="3.849" y1="1.067" x2="3.849" />
                <line id="Line_2" data-name="Line 2" x2="3.947" transform="matrix(1, 0, 0, 1, 4, 6)" stroke-width="2" />
                <line id="Line_3" data-name="Line 3" x2="3.947" transform="matrix(1, 0, 0, 1, 0, 16.985001)"
                  stroke-width="2" />
                <line id="Line_4" data-name="Line 4" y2="18.415" stroke-width="2" x1="15.252" y1="3.717" x2="15.252" />
                <line id="Line_5" data-name="Line 5" x2="14.825" stroke-width="2" x1="10.825" y1="7.964" y2="7.964" />
                <line id="Line_6" data-name="Line 6" x2="19.169" stroke-width="2" x1="15.221" y1="13.782" y2="13.782" />
              </svg>
              Up/Down Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('PnF', '/images/point-figure.svg')" :style="selectedChartType === 'PnF'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 19.805 20.082"
                class="svgImage">
                <g id="point-figure" transform="translate(0)">
                  <g id="Group_1" data-name="Group 1">
                    <line id="Line_1" data-name="Line 1" y1="4.582" x2="7.156" transform="translate(0.539 3.206)"
                      stroke-width="2" />
                    <line id="Line_2" data-name="Line 2" x1="7.156" y1="4.581" transform="translate(0.539 3.197)"
                      stroke-width="2" />
                  </g>
                  <g id="Group_2" data-name="Group 2">
                    <line id="Line_3" data-name="Line 3" y1="4.582" x2="7.156" transform="translate(0.539 8.926)"
                      stroke-width="2" />
                    <line id="Line_4" data-name="Line 4" x1="7.156" y1="4.581" transform="translate(0.539 8.917)"
                      stroke-width="2" />
                  </g>
                  <g id="Group_3" data-name="Group 3">
                    <line id="Line_5" data-name="Line 5" y1="4.582" x2="7.156" transform="translate(0.539 14.658)"
                      stroke-width="2" />
                    <line id="Line_6" data-name="Line 6" x1="7.156" y1="4.581" transform="translate(0.539 14.649)"
                      stroke-width="2" />
                  </g>
                  <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="2.992" cy="2.204" rx="2.992" ry="2.204"
                    transform="translate(12.821 1)" stroke-width="2" />
                  <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="2.992" cy="2.204" rx="2.992" ry="2.204"
                    transform="translate(12.821 7.21)" stroke-width="2" />
                  <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.992" cy="2.204" rx="2.992" ry="2.204"
                    transform="translate(12.821 13.42)" stroke-width="2" />
                </g>
              </svg>
              Point & Figures Chart
            </sui-dropdown-item>
            <sui-dropdown-item @click="changeType('BarTrend', '/images/trend.svg')" :style="selectedChartType === 'BarTrend'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              ">
              <svg id="trend" xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 20 20"
                class="svgImage">
                <line id="Line_7" data-name="Line 7" y2="17.015" transform="translate(4.984 2.985)" stroke-width="2" />
                <line id="Line_8" data-name="Line 8" x2="3.946" transform="translate(5.043 6)" stroke-width="2" />
                <line id="Line_9" data-name="Line 9" x2="3.947" transform="translate(1.011 16.985)" stroke-width="2" />
                <line id="Line_10" data-name="Line 10" y2="17" transform="translate(14.986)" stroke-width="2" />
                <line id="Line_11" data-name="Line 11" x2="4" transform="translate(14.986 3)" stroke-width="2" />
                <line id="Line_12" data-name="Line 12" x2="3.947" transform="translate(11.014 12.031)"
                  stroke-width="2" />
                <path id="Path_1" data-name="Path 1" d="M1,13.015c0-2.227,4.032-4.029,9.015-4.029" stroke-width="2" />
                <path id="Path_2" data-name="Path 2" d="M19,6c0,1.65-4.019,2.985-8.985,2.985" stroke-width="2" />
              </svg>

              Bar Trend Chart
            </sui-dropdown-item>
            <sui-dropdown-item style="padding-bottom: 8px !important" @click="changeChartToCompareStocksType"
              :style="themeFontColor">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 20.008 20.403"
                class="svgImage">
                <g id="compare" transform="translate(0 0)">
                  <line id="Line_11" data-name="Line 11" y1="11.951" x2="7.574" transform="translate(0.845 7.505)"
                    stroke-width="2" />
                  <line id="Line_12" data-name="Line 12" x2="3.158" y2="4.982" transform="translate(8.419 7.505)"
                    stroke-linecap="round" stroke-width="2" />
                  <line id="Line_13" data-name="Line 13" y1="11.952" x2="7.574" transform="translate(11.577 0.535)"
                    stroke-width="2" />
                  <line id="Line_14" data-name="Line 14" y1="3.951" x2="11.574" transform="translate(0.845 15.505)"
                    stroke-width="2" />
                  <line id="Line_15" data-name="Line 15" x2="3.158" y2="2.982" transform="translate(12.419 15.505)"
                    stroke-linecap="round" stroke-width="2" />
                  <line id="Line_16" data-name="Line 16" y1="5.952" x2="3.574" transform="translate(15.577 12.535)"
                    stroke-width="2" />
                </g>
              </svg>

              Comparison Chart
            </sui-dropdown-item>
            <sui-dropdown-item style="padding-bottom: 8px !important ; display:flex;align-items:center;"
              @click="toggleVloume" :style="themeFontColor">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
              </svg>


              <span style="margin-left:12px;">
                Toggle Volume
              </span>
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>

        <sui-dropdown :disabled="isChartLoading" style="display: flex; justify-content: center; align-items: center"
          :text="timeFrame" class="button secondary mini width-change width-change-max">
          <sui-dropdown-menu class="width-change-item" :style="themePrimaryColor" style="
              z-index: 101;
              width: 150px;
              left: 0;
              top: 37px;
              color: black;
              padding-bottom: 8px;
            ">
            <sui-dropdown-item secondary :style="timeFrame == 'D'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              " :negative="timeFrame == 'D'" @click="changeTimeFrame('D')" data-tooltip="Daily View"
              data-position="bottom center" data-inverted="">
              Day
            </sui-dropdown-item>
            <sui-dropdown-item secondary content="W" :style="timeFrame == 'W'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              " :negative="timeFrame == 'W'" @click="changeTimeFrame('W')" data-tooltip="Weekly View"
              data-position="bottom center" data-inverted="">
              Week
            </sui-dropdown-item>
            <sui-dropdown-item content="M" :style="timeFrame == 'M'
                ? {
                  ...themeSecondaryColor,
                  ...themeFontColor,
                  color: 'white',
                }
                : {} && themeFontColor
              " :negative="timeFrame == 'M'" @click="changeTimeFrame('M')" data-tooltip="Monthly View"
              data-position="bottom center" data-inverted="" secondary>Month
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>

        <sui-dropdown style="display: flex; justify-content: center; align-items: center"
          :text="chartVisibleRange ? chartVisibleRange : '1Y'" class="button secondary mini width-change-max">
          <sui-dropdown-menu class="width-change-item" :style="themePrimaryColor" style="
              z-index: 101;
              width: 170px;
              left: 0;
              top: 37px;
              padding-bottom: 8px;
            ">
            <sui-dropdown-item v-for="timeRange in filteredTimeRanges" :key="timeRange.range"
              @click="chartVisibleRange = timeRange.range" :style="chartVisibleRange == timeRange.range
                  ? {
                    ...themeSecondaryColor,
                    ...themeFontColor,
                    color: 'white',
                  }
                  : {} && themeFontColor
                " :disabled="isTrainingMode">
              {{ timeRange.range }}
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>

        <div class="dropdown" style="padding-bottom: 10px">
          <button class="dropbtn header-menu-mob-btn" style="height: 2.5rem; background-color: #414343 !important">
            <sui-icon style="
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
              " name="bars icon small" />
          </button>
          <div class="dropdown-content" :style="themePrimaryColor" style="left: 0; width: 170px">
            <div class="dropdownC" :style="themeFontColor"
              @mouseover="!isTrainingMode && enterThemehoverColor($event.target)"
              @mouseout="!isTrainingMode && exitElementColor($event.target)">
              <button class="dropbtnC header-menu-nested-drpdwn" style="padding-top: 8px" :style="themeFontColor"
                :disabled="isTrainingMode" @mouseover="!isTrainingMode && enterThemehoverColor($event.target)"
                @mouseout="!isTrainingMode && exitElementColor($event.target)">
                <sui-icon class="menu_icon" :style="themeFontColor" name="search icon " />
                Scan
              </button>
              <div v-if="!isTrainingMode" class="dropdown-contentC" :style="themePrimaryColor"
                style="padding-bottom: 10px; left: 35px">
                <a @click="patterns = !patterns" :style="themeFontColor"
                  @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">Scan for
                  Patterns & Events</a>
                <a :style="themeFontColor" @click="activity = !activity"
                  @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">Scan For
                  Activity</a>
                <a :style="themeFontColor" @click="candleDialog = !candleDialog"
                  @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">Scan For
                  candles</a>
                <a :style="themeFontColor" @click="indicators = !indicators"
                  @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">Scan For
                  Indicators & Events</a>
                <a :style="themeFontColor" @click="darvasBoxes = !darvasBoxes"
                  @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">Scan For
                  Darvas Boxes</a>
                <a :style="themeFontColor" @click="fundementals = !fundementals"
                  @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">Scan For
                  Fundamentals</a>

              </div>
            </div>

            <!-- 
           
           <sui-dropdown-item >
             
           </sui-dropdown-item> -->
            <div class="dropdownC" :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
              @mouseout="exitElementColor($event.target)">
              <button :disabled="isTrainingMode" class="dropbtnC header-menu-nested-drpdwn" :style="themeFontColor"
                @mouseover="!isTrainingMode && enterThemehoverColor($event.target)"
                @mouseout="!isTrainingMode && exitElementColor($event.target)">
                <sui-icon class="menu_icon" :style="themeFontColor" name="magic icon " />

                Guru's Toolbox
              </button>
              <div v-if="!isTrainingMode" class="dropdown-contentC" :style="themePrimaryColor"
                style="left: 35px; padding-bottom: 10px">
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="capitulationDialog = !capitulationDialog"
                  @mouseout="exitElementColor($event.target)">Capitulation Days</a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="franksPatternDialog = !franksPatternDialog"
                  @mouseout="exitElementColor($event.target)">Frank's Pattern</a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="samsPatternDialog = !samsPatternDialog" @mouseout="exitElementColor($event.target)">Sam's
                  Darvas Boxes</a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)" @click="
                  higherHighHigherLowsDialog = !higherHighHigherLowsDialog
                  " @mouseout="exitElementColor($event.target)">Higher Hgh, Higher Low</a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="bollingerSqueezeDialog = !bollingerSqueezeDialog"
                  @mouseout="exitElementColor($event.target)">Bollinger Squeeze</a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="divergenceScanDialog = !divergenceScanDialog"
                  @mouseout="exitElementColor($event.target)">Divergence Scan
                </a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="bigCandleDialog = !bigCandleDialog" @mouseout="exitElementColor($event.target)">Big Candle
                </a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="pocketPivotDialog = !pocketPivotDialog" @mouseout="exitElementColor($event.target)">Pocket
                  Piot
                </a>
                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                  @click="heikenAshiDialog = !heikenAshiDialog" @mouseout="exitElementColor($event.target)">Heiken
                  Ashi</a>

              </div>
            </div>
            <a :style="themeFontColor" @click="favouriteScan = !favouriteScan"
              @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">
              <sui-icon class="menu_icon" :style="themeFontColor" name="heart icon " />

              Favourite Scanner
            </a>
            <a :style="themeFontColor" @click="tradePlanner = !tradePlanner"
              @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">
              <sui-icon class="menu_icon" :style="themeFontColor" name="table icon " />

              Trade planner
            </a>
            <a :style="themeFontColor" @click="videoDialog = !videoDialog"
              @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">
              <sui-icon class="menu_icon" :style="themeFontColor" name="play circle icon " />
              Friday broadcast</a>
            <a :style="themeFontColor" @click="onClickTrainingMode" @mouseover="enterThemehoverColor($event.target)"
              @mouseout="exitElementColor($event.target)">
              <sui-icon class="menu_icon" :style="themeFontColor" name="graduation cap icon " />
              training Mode</a>
            <a :style="themeFontColor" @click="showSettings" @mouseover="enterThemehoverColor($event.target)"
              @mouseout="exitElementColor($event.target)">
              <sui-icon class="menu_icon" :style="themeFontColor" name="settings icon " />
              Setting</a>

            <a :style="themeFontColor" @click="checkDarkMode()" @mouseover="enterThemehoverColor($event.target)"
              @mouseout="exitElementColor($event.target)">
              <sui-icon class="menu_icon" :style="themeFontColor"
                :name="themeSettings.id === 'dark' ? 'sun ' : 'moon outline'" />
              {{ themeSettings.id === 'dark' ? 'Classic' : 'Dark' }}</a>

            <div class="dropdownC" :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
              @mouseout="exitElementColor($event.target)">
              <button class="dropbtnC header-menu-nested-drpdwn" style="padding-bottom: 10px" :style="themeFontColor"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">
                <sui-icon class="menu_icon" :style="themeFontColor" name="user icon " />
                User
              </button>
              <div class="dropdown-contentC" :style="themePrimaryColor">
                <a :style="themeFontColor" @click="open = !open" @mouseover="enterThemehoverColor($event.target)"
                  @mouseout="exitElementColor($event.target)">My Account</a>
                <a h:style="themeFontColor" @mouseover="enterThemehoverColor($event.target)" @click="logoutAndNavigate"
                  @mouseout="exitElementColor($event.target)">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- first chart control group -->
      <div class="chart-controls" id="chart-controls-header">
        <sui-button-group :widths="10" icons>
          <custom-image-icon-button imageSrc="/images/bar.svg"
            :style="selectedChartType === 'Bars' ? themeSecondaryColor : {}" :isToggled="selectedChartType === 'Bars'"
            @click="changeType('Bars')" tooltipText="Bar Chart" tooltipPosition="bottom center" />
          <custom-image-icon-button imageSrc="/images/line.svg" :style="selectedChartType === 'LineChart' ? themeSecondaryColor : {}
            " :isToggled="selectedChartType == 'LineChart'" @click="changeType('LineChart')" tooltipText="Line Chart"
            tooltipPosition="bottom center" />
          <custom-image-icon-button imageSrc="/images/candlestick.svg"
            :style="selectedChartType === 'Candles' ? themeSecondaryColor : {}"
            :isToggled="selectedChartType == 'Candles'" @click="changeType('Candles')" tooltipText="Candlestick Chart"
            tooltipPosition="bottom center" />
          <custom-image-icon-button imageSrc="/images/kagi.svg"
            :style="selectedChartType === 'Kagi' ? themeSecondaryColor : {}" :isToggled="selectedChartType == 'Kagi'"
            @click="changeType('Kagi')" tooltipText="Kagi Chart" tooltipPosition="bottom center"
            :disabled="isTrainingMode" />
          <custom-image-icon-button imageSrc="/images/heikenAshi.svg" :style="selectedChartType === 'HeikenAshi' ? themeSecondaryColor : {}
            " :isToggled="selectedChartType == 'HeikenAshi'" @click="changeType('HeikenAshi')"
            tooltipText="Heikin Ashi Chart" tooltipPosition="bottom center" :disabled="isTrainingMode" />
          <custom-image-icon-button imageSrc="/images/updown.svg" :style="selectedChartType === 'UpAndDown' ? themeSecondaryColor : {}
            " :isToggled="selectedChartType == 'UpAndDown'" @click="changeType('UpAndDown')"
            tooltipText="Up/Down Chart" tooltipPosition="bottom center" :disabled="isTrainingMode" />
          <custom-image-icon-button imageSrc="/images/point-figure.svg"
            :style="selectedChartType === 'PnF' ? themeSecondaryColor : {}" :isToggled="selectedChartType == 'PnF'"
            @click="changeType('PnF')" tooltipText="Point & Figures Chart" tooltipPosition="bottom center"
            :disabled="isTrainingMode" />
          <custom-image-icon-button imageSrc="/images/trend.svg"
            :style="selectedChartType === 'BarTrend' ? themeSecondaryColor : {}"
            :isToggled="selectedChartType == 'BarTrend'" @click="changeType('BarTrend')" tooltipText="Bar Trend Chart"
            tooltipPosition="bottom center" />
          <custom-image-icon-button imageSrc="/images/compare.svg" :style="selectedChartType === 'CompareChartMain'
              ? themeSecondaryColor
              : {}
            " :isToggled="selectedChartType == 'CompareChartMain'" @click="changeChartToCompareStocksType"
            tooltipText="Comparison Chart" tooltipPosition="bottom center" :disabled="isTrainingMode" />
          <custom-image-icon-button :isToggled="chartToggleVolume" imageSrc="/images/volume-chart.svg"
            @click="toggleVloume" tooltipText="Toggle Volume" tooltipPosition="bottom center"
            :style="chartToggleVolume ? themeSecondaryColor : {}" />
        </sui-button-group>
      </div>
      <sui-dropdown style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
        " class="button secondary" id="Chart-Controls-Block1">
        <sui-image style="" class="mob-tab-chart-img" :src="chartImgAddress ? chartImgAddress : '/images/bar.svg'"
          size="mini" />
        <sui-dropdown-menu :style="themePrimaryColor" style="
            padding-bottom: 8px;
            z-index: 101;
            width: 170px;
            left: 0;
            top: 37px;
            background-color: blueviolet !important;
          ">
          <sui-dropdown-item @click="changeType('Bars', '/images/bar.svg')" style="font-size: 1rem" :style="selectedChartType === 'Bars'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg id="bar" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.005 20"
              class="svgImage">
              <line id="Line_1" data-name="Line 1" y2="17.015" transform="translate(3.849 2.985)" stroke-width="2" />
              <line id="Line_2" data-name="Line 2" x2="3.947" transform="translate(4 6)" stroke-width="2" />
              <line id="Line_3" data-name="Line 3" x2="3.947" transform="translate(0 16.985)" stroke-width="2" />
              <line id="Line_4" data-name="Line 4" y2="17" transform="translate(16.067)" stroke-width="2" />
              <line id="Line_5" data-name="Line 5" x2="4" transform="translate(16.005 3)" stroke-width="2" />
              <line id="Line_6" data-name="Line 6" x2="3.948" transform="translate(12.031 12.031)" stroke-width="2" />
            </svg>
            Bar Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('LineChart', '/images/line.svg')" :style="selectedChartType === 'LineChart'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.995 19.992" class="svgImage">
              <g id="line" transform="translate(0 0)">
                <line id="Line_27" data-name="Line 27" y1="11.951" x2="7.574" transform="translate(0.845 7.505)"
                  stroke-width="2" />
                <line id="Line_28" data-name="Line 28" x2="3.158" y2="4.982" transform="translate(8.419 7.505)"
                  stroke-linecap="round" stroke-width="2" />
                <line id="Line_29" data-name="Line 29" y1="11.952" x2="7.574" transform="translate(11.577 0.535)"
                  stroke-width="2" />
              </g>
            </svg>
            Line Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('Candles', '/images/candlestick.svg')" style="font-size: 1rem" :style="selectedChartType === 'Candles'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg id="candlestick" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20.015"
              class="svgImage">
              <rect id="Rectangle_1" data-name="Rectangle 1" width="6.029" height="11.015"
                transform="translate(1 5.029)" stroke-linejoin="round" stroke-width="2" />
              <line id="Line_7" data-name="Line 7" y2="4.261" transform="translate(3.985 1)" stroke-linejoin="round"
                stroke-width="2" />
              <line id="Line_8" data-name="Line 8" y2="3.015" transform="translate(3.985 17)" stroke-linejoin="round"
                stroke-width="2" />
              <line id="Line_9" data-name="Line 9" y1="3" transform="translate(15.985)" stroke-linejoin="round"
                stroke-width="2" />
              <rect id="Rectangle_2" data-name="Rectangle 2" width="5.971" height="9.044"
                transform="translate(13.029 3)" stroke-linejoin="round" stroke-width="2" />
              <line id="Line_10" data-name="Line 10" y1="6" transform="translate(16.015 13.029)" stroke-linejoin="round"
                stroke-width="2" />
            </svg>
            Candlestick Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('Kagi', '/images/kagi.svg')" style="font-size: 1rem" :style="selectedChartType === 'Kagi'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg id="kagi" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.033 20.011"
              class="svgImage">
              <line id="Line_17" data-name="Line 17" y1="13" transform="translate(1 7.011)" stroke-width="2" />
              <line id="Line_18" data-name="Line 18" x1="5" transform="translate(0 8)" stroke-width="2" />
              <line id="Line_19" data-name="Line 19" y1="5" transform="translate(5 7)" stroke-width="2" />
              <line id="Line_20" data-name="Line 20" x1="5" transform="translate(4 12)" stroke-width="2" />
              <line id="Line_21" data-name="Line 21" y1="13" transform="translate(9)" stroke-width="2" />
              <line id="Line_22" data-name="Line 22" x1="5.437" transform="translate(8.563 1)" stroke-width="2" />
              <line id="Line_23" data-name="Line 23" y1="17" transform="translate(13 0.031)" stroke-width="2" />
              <line id="Line_24" data-name="Line 24" x1="5" transform="translate(12 17.031)" stroke-width="2" />
              <line id="Line_25" data-name="Line 25" y1="17" transform="translate(17 1)" stroke-width="2" />
              <line id="Line_26" data-name="Line 26" x1="4.033" transform="translate(16 1)" stroke-width="2" />
            </svg>
            Kagi Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('HeikenAshi', '/images/heikenAshi.svg')" style="font-size: 1rem" :style="selectedChartType === 'HeikenAshi'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.128 18.329" class="svgImage">
              <g id="heikenAshi" transform="translate(-0.33 -0.075)">
                <rect id="rect3" width="6.029" height="11.015" transform="translate(0.83 5.283)" stroke-width="1" />
                <line id="line5" y2="4.261" transform="translate(3.731 1.594)" stroke-width="1" />
                <line id="line7" y2="3.015" transform="translate(3.731 15.389)" stroke-width="1" />
                <rect id="rect3-5" width="6.029" height="11.015" transform="translate(12.93 2.21)" stroke-width="1" />
                <line id="line7-2" y2="3.015" transform="translate(16.029 12.656)" stroke-width="1" />
                <line id="line5-7" y2="4.261" transform="translate(15.859 0.075)" stroke-width="1" />
              </g>
            </svg>
            Heikin Ashi Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('UpAndDown', '/images/updown.svg')" :style="selectedChartType === 'UpAndDown'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg viewBox="0 0 20.005 20" xmlns="http://www.w3.org/2000/svg" width="15" height="15" class="svgImage">
              <line id="Line_1" data-name="Line 1" y2="19.689" stroke-width="2" x1="3.849" y1="1.067" x2="3.849" />
              <line id="Line_2" data-name="Line 2" x2="3.947" transform="matrix(1, 0, 0, 1, 4, 6)" stroke-width="2" />
              <line id="Line_3" data-name="Line 3" x2="3.947" transform="matrix(1, 0, 0, 1, 0, 16.985001)"
                stroke-width="2" />
              <line id="Line_4" data-name="Line 4" y2="18.415" stroke-width="2" x1="15.252" y1="3.717" x2="15.252" />
              <line id="Line_5" data-name="Line 5" x2="14.825" stroke-width="2" x1="10.825" y1="7.964" y2="7.964" />
              <line id="Line_6" data-name="Line 6" x2="19.169" stroke-width="2" x1="15.221" y1="13.782" y2="13.782" />
            </svg>
            Up/Down Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('PnF', '/images/point-figure.svg')" :style="selectedChartType === 'PnF'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.805 20.082" class="svgImage">
              <g id="point-figure" transform="translate(0)">
                <g id="Group_1" data-name="Group 1">
                  <line id="Line_1" data-name="Line 1" y1="4.582" x2="7.156" transform="translate(0.539 3.206)"
                    stroke-width="2" />
                  <line id="Line_2" data-name="Line 2" x1="7.156" y1="4.581" transform="translate(0.539 3.197)"
                    stroke-width="2" />
                </g>
                <g id="Group_2" data-name="Group 2">
                  <line id="Line_3" data-name="Line 3" y1="4.582" x2="7.156" transform="translate(0.539 8.926)"
                    stroke-width="2" />
                  <line id="Line_4" data-name="Line 4" x1="7.156" y1="4.581" transform="translate(0.539 8.917)"
                    stroke-width="2" />
                </g>
                <g id="Group_3" data-name="Group 3">
                  <line id="Line_5" data-name="Line 5" y1="4.582" x2="7.156" transform="translate(0.539 14.658)"
                    stroke-width="2" />
                  <line id="Line_6" data-name="Line 6" x1="7.156" y1="4.581" transform="translate(0.539 14.649)"
                    stroke-width="2" />
                </g>
                <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="2.992" cy="2.204" rx="2.992" ry="2.204"
                  transform="translate(12.821 1)" stroke-width="2" />
                <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="2.992" cy="2.204" rx="2.992" ry="2.204"
                  transform="translate(12.821 7.21)" stroke-width="2" />
                <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.992" cy="2.204" rx="2.992" ry="2.204"
                  transform="translate(12.821 13.42)" stroke-width="2" />
              </g>
            </svg>
            Point & Figures Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeType('BarTrend', '/images/trend.svg')" :style="selectedChartType === 'BarTrend'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            ">
            <svg id="trend" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20"
              class="svgImage">
              <line id="Line_7" data-name="Line 7" y2="17.015" transform="translate(4.984 2.985)" stroke-width="2" />
              <line id="Line_8" data-name="Line 8" x2="3.946" transform="translate(5.043 6)" stroke-width="2" />
              <line id="Line_9" data-name="Line 9" x2="3.947" transform="translate(1.011 16.985)" stroke-width="2" />
              <line id="Line_10" data-name="Line 10" y2="17" transform="translate(14.986)" stroke-width="2" />
              <line id="Line_11" data-name="Line 11" x2="4" transform="translate(14.986 3)" stroke-width="2" />
              <line id="Line_12" data-name="Line 12" x2="3.947" transform="translate(11.014 12.031)" stroke-width="2" />
              <path id="Path_1" data-name="Path 1" d="M1,13.015c0-2.227,4.032-4.029,9.015-4.029" stroke-width="2" />
              <path id="Path_2" data-name="Path 2" d="M19,6c0,1.65-4.019,2.985-8.985,2.985" stroke-width="2" />
            </svg>
            Bar Trend Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="changeChartToCompareStocksType" :style="themeFontColor">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.008 20.403" class="svgImage">
              <g id="compare" transform="translate(0 0)">
                <line id="Line_11" data-name="Line 11" y1="11.951" x2="7.574" transform="translate(0.845 7.505)"
                  stroke-width="2" />
                <line id="Line_12" data-name="Line 12" x2="3.158" y2="4.982" transform="translate(8.419 7.505)"
                  stroke-linecap="round" stroke-width="2" />
                <line id="Line_13" data-name="Line 13" y1="11.952" x2="7.574" transform="translate(11.577 0.535)"
                  stroke-width="2" />
                <line id="Line_14" data-name="Line 14" y1="3.951" x2="11.574" transform="translate(0.845 15.505)"
                  stroke-width="2" />
                <line id="Line_15" data-name="Line 15" x2="3.158" y2="2.982" transform="translate(12.419 15.505)"
                  stroke-linecap="round" stroke-width="2" />
                <line id="Line_16" data-name="Line 16" y1="5.952" x2="3.574" transform="translate(15.577 12.535)"
                  stroke-width="2" />
              </g>
            </svg>
            Comparison Chart
          </sui-dropdown-item>
          <sui-dropdown-item @click="toggleVloume" :style="themeFontColor" style="display:flex;align-items:center;">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
            </svg>

            <span style="margin-left:12px;">
              Toggle Volume
            </span>
          </sui-dropdown-item>
        </sui-dropdown-menu>
      </sui-dropdown>

      <sui-dropdown :disabled="isChartLoading" :text="timeFrame" class="button secondary" style="margin-left: 9px"
        id="timeFrameBlock1">
        <sui-dropdown-menu :style="themePrimaryColor"
          style="z-index: 101; width: 150px; left: 0; top: 37px; color: black">
          <sui-dropdown-item secondary content="D" :style="timeFrame == 'D'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            " :negative="timeFrame == 'D'" @click="changeTimeFrame('D')" data-tooltip="Daily View"
            data-position="bottom center" data-inverted="">
            Day
          </sui-dropdown-item>
          <sui-dropdown-item secondary content="W" :style="timeFrame == 'W'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            " :negative="timeFrame == 'W'" @click="changeTimeFrame('W')" data-tooltip="Weekly View"
            data-position="bottom center" data-inverted="">
            Week
          </sui-dropdown-item>
          <sui-dropdown-item secondary content="M" :style="timeFrame == 'M'
              ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
              : {} && themeFontColor
            " :negative="timeFrame == 'M'" @click="changeTimeFrame('M')" data-tooltip="Monthly View"
            data-position="bottom center" data-inverted="">Month
          </sui-dropdown-item>
        </sui-dropdown-menu>
      </sui-dropdown>

      <sui-dropdown :text="chartVisibleRange ? chartVisibleRange : '1Y'" class="button secondary"
        style="margin-left: 5px" id="timeFrameBlock2">
        <sui-dropdown-menu :style="themePrimaryColor" style="z-index: 101; width: 170px; left: 0; top: 37px">
          <sui-dropdown-item v-for="timeRange in filteredTimeRanges" :key="timeRange.range"
            @click="chartVisibleRange = timeRange.range || mob" style="font-size: 0.9em" :style="chartVisibleRange == timeRange.range
                ? { ...themeSecondaryColor, ...themeFontColor, color: 'white' }
                : {} && themeFontColor
              " :disabled="isTrainingMode">
            {{ timeRange.range }}
          </sui-dropdown-item>
        </sui-dropdown-menu>
      </sui-dropdown>

      <!-- first time frame group -->
      <div class="time-frames-d1-to-M" id="time-frames-header">
        <sui-button-group :widths="3" icons>
          <sui-button secondary content="D" :disabled="isChartLoading"
            :style="timeFrame == 'D' ? themeSecondaryColor : {}" :negative="timeFrame == 'D'"
            @click="changeTimeFrame('D')" data-tooltip="Daily View" data-position="bottom center" data-inverted="" />
          <sui-button secondary content="W" :disabled="isChartLoading"
            :style="timeFrame == 'W' ? themeSecondaryColor : {}" :negative="timeFrame == 'W'"
            @click="changeTimeFrame('W')" data-tooltip="Weekly View" data-position="bottom center" data-inverted="" />
          <sui-button secondary :disabled="isChartLoading" content="M"
            :style="timeFrame == 'M' ? themeSecondaryColor : {}" :negative="timeFrame == 'M'"
            @click="changeTimeFrame('M')" data-tooltip="Monthly View" data-position="bottom center" data-inverted="" />
        </sui-button-group>
      </div>

      <!-- data range -->
      <div class="data-range" id="data-range-header">
        <sui-button-group :widths="5" icons>
          <sui-button v-for="timeRange in filteredTimeRanges" :key="timeRange.range" secondary
            :content="timeRange.range" :style="chartVisibleRange == timeRange.range ? themeSecondaryColor : {}
              " @click="chartVisibleRange = timeRange.range" :data-tooltip="timeRange.toolTip"
            data-position="bottom center" data-inverted="" :disabled="isTrainingMode" />
        </sui-button-group>
      </div>

      <!-- data range small -->
      <div class="smaller-time-controls" style="z-index: 1" id="smaller-time-header">
        <sui-button-group icons>
          <sui-dropdown :text="timeFrame" :icon="null" data-tooltip="Time Frames" data-position="bottom center"
            data-inverted="" class="button secondary" :disabled="isTrainingMode || isChartLoading">
            <sui-dropdown-menu>
              <sui-dropdown-item @click="changeTimeFrame('D')">
                D
              </sui-dropdown-item>
              <sui-dropdown-item @click="changeTimeFrame('W')">
                W
              </sui-dropdown-item>
              <sui-dropdown-item @click="changeTimeFrame('M')">
                M
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>

          <sui-dropdown :text="chartVisibleRange" :icon="null" class="button secondary" data-tooltip="Visible Ranges"
            data-position="bottom center" data-inverted="">
            <sui-dropdown-menu>
              <sui-dropdown-item @click="chartVisibleRange = '3M'">
                3M
              </sui-dropdown-item>
              <sui-dropdown-item @click="chartVisibleRange = '6M'">
                6M
              </sui-dropdown-item>
              <sui-dropdown-item @click="chartVisibleRange = 'YTD'">
                YTD
              </sui-dropdown-item>
              <sui-dropdown-item @click="chartVisibleRange = '1Y'">
                1Y
              </sui-dropdown-item>
              <sui-dropdown-item @click="chartVisibleRange = '5Y'">
                5Y
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </sui-button-group>
      </div>

      <!-- settings -->
      <div class="settings-group r" id="settings-group-header" style="z-index: 1; font-size: 1.1em">
        <sui-button-group icons>
          <sui-dropdown icon="search" class="button secondary" :disabled="isTrainingMode"
            :data-tooltip="mousedown['scan'] && 'Scanning Tools'" data-position="bottom center" data-inverted=""
            @mouseenter="mousedown['scan'] = true" @click="mousedown['scan'] = false">
            <!-- style='font-size:1.1em' 679 - 702-->
            <sui-dropdown-menu :style="themePrimaryColor">
              <sui-dropdown-item @click="patterns = !patterns" style="font-size: 1.1em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">
                Patterns & Events
              </sui-dropdown-item>
              <sui-dropdown-item @click="activity = !activity" style="font-size: 1.1em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">
                Activity
              </sui-dropdown-item>
              <sui-dropdown-item @click="candleDialog = !candleDialog" style="font-size: 1.1em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Candles
              </sui-dropdown-item>
              <sui-dropdown-item @click="indicators = !indicators" style="font-size: 1.1em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Indicator & Events
              </sui-dropdown-item>
              <sui-dropdown-item @click="darvasBoxes = !darvasBoxes" style="font-size: 1.1em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Darvas Boxes
              </sui-dropdown-item>
              <sui-dropdown-item @click="fundementals = !fundementals" style="font-size: 1.1em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Fundamentals
              </sui-dropdown-item>
              
            </sui-dropdown-menu>
          </sui-dropdown>

          <sui-dropdown icon="magic" class="button secondary" :disabled="isTrainingMode"
            :data-tooltip="mousedown['gurus'] && 'Guru\'s Toolbox'" data-position="bottom center" data-inverted=""
            @mouseenter="mousedown['gurus'] = true" @click="mousedown['gurus'] = false">
            <sui-dropdown-menu :style="themePrimaryColor">
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
                @click="capitulationDialog = !capitulationDialog" style="font-size: 1.05em">Capitulation Days
              </sui-dropdown-item>
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
                @click="franksPatternDialog = !franksPatternDialog" style="font-size: 1.05em">Frank's Pattern
              </sui-dropdown-item>
              <sui-dropdown-item @click="samsPatternDialog = !samsPatternDialog" style="font-size: 1.05em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Sam's Darvas Boxes
              </sui-dropdown-item>
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)" @click="
                  higherHighHigherLowsDialog = !higherHighHigherLowsDialog
                  ">Higher High, Higher Low
              </sui-dropdown-item>
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
                @click="bollingerSqueezeDialog = !bollingerSqueezeDialog">Bollinger Squeeze
              </sui-dropdown-item>
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
                @click="divergenceScanDialog = !divergenceScanDialog">Divergence Scan
              </sui-dropdown-item>
              <sui-dropdown-item @click="bigCandleDialog = !bigCandleDialog" style="font-size: 1.05em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Big Candle
              </sui-dropdown-item>
              <sui-dropdown-item @click="pocketPivotDialog = !pocketPivotDialog" style="font-size: 1.05em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Pocket Pivot
              </sui-dropdown-item>
              <sui-dropdown-item @click="heikenAshiDialog = !heikenAshiDialog" style="font-size: 1.05em"
                :style="[themeFontColor, themePrimaryColor]" @mouseover="enterThemehoverColor($event.target)"
                @mouseout="exitElementColor($event.target)">Heiken Ashi
              </sui-dropdown-item>
              
            </sui-dropdown-menu>
          </sui-dropdown>
          <sui-button icon="heart" secondary @click="favouriteScan = !favouriteScan" data-tooltip="Favourite Scan"
            data-position="bottom center" data-inverted="" />
          <sui-button icon="table" secondary @click="tradePlanner = !tradePlanner" data-tooltip="Trade Planner"
            data-position="bottom center" data-inverted="" />

          <sui-button icon="download" secondary data-tooltip="CSV Export" data-position="bottom center"
            data-inverted="" />
          <sui-button icon="play circle" secondary @click="videoDialog = !videoDialog" data-tooltip="Friday Broadcast"
            data-position="bottom center" data-inverted="" />
          <sui-button icon="graduation" :secondary="!isTrainingMode" :negative="isTrainingMode"
            @click="onClickTrainingMode" data-tooltip="Training Mode" data-position="bottom center" data-inverted="" />

          <sui-button icon="settings" secondary @click="showSettings" data-tooltip="Settings"
            data-position="bottom center" data-inverted="" />
          <sui-button :icon="themeSettings.id === 'dark' ? 'sun' : 'moon outline'" secondary @click="checkDarkMode()"
            :data-tooltip="themeSettings.id === 'dark' ? 'Classic mode' : 'Dark mode'
              " data-position="bottom center" data-inverted="" />

          <sui-dropdown icon="user" class="button secondary" :data-tooltip="mousedown['profile'] && 'My Account'"
            data-position="bottom center" data-inverted="" @mouseenter="mousedown['profile'] = true"
            @click="mousedown['profile'] = false">
            <sui-dropdown-menu :style="themePrimaryColor">
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
                @click="open = !open">User</sui-dropdown-item>
              <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
                @click="logoutAndNavigate">Logout
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </sui-button-group>
      </div>
    </div>

    <comparing-symbols v-model="compareDialogOpen" />

    <!-- modal -->
    <training-mode v-model="trainingModeDialogOpen" />

    <!-- profile -->
    <profile-settings v-model="open" />

    <!-- trade planner -->
    <trade-planner v-model="tradePlanner" />

    <favourite-scan v-model="favouriteScan" />

    <!-- settings -->
    <!--    <settings v-model="settingsDialog" />-->
    <settings-new v-model="settingsDialog" />

    <!-- capitulation -->
    <capitulation v-model="capitulationDialog" />

    <!-- franks pattern -->
    <franks-pattern v-model="franksPatternDialog" />

    <!-- sams pattern -->
    <sams-pattern v-model="samsPatternDialog" />

    <!-- higher high higher low -->
    <higher-high-higher-low v-model="higherHighHigherLowsDialog" />

    <!-- bollinger squeeze -->
    <bollinger-squeeze v-model="bollingerSqueezeDialog" />

    <!-- divergence scan -->
    <divergence-scan v-model="divergenceScanDialog" />

    <!-- big candle -->
    <big-candle v-model="bigCandleDialog" />

    <!-- pocket pivot -->
    <pocket-pivot v-model="pocketPivotDialog" />

    <!-- heiken ashi -->
    <heiken-ashi v-model="heikenAshiDialog" />

    <!-- weekly video -->
    <weekly-video v-model="videoDialog" />

    <!-- SCANS -->

    <!-- activity -->
    <activity v-model="activity" />

    <!-- darvas boxes -->
    <darvas-boxes v-model="darvasBoxes" />

    <!-- fundementals -->
    <fundementals v-model="fundementals" />

    <!-- candle patterns -->
    <candle v-model="candleDialog" />

    <!-- chart patterns -->
    <chart-patterns v-model="patterns" />

    <!-- indicators scan -->
    <indicators v-model="indicators" />
    <!-- MainChartProps -->
    <MainChartProps v-model="mainChartProps" />

    <!--    Empty -->
    <dialog-box size="mini" modalHeader="Warning" :open="symbolIsMissing" :action="false">
      <!--      <h3 class="">-->
      <div class="ui center aligned header">
        <br />
        <h4 class="ui red header">
          <i class="exclamation triangle icon"></i>&nbsp;{{
            missingSymbolMsg()
          }}
        </h4>
        <br />
      </div>
      <!--      </h3> -->
      <template v-slot:action>
        <sui-modal-actions>
          <div>
            <sui-button @click.native="closeEmpty">Close </sui-button>
            <sui-button negative @click.native="closeAndSearch">Search symbol
            </sui-button>
          </div>
        </sui-modal-actions>
      </template>
    </dialog-box>

    <!--    Watches-->

    <WatchlistScanPopup v-model="showWatchesModal" :showLoader="showLoader" :duplicationError="duplicationError"
      @createWatches="createWatches" @toggleWatchesModalInner="toggleWatchesModalInner" />
    <dialog-box v-if="false" size="mini" modalHeader="Add Watch" :open="showWatchesModal" :action="false"
      style="z-index: 110">
      <!--      <h3 class="">-->
      <sui-form :loading="showLoader" size="small">
        <div class="ui center aligned header positionStyle fontStyle">
          <br />
          <h4 :style="themeDarkMode ? { color: '#A2A2A2' } : {}" class="ui header fontStyle" style="font-size: 14px">
            Please enter a name for your new watchlist:
          </h4>
          <br />

          <input type="text" v-model="newWatchName" :style="themeDarkMode
              ? {
                background: '#505050 !important',
                borderColor: '#151515',
                color: '#A2A2A2',
              }
              : {}
            " />
          <template v-if="duplicationError">
            <br />
            <h4 class="ui red header" style="font-size: 14px" :style="themeDarkMode ? { color: '#A2A2A2' } : {}">
              A watchlist with that name already exists
            </h4>
            <br />
          </template>
        </div>
      </sui-form>
      <template v-slot:action>
        <sui-modal-actions :style="themeDarkMode
            ? {
              background: '#151515 !important',
              borderColor: '#151515',
              color: '#A2A2A2',
            }
            : {}
          ">
          <div>
            <sui-button negative @click.native="createWatches" :style="themeSecondaryColor">Create
            </sui-button>
            <sui-button @click.native="toggleWatchesModalInner" :style="themeDarkMode
                ? {
                  background: '#505050 !important',
                  borderColor: '#151515',
                  color: '#A2A2A2',
                }
                : {}
              ">Cancel
            </sui-button>
          </div>
        </sui-modal-actions>
      </template>
    </dialog-box>

    <import-watch ref="importWatchRef" v-model="showWatchesImportModalLocal" :showLoader="showLoader"
      :duplicationError="duplicationError" :createWatches="createWatchesFromImport"
      :toggleWatchesModalInner="toggleImportWatchesModalInner" />
    <dialog-box size="mini" modalHeader="Rename Watch" :open="showWatchRenameModal" :action="false">
      <!--      <h3 class="">-->
      <sui-form :loading="showLoader" size="small">
        <div class="ui center aligned header">
          <br />
          <h4 class="ui header" style="font-size: 14px">
            Please enter a name for your new watchlist:
          </h4>
          <br />

          <input type="text" @keypress.enter="renameWatch" v-model="newWatchName" />
          <template v-if="duplicationError">
            <br />
            <h4 class="ui red header" style="font-size: 14px">
              A watchlist with that name already exists
            </h4>
            <br />
          </template>
        </div>
      </sui-form>

      <!--      </h3> -->
      <template v-slot:action>
        <sui-modal-actions>
          <div>
            <sui-button :disabled="!(newWatchName && newWatchName.trim())" negative @click.native="renameWatch">Create
            </sui-button>
            <sui-button @click.native="toggleWatchesRenameModalInner">Cancel
            </sui-button>
          </div>
        </sui-modal-actions>
      </template>
    </dialog-box>
    <dialog-box size="mini" modalHeader="Delete Watch" :open="showWatchesDeleteModal" :action="false">
      <!--      <h3 class="">-->
      <sui-form :loading="showLoader" size="small">
        <div class="ui center aligned">
          <br />
          <h4 class="ui">
            Are you sure you want to remove your
            <span style="color: red">({{ deleteWatchName }})</span> watchlist?
          </h4>
          <br />
        </div>
      </sui-form>
      <!--      </h3> -->
      <template v-slot:action>
        <sui-modal-actions>
          <div>
            <sui-button negative @click.native="deleteWatches">Yes </sui-button>
            <sui-button @click.native="toggleWatchesDeleteModalInner">No
            </sui-button>
          </div>
        </sui-modal-actions>
      </template>

      <!-- <div v-if="showLoader" class="loaderStyle" >
      <watches-loader/>
    </div> -->
    </dialog-box>

    <search-symbol v-model="symbolSearch" v-on:selectSymbolModalData="selectSymbolModalData" />
    <div class="ui horizontal list small" v-if="debugActive">
      <div class="item">
        <div class="content">
          <div class="header">Time Range</div>
          {{ chartRangeObj.startRange }}-{{ chartRangeObj.endRange }} /
          {{ chartRangeObj.daysCount }}
        </div>
      </div>
      <div class="item">
        <div class="content">
          <div class="header">Range / Count</div>
          {{ chartRangeObj.startIndex }}-{{ chartRangeObj.endIndex }} /
          {{ chartRangeObj.count }}
        </div>
      </div>
    </div>
    <!-- right -->

    <div class="right-group-window" id="right_group-window">
      <symbol-search-box :isDisabled="isTrainingMode" @refreshInput="refreshInput" @nextSymbol="nextSymbol"
        @prevSymbol="prevSymbol" @selectSymbolModal="selectSymbolModal" @TextMatchSymbolBox="TextMatchSymbolBox"
        :show-close-button="false" v-model="selectedSymbol" />
      <div v-if="symbolSearchTextLength === 0 && symbolSearchText" style="
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          top: 40px;
          height: 40px;
          width: 100px;
          background-color: white;
          box-shadow: 0px 0px 15px 1px grey;
        ">
        No results
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomImageIconButton from '@/baseComponents/CustomImageIconButton.vue'
import DialogBox from '@/baseComponents/DialogBox.vue'
import TradePlanner from 'Components/dialogBoxes/TradePlanner'
import TrainingMode from './dialogBoxes/TrainingMode.vue'
import SettingsNew from './dialogBoxes/SettingsNew.vue'
import ProfileSettings from './dialogBoxes/ProfileSettings.vue'
import Capitulation from './dialogBoxes/guru/Capitulation.vue'
import FranksPattern from './dialogBoxes/guru/FranksPattern.vue'
import HigherHighHigherLow from './dialogBoxes/guru/HigherHighHigherLow.vue'
import BollingerSqueeze from './dialogBoxes/guru/BollingerSqueeze.vue'
import DivergenceScan from './dialogBoxes/guru/DivergenceScan.vue'
import BigCandle from './dialogBoxes/guru/BigCandle.vue'
import PocketPivot from './dialogBoxes/guru/PocketPivot.vue'
import HeikenAshi from './dialogBoxes/guru/HeikenAshi.vue'
import Candle from './dialogBoxes/patterns/Candle.vue'
import WeeklyVideo from './dialogBoxes/WeeklyVideo.vue'
import Activity from './dialogBoxes/patterns/Activity.vue'
import DarvasBoxes from './dialogBoxes/patterns/DarvasBoxes.vue'
import Fundementals from './dialogBoxes/patterns/Fundementals.vue'
import SamsPattern from './dialogBoxes/guru/SamsPattern.vue'
import ChartPatterns from './dialogBoxes/patterns/ChartPatterns.vue'
import Indicators from './dialogBoxes/patterns/Indicators.vue'
import ComparingSymbols from './dialogBoxes/ComparingSymbols.vue'
import SymbolSearchBox from './SymbolSearchBox.vue'
import SearchSymbol from '../components/dialogBoxes/SearchSymbol'
import PatternBaseModal from './dialogBoxes/patterns/PatternBaseModal'
import ImportWatch from './dialogBoxes/watches/ImportWatch'
import MainChartProps from './dialogBoxes/MainChartProps.vue'
import WatchesLoader from './WatchesLoader.vue'
import { chartEvents } from '../chartEvents'
import { TimeRangesConfig } from '../utils/TimeRangesConfig'
import WatchlistScanPopup from "./dialogBoxes/patterns/WatchlistPopup";
import FavouriteScan from './dialogBoxes/FavouriteScan.vue'

export default {
  name: 'TopToolbox',
  components: {
    WatchlistScanPopup,
    ImportWatch,
    PatternBaseModal,
    CustomImageIconButton,
    DialogBox,
    FavouriteScan,
    TradePlanner,
    TrainingMode,
    ProfileSettings,
    Capitulation,
    FranksPattern,
    HigherHighHigherLow,
    BollingerSqueeze,
    DivergenceScan,
    BigCandle,
    PocketPivot,
    HeikenAshi,
    Candle,
    WeeklyVideo,
    Activity,
    DarvasBoxes,
    Fundementals,
    SamsPattern,
    ChartPatterns,
    Indicators,
    ComparingSymbols,
    SearchSymbol,
    SymbolSearchBox,
    SettingsNew,
    WatchesLoader,
    MainChartProps,
  },
  data() {
    return {
      symbolSearchText: '',
      colorTopBottom: '#eee',
      symbolSearchTextLength: null,
      chartIconName: 'chart bar icon',
      chartImgAddress: '',
      Id_check: '',
      Id_checkDD: '',
      timeRanges: TimeRangesConfig,
      toolItem: '',
      showUser: false,
      open: false,
      compareDialogOpen: false,
      trainingModeDialogOpen: false,
      tradePlanner: false,
      favouriteScan: false,
      videoDialog: false,
      settingsDialog: false,
      settingsDialogKey: 0,
      capitulationDialog: false,
      franksPatternDialog: false,
      samsPatternDialog: false,
      higherHighHigherLowsDialog: false,
      bollingerSqueezeDialog: false,
      divergenceScanDialog: false,
      bigCandleDialog: false,
      pocketPivotDialog: false,
      heikenAshiDialog: false,
      // scans
      activity: false,
      candleDialog: false,
      darvasBoxes: false,
      fundementals: false,
      indicators: false,
      emptysymbol: false,
      symbolSearch: false,
      patterns: false,
      //
      selectedSymbol: '',
      newWatchName: '',
      duplicationError: false,
      mousedown: { scan: true, gurus: true, profile: true },
      showLoader: false,
    }
  },
  methods: {
    ...mapActions([
      'setSymbolData',
      'setChartRange',
      'incrementSymbolIndex',
      'decrementSymbolIndex',
      'toggleSymbolMissing',
      'updateTrainingModeState',
      'enterThemehoverColor',
      'toggleWatchesModal',
      'createNewWatchList',
      'deleteWatchList',
      'toggleWatchesDeleteModal',
      'toggleWatchesRenameModal',
      'toggleWatchesImportModal',
      'renameWatchListItem',
      'exitElementColor',
      'toggleDarkMode',
      'setSelectedWatch'
    ]),
    // RightScroll(){
    //     document.getElementById('owais').style.scrollMarginRight -= 20;
    //     document.getElementById('owais').style.backgroundColor = 'pink'
    //   },
    createWatchesFromImport(name) {
      this.createWatches(name, false).then(d => {
        if (this.$refs?.importWatchRef?.updateWatchCode) {
          this.$refs?.importWatchRef?.updateWatchCode(d?.watchlist?.id)
        }
        this.toggleImportWatchesModalInner()
      })
    },
    async createWatches(name, updateModal = true) {
      // createNewWatchList
      this.showLoader = true
      let resp;
      this.newWatchName = ''
      let watchObj = this.watchLists.find((s) => s.name === name)
      if (watchObj) {
        this.duplicationError = true
        this.showLoader = false
      } else {
        if (this.scanCopyBtn) {
          resp = await this.createNewWatchList({ name: name, ScanResults: this.scanResults, updateModal },)
          this.$store.commit('SCAN_COPY_BUTTON', false)
        } else {
          resp = await this.createNewWatchList({ name: name, updateModal })
          const index = resp.data.findIndex((x) => { return x.name == name })
          this.setSelectedWatch(index)
        }
        // this.showLoader = false
        setTimeout(() => {
          this.showLoader = false
        }, 1000)

      }
      return resp
    },

    checkDarkMode() {
      this.toggleDarkMode(this.themeSettings.id).then((d) => {
      })
    },
    TextMatchSymbolBox(length, text) {
      this.symbolSearchTextLength = length
      this.symbolSearchText = TextEncoder
    },
    renameWatch(event) {
      event.preventDefault();
      if (!(this.newWatchName && this.newWatchName.trim())) return;
      this.showLoader = true
      let name = this.newWatchName
      this.newWatchName = ''
      let watchObj = this.watchLists.find((s) => s.name === name)
      if (watchObj) {
        this.duplicationError = true
      } else {
        this.renameWatchListItem(name)
      }
      setTimeout(() => {
        this.showLoader = false
      }, 1000)
    },

    deleteWatches() {
      // createNewWatchList
      this.showLoader = true
      this.deleteWatchList(this.selectedWatchData.id)
      setTimeout(() => {
        this.showLoader = false
      }, 1000)
    },
    toggleWatchesModalInner() {
      this.newWatchName = ''
      this.duplicationError = false
      this.toggleWatchesModal()
    },
    toggleImportWatchesModalInner() {
      this.newWatchName = ''
      this.duplicationError = false
      this.$store.commit('WATCHLIST_POPUP', false)
    },
    toggleWatchesRenameModalInner() {
      this.newWatchName = ''
      this.duplicationError = false
      this.toggleWatchesRenameModal()
    },
    toggleWatchesDeleteModalInner() {
      this.toggleWatchesDeleteModal()
    },

    SelectedMenuRow(id) {
      if (this.Id_check == '') {
        if (this.Id_checkDD == '') {
        } else {
          document.getElementById(this.Id_checkDD).style.color = 'white'
        }
        document.getElementById(id).style.backgroundColor = '#E4312C'
        this.Id_check = id
      } else {
        if (this.Id_checkDD == '') {
        } else {
          document.getElementById(this.Id_checkDD).style.color = 'white'
        }
        document.getElementById(id).style.backgroundColor = '#E4312C'
        document.getElementById(this.Id_check).style.backgroundColor = '#1B1C1D'
        if (id == this.Id_check) {
          document.getElementById(this.Id_check).style.color = 'white'
          document.getElementById(id).style.backgroundColor = '#E4312C'
        }
        this.Id_check = id
      }
    },
    missingSymbolMsg() {
      return `Requested symbol '${this.symbolName}' not found!`
    },
    selectSymbolModalData(val) {
      this.setSymbolData({ symbolName: val, timeFrame: localStorage.getItem("SET_TIME_FRAME") || "D" }).then((d) => {
        chartEvents.$emit('setChartRange', undefined, 'Symbol Modal')
        chartEvents.$emit('symbolChangeVerifyScanDetails')
        chartEvents.$emit('loadDrawingData')
      })
    },
    refreshInput() {
      this.selectedSymbol = this.selectedSymbol + ''
    },
    closeEmpty() {
      this.toggleSymbolMissing()
    },
    closeAndSearch() {
      this.closeEmpty()
      this.selectSymbolModal()
    },
    prevSymbol() {
      this.decrementSymbolIndex()
      this.refreshInput()
    },
    nextSymbol() {
      this.incrementSymbolIndex()
      this.refreshInput()
    },
    selectSymbolModal() {
      // this.incrementSymbolIndex()
      this.symbolSearch = !this.symbolSearch
    },
    logoutAndNavigate() {
      // localStorage.setItem('themeModeSave',this.themeDarkMode )
      localStorage.removeItem('state')
      this.$router.push({ name: 'login' })
      // this.$store.commit('THEME_MODE',JSON.parse(localStorage.getItem('themeModeSave')))
    },
    changeType(newType, mob_chartImg) {
      if (this.type !== newType) {
        this.chartImgAddress = mob_chartImg
        this.$store.dispatch('setNewChartType', { type: newType, location: "FromTopBar" })
      }
    },
    compareDialogOnClose() {
      this.compareDialogOpen = false
    },
    trainingModeDialogOnClose() {
      this.trainingModeDialogOpen = false
    },
    tradePlannerOnClose() {
      this.tradePlanner = false
    },
    favouriteScanOnClose() {
      this.favouriteScan = false
    },
    changeTimeFrame(tf) {
      const scanResultData = this.symbolName === this.SelectedScanSymbol
      let config = TimeRangesConfig[tf]
      let rangeMap = config.map((d) => d.range)
      // this.$store.dispatch('setNewChartTimeFrame',tf)
      this.setSymbolData({
        symbolName: this.symbolName,
        timeFrame: tf,
        scanResultData: scanResultData,
      }).then((d) => {
        // this.$store.dispatch('setNewChartTimeFrame',tf)
        const rangeExists = Object.keys(this.userData).includes(`${tf}_range`)
        let _index = 0;
        if (rangeExists) {
          const _range = this.userData[`${tf}_range`]
          _index = rangeMap.findIndex((x) => { return x == _range })
        }
        if (_index < 0) _index = 0;
        setTimeout(() => {
          chartEvents.$emit('setChartRange', rangeMap[_index], 'TimeFrameChange')
        })
        chartEvents.$emit('loadDrawingData')
      })
    },
    changeChartToCompareStocksType() {
      this.compareDialogOpen = true
    },
    onClickTrainingMode() {
      if (this.isTrainingMode) {
        return this.updateTrainingModeState({
          state: !this.isTrainingMode,
        })
      }

      this.trainingModeDialogOpen = !this.trainingModeDialogOpen
    },
    toggleVloume() {
      this.$store.dispatch('toggleVolumeChart')
    },
    showSettings() {
      this.settingsDialogKey = this.settingsDialogKey + 1
      this.settingsDialog = !this.settingsDialog
    },
  },
  computed: {
    ...mapState({
      chartRangeObj: (state) => state.chartRangeObj,
      debugActive: (state) => state.debugActive,
      selectedChartType: (state) => state.type,
      chartToggleVolume: (state) => state.chartToggleVolume,
    }),
    ...mapGetters([
      'getScanFavListTypes',
      'getGuruFavListTypes',
      'isChartLoading',
      'themeDarkMode',
      'scanResults',
      'scanCopyBtn',
      'symbolName',
      'timeFrame',
      'getChartVisibleRange',
      'getSymbolByIndex',
      'getSymbolsList',
      'symbolIsMissing',
      'showWatchesModal',
      'showWatchRenameModal',
      'showWatchesDeleteModal',
      'showWatchesImportModal',
      'watchLists',
      'selectedWatchCode',
      'isTrainingMode',
      'themeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themePrimaryColor',
      'themeSecondaryColor',
      'selectedWatchData',
      'SelectedScanSymbol',
      'themeFontColor',
      'mainChartProps',
      'userData'
    ]),
    /** @returns {String} */
    reversedTool: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.toolItem.reverse()
    },
    chartVisibleRange: {
      get() {
        return this.getChartVisibleRange
      },
      set(value) {
        if (value !== '' && value !== this.getChartVisibleRange) {
          this.$store.commit('CHART_PREDEFINE_RANGE_UPDATES')
          //TODO:CHANGE_EFFECTS
          // this.setChartRange(value)
          const key = `${this.timeFrame}_range`
          // localStorage.setItem(_range,value)
          chartEvents.$emit('brush_range_change')
          chartEvents.$emit('setChartRange', value, 'VisibleRangeChange')
          this.$store.dispatch('setNewTimeFrameRange', { key, value })
        }
      },
    },
    symbolsList() {
      return this.getSymbolsList.map((symbolItem) => ({
        key: symbolItem,
        value: symbolItem,
        text: symbolItem,
      }))
    },
    /** @returns {[{range: String, toolTip: String}]} */
    filteredTimeRanges() {
      return this.timeRanges[this.timeFrame]
    },
    deleteWatchName() {
      if (
        this.watchLists &&
        this.selectedWatchData &&
        this.selectedWatchData.name
      )
        return this.selectedWatchData.name
      else return ''
    },
    showWatchesImportModalLocal: {
      get() {
        return this.showWatchesImportModal
      },
      set(value) {
        this.toggleWatchesImportModal()
      },
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.colorTopBottom = '#2C2929'
      } else {
        this.colorTopBottom = '#eee'
      }
      localStorage.setItem('themeModeSave', this.themeDarkMode)
    },
    symbolName(newValue) {
      this.selectedSymbol = newValue
    },
    selectedSymbol(newValue) {
      if (newValue && this.selectedSymbol !== this.symbolName) {
        this.setSymbolData({ symbolName: newValue, timeFrame: localStorage.getItem("SET_TIME_FRAME") || "D" }).then((d) => {
          chartEvents.$emit('setChartRange', undefined, 'SearchSelectSymbol')
          chartEvents.$emit('symbolChangeVerifyScanDetails')
          chartEvents.$emit('loadDrawingData')
          this.$store.commit('SET_SEARCH_BOX_UPDATE', false)
        })
      }
    }
  },
}
</script>

<style>
.top-container2 {
  /* border-width:1px;
  border-style:solid; */
  border-bottom: 1px solid v-bind(colorTopBottom);
}

#ScanningtoolssItem:hover {
  background-color: greenyellow;
}

.item {
  padding: 0px;
  margin: 0px;
}

#ScanningToolId>i.dropdown.icon,
#GuruToolId>i.dropdown.icon,
#MyAccountId>i.dropdown.icon {
  position: relative;
  top: 10px;
}

.button {
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #04aa6d;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.button:hover {
  background-color: #3e8e41;
}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

/* .ui.button>.icon:not(.button) i.icon{
  margin: 0px;
 } */
.top-container i.icon {
  margin: 0 !important;
  background: transparent !important;
}

i.icon {
  background: transparent !important;
}

@media screen and (max-width: 944px) {
  #settings-group-header {
    display: none;
  }

  #timeFrameBlock1 {
    display: none !important;
  }

  #timeFrameBlock2 {
    display: none !important;
  }

  #Chart-Controls-Block1 {
    display: none !important;
  }

  #responsiveTab {
    display: flex;
  }
}

@media screen and (min-width: 1300px) {
  #timeFrameBlock1 {
    display: none;
  }

  #timeFrameBlock2 {
    display: none;
  }

  /* #Chart-Controls-Block1{
    display: none;
  } */
}

@media screen and (max-width: 1150px) {
  #Chart-Controls-Block1 {
    display: block;
  }

  #chart-controls-header {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  #Chart-Controls-Block1 {
    display: none !important;
  }
}

@media screen and (max-width: 1300px) {
  #timeFrameBlock1 {
    display: block;
  }

  #timeFrameBlock2 {
    display: block;
  }

  /* #Chart-Controls-Block1{
    display: block;
  } */
  #time-frames-header {
    display: none;
  }

  #data-range-header {
    display: none;
  }

  #smaller-time-header {
    display: none;
  }

  /* #chart-controls-header{
    margin-left: 0;
    display: none;
  } */
}

@media screen and (min-width: 944px) {
  #responsiveTab {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  #time-frames-header {
    display: none;
  }

  #data-range-header {
    display: none;
  }

  /* #chart-controls-header{
    margin-left: 0;
    display: none;
  } */
}

.top-toolbox {
  background-color: white;
  margin-top: 5px;
  margin-left: 5px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  height: 2.5rem;
  float: left;
}

.right-group-window {
  display: flex;
  margin-right: -5px;
  border-width: 2px;
  border-radius: 5px;
  box-sizing: border-box;
  float: right;
  width: 20%;
  justify-content: right;

  /* overflow: hidden; */
}

#indices-text {
  padding: 0 20px;
  width: 110px;
  font-size: 18px;
  border-width: 1px;
  border-color: #474949;
}

.logo {
  height: 100%;
}

.chart-controls {
  background-color: #555;
  display: flex;
  margin-left: 20px;
  border-width: 2px;
  border-radius: 5px;
  box-sizing: border-box;
  /* overflow: hidden; */
  position: relative;
  /* have a higher z-index than the tv chart  */
  z-index: 101;
}

.control-button {
  background-color: #474949;
  width: 30px;
  border: 1px;
  margin-right: 2px;
  color: white;
}

.control-button:first-child {
  border-radius: 5px;
}

.control-button:hover {
  background-color: red;
}

.smaller-time-controls {
  background-color: #555;
  display: flex;
  margin-left: 5px;
  border-width: 2px;
  border-radius: 5px;
  color: white;
}

.time-frames-d1-to-M {
  background-color: #555;
  display: flex;
  margin-left: 5px;
  border-width: 2px;
  border-radius: 5px;
  color: white;
  z-index: 101;
}

.data-range {
  background-color: #555;
  display: flex;
  margin-left: 5px;
  border-width: 2px;
  border-radius: 5px;
  color: white;
  z-index: 101;
}

.settings-group {
  background-color: #555;
  display: flex;
  margin-left: 5px;
  border-width: 2px;
  border-radius: 5px;
  color: white;
}

.drop-down {
  position: absolute;
}

.disabled-button {
  background-color: gray;
  font-weight: 400;
  width: 75px;
}

/* Style The Dropdown Button */
.drop-btn-up {
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 101;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 150px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 37px;
  border-radius: 2p;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 6px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: red;
  color: white m !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .drop-btn-up {
  background-color: red;
}

.selected {
  background-color: red;
}

.positionStyle {
  position: relative;
}

.loaderStyle {}

.button .secondary {
  font-size: 20px;
}

.fontStyle {
  font-size: 20px;
}

/* css new drop down */

.dropbtn {
  background-color: #1b1c1d;
  color: white;
  padding: 6px 19px;
  margin: 0 0.25em 0 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* .dropbtn:active{
background-color: blue;
} */
.dropdown {
  position: relative;
  display: inline-block;
}

/* child drop down */
.dropbtnC {
  background-color: transparent;
  color: black;
  width: 100%;
  text-align: left;
  padding: 5px 16px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

.dropdownC {
  position: relative;
  /* display: inline-block; */

  /* background-color: #04aa6d; */
}

.dropdown-contentC {
  display: none;
  position: absolute;
  right: 0;
  background-color: rgb(255, 255, 255);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 170px;
  left: 20px;
}

.dropdown-contentC a {
  color: black;
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.9em;
}

.dropdown-contentC h1 {
  color: black;
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.9em;
}

.dropdownC:hover .dropdown-contentC {
  display: block;
}

.menu_icon {
  background-color: transparent !important;
  padding-right: 22px;
}

.ui.dropdown>.dropdown.icon {
  display: none;
}

.header-menu-mob-btn {
  padding: 0.55571429em 0.9em !important;
}

@media screen and (max-width: 450px) {
  #responsiveTab .ui.button {
    padding: 0.98571429em 1em;
    width: 200% !important;
    margin: 0 !important;
  }

  .width-change {
    padding: 0.9em 1.1em !important;
  }

  #responsiveTab .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
    margin: 0 0 0 -0.21428571em;
  }

  #responsiveTab i.icon {
    margin: 0;
  }

  .header-menu-mob-btn {
    padding: 0.15571429em 0.7em !important;
  }
}

@media screen and (max-width: 300px) {
  #responsiveTab .ui.button {
    width: 30px !important;
  }
}

.svgImage {
  fill: currentColor;
  stroke: currentColor;
  margin-right: 10px;
}

.header-menu-nested-drpdwn {
  padding: 6px 16px;
  font-size: 0.95em;
}

.width-change-item .menu>.item {
  padding: 0.5rem 1.14285714rem !important;
}

#responsiveTab .ui.dropdown .menu>.item {
  padding: 0.6rem 1.14285714rem !important;
}

.mob-tab-chart-img {
  height: 12px !important;
  width: auto !important;
}

@media screen and (max-width: 300px) {
  .width-change {
    width: 200px;
  }

  .mob-tab-chart-img {
    height: 20px;
    width: 20px;
  }
}
</style>
