var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-box',{attrs:{"id":"mainForm","modalHeader":"Update Profile Settings","open":_vm.open,"action":false},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('sui-modal-actions',{style:(_vm.themeDarkMode
          ? {
              background: '#151515 !important',
              borderColor: '#151515',
              color: '#A2A2A2',
            }
          : {})},[_c('div',[_c('sui-button',{style:(_vm.themeSecondaryColor),attrs:{"disabled":_vm.showLoader},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}},[_vm._v("OK ")]),_c('sui-button',{style:(_vm.themeDarkMode
              ? {
                  background: '#505050 !important',
                  borderColor: '#151515',
                  color: '#A2A2A2',
                }
              : {}),attrs:{"disabled":_vm.showLoader},nativeOn:{"click":function($event){return _vm.onClose.apply(null, arguments)}}},[_vm._v("Close ")])],1)])]},proxy:true}])},[_c('sui-modal-content',{style:({ ..._vm.themeFontColor })},[(_vm.isError)?_c('sui-message',{staticStyle:{"background-color":"#FDF6F6","box-shadow":"0 0 0 1px #e0b4b4 inset,0 0 0 0 transparent"}},[_c('sui-message-header',{staticStyle:{"color":"#912D2B"}},[_vm._v("Action Forbidden")]),_c('p',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e(),_c('sui-modal-description',[(!_vm.passwordVerified)?_c('div',[_c('sui-form',{attrs:{"loading":_vm.showLoader},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('sui-form-field',{staticClass:"profile-font"},[_c('label',{style:(_vm.themeFontColor)},[_vm._v("Please verify your password")]),_c('sui-form-field',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],style:(_vm.themeDarkMode
                    ? { backgroundColor: '#505050', color: '#A2A2A2' }
                    : {}),attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})])],1)],1)],1):_vm._e(),(_vm.passwordVerified)?_c('div',[_c('sui-form',{attrs:{"loading":_vm.showLoader},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('sui-form-field',{staticClass:"profile-font"},[_c('label',{style:(_vm.themeFontColor)},[_vm._v("Enter your new email address")]),_c('sui-form-field',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],style:(_vm.themeDarkMode
                    ? { backgroundColor: '#505050', color: '#A2A2A2' }
                    : {}),attrs:{"readonly":_vm.emailVerified,"type":"email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])],1),(_vm.emailVerified)?_c('sui-form-field',{staticClass:"profile-font"},[_c('label',{style:(_vm.themeFontColor)},[_vm._v("Verify new email address")]),_c('sui-form-field',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userOtp),expression:"userOtp"}],style:(_vm.themeDarkMode
                    ? { backgroundColor: '#505050', color: '#A2A2A2' }
                    : {}),attrs:{"type":"text","placeholder":"Enter 6-digit OTP code"},domProps:{"value":(_vm.userOtp)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.userOtp=$event.target.value}}})])],1):_vm._e()],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }