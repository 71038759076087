<template>
  <sui-modal
    v-model="open"
    :size="'mini'"
  >
    <sui-modal-header >{{ modalHeader }}</sui-modal-header>
    <sui-modal-content >
        <p>
            It looks like you have logged into another session - Was this you?
        </p>
    </sui-modal-content>
    <sui-modal-actions  >
      <sui-button
      @click="onClickYes"
      > Yes </sui-button>
      <sui-button
        @click="onClickNo"
      >
        No
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

  <script>
import DialogBox from '@/baseComponents/DialogBox.vue'
export default {
  name: 'ComparingSymbols',
  data() {
    return {
          isLoading: false,
      modalHeader:'Error Box'
    }
  },


  components: {
    DialogBox,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    onClickYes: {
      type: Function,
    },
    onClickNo: {
      type: Function,
    }
  },
}
</script>

  <style>
.compare-chart-text {
  font-size: 15px;
}
.td {
}
@media screen and (max-width: 900) {
  .td {
  }
}
@media screen and (max-width: 900) {
  .tr {
  }
}
.tablebody {
  background-color: yellow;
}
.content {
}
.row-one {
  display: flex;
  justify-content: space-around;
}
.row-two {
  display: flex;
}

@media screen and (max-width: 900px) {
  .row-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
  }
  .row-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
  }
}
</style>
