<template>
  <div class="check-box-container">
    <input
      :type="type"
      :name="label"
      :value="checked"
      @change="$emit('input')"
      class="input-text-styles"
    />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: 'checkbox'
    },
    checked: {
      type: String | Number,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  name: 'BaseTextBox'
}
</script>

<style>
.check-box-container {
  display: flex;
  align-items: center;
}
.check-box-container > * {
  margin-left: 0.5rem;
}
</style>