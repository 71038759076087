<template>
  <dialog-box
    modalHeader="Friday Broadcast"
    :open="open"
    @onClose="onClose"
    :action="false"
    :closeIcon="true"
    :size="size"
  >
    <div>
      <iframe v-if="playVideo" width="100%" height="440px" id="weeklyPlayer" :src="youtubeEmbedUrl" frameborder="0" allowfullscreen />
    </div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
export default {
  name: 'WeeklyVideo',
  components: {
    DialogBox
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.open) {
      this.fetchYouTubeUrl();
    }
  },
  computed: {
    youtubeEmbedUrl() {
      // const videoId = this.extractVideoId(this.youtubeVideoUrl);
      const videoId = this.youtubeVideoUrl.split('/')[this.youtubeVideoUrl.split('/').length -1]
      return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
    },
  },
  methods: {
    extractVideoId(url) {
      const match = url.match(/[?&]v=([^?&]+)/);
      return match ? match[1] : null;
    },
    onClose() {
      this.playVideo = false
    },
    async fetchYouTubeUrl() {
      try {
        const response = await fetch('https://watch.protraderscans.com/friday');
        const text = await response.text();
        const youtubeUrl = this.extractYouTubeUrl(text);
        if (youtubeUrl) {
          this.youtubeVideoUrl = youtubeUrl;
        }
      } catch (error) {
        console.error('Error fetching YouTube URL:', error);
      }
    },
    extractYouTubeUrl(html) {
      // Extract YouTube URL from HTML
      const match = html.match(/window\.location\s*=\s*"([^"]+)";/);
      return match ? match[1] : null;
    }
  },
  data() {
    return {
      playVideo: true,
      youtubeVideoUrl: "https://watch.protraderscans.com/friday",
      size:'large'
    }
  },
  watch:{
    open(n){
      if (n) {
        this.fetchYouTubeUrl();
      }
    }
  }
}
</script>

<style></style>
