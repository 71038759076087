<template>
  <dialog-box
      modalHeader="Settings"
      :open="open"
      :action="false"
      :style="themeFontColor"
  >
    <div style="height: 60vh; padding: 8px">
      <div :style="{ ...themeFontColor, ...themePrimaryColor }">
        <div
            style="
            display: flex;
            gap: 5px;
            flex-direction: row;
            position: relative;
            bottom: -2px;
          "
        >
          <!-- <div > -->
          <div
              class="settingTabs"
              style=""
              @click="GeneralTab"
              :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isGeneralTabActive ? 'bold' : 'normal',
              boxShadow: isGeneralTabActive ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isGeneralTabActive ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>General</div>
          </div>
          <!-- </div> -->

          <!-- <div > -->
          <div
              class="settingTabs"
              @click="IndicatorTab"
              :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isIndicatorTabActive ? 'bold' : 'normal',
              boxShadow: isIndicatorTabActive
                ? '0px -.2px 1px 1px #DDDDDD'
                : '',
              borderRadius: isIndicatorTabActive ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Indicators</div>
          </div>
          <!-- </div> -->

          <!-- <div style=""> -->
          <div
              class="settingTabs"
              @click="ThemeTab"
              :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isThemeTabActive ? 'bold' : 'normal',
              boxShadow: isThemeTabActive ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isThemeTabActive ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Themes</div>
          </div>
          <!-- </div> -->
          <!-- <div style=""> -->
          <div
              v-if="onlyMatHasAccess"
              class="settingTabs"
              @click="PlusTab"
              :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isNewIndicator ? 'bold' : 'normal',
              boxShadow: isNewIndicator ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isNewIndicator ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Plus Charts</div>
          </div>
          <!-- </div> -->
        </div>
        <div
            style="border-top: 1px solid #dddddd; position: relative; top: 2px"
        />

        <div v-if="isGeneralTabActive">
          <sui-grid divided="vertically">
            <sui-grid-row :columns="2">
              <sui-grid-column padded="vertically" key="chartInfo">
                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">Chart</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row key="lineColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Line Color
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartLineColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="sideColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Unchanged Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartSideColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="upColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Up Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartUpColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="downColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Down Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartDownColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="barChange">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Use Up/Down Colour on Bar Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                                type="checkbox"
                                name="barChange"
                                v-model="chartBarChange"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="semiLogAxis">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Semi Log Price Axis
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                                type="checkbox"
                                name="semiLogAxis"
                                v-model="chartSemiLogAxis"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="volumeColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Volume Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartVolumeColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="upColorVolume">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Up Volume Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartUpColorVolume"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="downColorVolume">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Down Volume Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartDownColorVolume"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="barChangeVolume">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Use Up/Down Colours on Volume Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                                type="checkbox"
                                name="barChangeVolume"
                                v-model="chartBarChangeVolume"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="obvColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >On-Balance Volume Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="chartObvColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>
              </sui-grid-column>
              <sui-grid-column padded="vertically" key="otherInfo">
                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">CrossHair</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row style="padding-bottom: 24px">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Enabled
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                                type="checkbox"
                                name="enabled"
                                v-model="crossHairEnabled"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Line Color
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="crossHairLineColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Box Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="crossHairBoxColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Text Colour
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="crossHairTextColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>

                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">Marker</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row style="padding-bottom: 30px">
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Use Up/Down Colour on Bar Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                                type="checkbox"
                                name="marker-enabled"
                                v-model="markerEnabled"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Line Color
                        </sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                              v-model="markerLineColor"
                              :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>

                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">Misc</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                        >Use Up/Down Colour on Bar Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                                type="checkbox"
                                name="barChange"
                                v-model="markerHidePopup"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </div>

        <div v-if="isIndicatorTabActive">
          <sui-grid divided="vertically" style="margin-top: 4px">
            <sui-grid-row :columns="2" :style="themePrimaryColor">
              <sui-grid-column padded="vertically">
                <p></p>
                <!-- <h4 is="sui-header">Simple Moving Average</h4> -->
                <sui-table basic="very" compact="very" :key="multiItems">
                  <sui-table-body>
                    <sui-table-row>
                      <sui-table-cell :width="14">
                        <sui-form>
                          <sui-form-field>
                            <label :style="themeFontColor"
                            >Simple Moving Average</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                  v-for="(multi, i) of userMultiSettings.sma"
                                  :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                      placeholder="20"
                                      v-model.number="multi.period"
                                      type="number"
                                      v-on:input="updateProp('sma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                      v-model="multi.color"
                                      :isSettingsPicker="true"
                                      type="color"
                                      v-on:input="updateProp('sma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                      size="mini"
                                      icon="times"
                                      :disabled="
                                      userMultiSettings.sma.length === 1
                                    "
                                      @click.prevent="remElem('sma', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                      size="mini"
                                      icon="add"
                                      :style="themeSecondaryColor"
                                      content="Add"
                                      @click.prevent="addElem('sma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell/>
                                <sui-table-cell/>
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                            >Simple Moving Average of OBV</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                  v-for="(multi, i) of userMultiSettings.smaobv"
                                  :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                      placeholder="20"
                                      v-model="multi.period"
                                      type="number"
                                      v-on:input="updateProp('smaobv')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                      v-model="multi.color"
                                      :isSettingsPicker="true"
                                      type="color"
                                      v-on:input="updateProp('smaobv')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                      size="mini"
                                      icon="times"
                                      :disabled="
                                      userMultiSettings.smaobv.length === 1
                                    "
                                      @click.prevent="remElem('smaobv', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                      size="mini"
                                      icon="add"
                                      :style="themeSecondaryColor"
                                      content="Add"
                                      @click.prevent="addElem('smaobv')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell/>
                                <sui-table-cell/>
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                            >Simple Moving Average of OBV of Volume</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                  v-for="(
                                  multi, i
                                ) of userMultiSettings.smavolume"
                                  :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                      placeholder="20"
                                      v-model="multi.period"
                                      type="number"
                                      v-on:input="updateProp('smavolume')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                      v-model="multi.color"
                                      :isSettingsPicker="true"
                                      type="color"
                                      v-on:input="updateProp('smavolume')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                      size="mini"
                                      icon="times"
                                      :disabled="
                                      userMultiSettings.smavolume.length === 1
                                    "
                                      @click.prevent="remElem('smavolume', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                      size="mini"
                                      icon="add"
                                      :style="themeSecondaryColor"
                                      content="Add"
                                      @click.prevent="addElem('smavolume')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell/>
                                <sui-table-cell/>
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                            >Weighted Moving Average</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                  v-for="(multi, i) of userMultiSettings.wma"
                                  :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                      placeholder="20"
                                      v-model="multi.period"
                                      type="number"
                                      v-on:input="updateProp('wma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                      v-model="multi.color"
                                      :isSettingsPicker="true"
                                      type="color"
                                      v-on:input="updateProp('wma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                      size="mini"
                                      icon="times"
                                      :disabled="
                                      userMultiSettings.wma.length === 1
                                    "
                                      @click.prevent="remElem('wma', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                      size="mini"
                                      icon="add"
                                      :style="themeSecondaryColor"
                                      content="Add"
                                      @click.prevent="addElem('wma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell/>
                                <sui-table-cell/>
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                            >Exponential Moving Average</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                  v-for="(multi, i) of userMultiSettings.xma"
                                  :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                      placeholder="20"
                                      v-model="multi.period"
                                      type="number"
                                      v-on:input="updateProp('xma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                      v-model="multi.color"
                                      :isSettingsPicker="true"
                                      type="color"
                                      v-on:input="updateProp('xma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                      size="mini"
                                      icon="times"
                                      :disabled="
                                      userMultiSettings.xma.length === 1
                                    "
                                      @click.prevent="remElem('xma', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                      size="mini"
                                      icon="add"
                                      :style="themeSecondaryColor"
                                      content="Add"
                                      @click.prevent="addElem('xma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell/>
                                <sui-table-cell/>
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>
                        </sui-form>
                      </sui-table-cell>
                    </sui-table-row>
                  </sui-table-body>
                </sui-table>
              </sui-grid-column>

              <sui-grid-column padded="vertically">
                <p></p>
                <sui-form>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Trend Channels</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="trendChannelValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="trendChannelPeriod"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Bollinger Bands</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="bollingerBandPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="bollingerBandPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Deviations
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="bollingerBandDeviationValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="bollingerBandDeviationColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Average True Range</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="atrPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="atrPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Multiplier
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                              placeholder="20"
                              type="number"
                              v-model="atrMultiplierValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">MACD</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Fast Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="macdFastPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="macdFastPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Slow Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="macdSlowPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="macdSlowPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Signal Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="macdSignalPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="macdSignalPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Rate Of Change</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="rocPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="rocPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">
                            Relative Strength Index
                          </h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="rsiPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="rsiPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Stochastic Oscillator</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="stocPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="stocPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >%K Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="stocKPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="stocKPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >%D Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="stocDPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="stocDPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Williams %R</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Period
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                  placeholder="20"
                                  type="number"
                                  v-model="willRPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                  type="color"
                                  :isSettingsPicker="true"
                                  v-model="willRPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Parabolic SAR</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Start
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                              placeholder="20"
                              type="number"
                              v-model="sarStartValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Increment
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                              placeholder="20"
                              type="number"
                              v-model="sarIncrementValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Max
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                              placeholder="20"
                              type="number"
                              v-model="sarMaxValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Colour
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <color-picker
                              type="color"
                              :isSettingsPicker="true"
                              v-model="sarSeriesValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Value</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Colour
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <color-picker
                              type="color"
                              :isSettingsPicker="true"
                              v-model="valueTradedSeriesValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">VWAP</h4>
                        </sui-table-cell>
                        <sui-table-cell/>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                        >Colour
                        </sui-table-cell
                        >
                        <sui-table-cell>
                          <color-picker
                              type="color"
                              :isSettingsPicker="true"
                              v-model="vwapSeriesValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </sui-form>
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </div>

        <div v-if="isNewIndicator">
          <sui-grid divided="vertically" style="margin-top: 4px">
            <sui-grid-row :columns="2" :style="themePrimaryColor">
              <sui-grid-column padded="vertically">
                <p></p>
                <sui-form>

                  <CMO_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <ALMA_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <BBW_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <ATRP_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <COG_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <HMA_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <KCW_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <MOM_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <VWMA_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <Ribbon_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <KC_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <TSI_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <MFI_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <CCI_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                  <DMI_SettingsWindow @runGeneralSetting="runGeneralSetting"/>
                  <SWMA_SettingsWindow @runGeneralSetting="runGeneralSetting"/>

                </sui-form>
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </div>

        <div v-if="isThemeTabActive">
          <sui-table called :style="themePrimaryColor">
            <sui-table-body>
              <sui-table-row>
                <sui-table-cell>
                  <h4>
                    <sui-checkbox
                        radio
                        name="themes"
                        :input-value="isThemePrimary"
                        v-on:change="disableCustomFonts"
                    />
                    <label
                        style="position: relative; bottom: 3px"
                        :style="themeFontColor"
                    >Themes</label
                    >
                  </h4>
                  <sui-dropdown
                      placeholder="Select a theme"
                      selection
                      :disabled="!isThemePrimary"
                      :options="default_themes"
                      v-model="primaryThemeId"
                  />
                </sui-table-cell>
                <sui-table-cell>
                  <sui-button
                      :icon="themeSettings.id === 'dark' ? 'sun' : 'moon outline'"
                      secondary
                      @click="checkDarkMode()"
                      :data-tooltip="
                      themeSettings.id === 'dark' ? 'Classic mode' : 'Dark mode'
                    "
                      data-position="bottom center"
                      data-inverted=""
                  />
                </sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  <h4>
                    <sui-checkbox
                        :style="themeFontColor"
                        radio
                        name="themes"
                        :input-value="!isThemePrimary"
                        v-on:change="disableTheme"
                    />
                    <label
                        style="position: relative; bottom: 3px"
                        :style="themeFontColor"
                    >Custom</label
                    >
                  </h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row :disabled="isThemePrimary">
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Font</h4>
                        </sui-table-cell>
                        <sui-table-cell>
                          <color-picker
                              v-model="themeSettingsSettingsFont"
                              is-settings-picker
                              fieldRef="font"
                              type="settings"
                              v-on:input="changeCustomSettings"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row :disabled="isThemePrimary">
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Primary</h4>
                        </sui-table-cell>
                        <sui-table-cell>
                          <color-picker
                              is-settings-picker
                              v-model="themeSettingsSettingsPrimary"
                              fieldRef="primary"
                              type="settings"
                              v-on:input="changeCustomSettings"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row
                          :disabled="isThemePrimary"
                          :style="themeFontColor"
                      >
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Secondary</h4>
                        </sui-table-cell>
                        <sui-table-cell>
                          <color-picker
                              is-settings-picker
                              v-model="themeSettingsSettingsSecondary"
                              fieldRef="secondary"
                              type="settings"
                              v-on:input="changeCustomSettings"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </sui-table-cell>
              </sui-table-row>
            </sui-table-body>
          </sui-table>
        </div>
      </div>
    </div>

    <template v-slot:action>
      <sui-modal-actions
          style="display: flex; justify-content: space-between"
          :style="themePrimaryColor"
      >
        <div>
          <sui-button icon="undo" secondary @click.native="onReset"/>
        </div>
        <div style="justify-content: right">
          <sui-button
              :style="themeSecondaryColor"
              negative
              @click.native="onSave"
          >OK
          </sui-button
          >
          <sui-button
              style="
              background-color: #414343;
              filter: none;
              border: 1px solid #2e2f2f;
              opacity: 1;
              font-weight: bold;
              color: white;
            "
              @click.native="onClose"
          >Cancel
          </sui-button
          >
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import {mapActions, mapGetters} from 'vuex'
import ColorPicker from '@/baseComponents/ColorPicker.vue'
import GeneralSettingsComputed from './helper/GeneralSettingsComputed'
import IndicatorSettingsComputed from './helper/IndicatorSettingsComputed'
import {DEFAULT_THEME_SETTINGS, THEME_DETAILS,} from '../../settings/THEME_DETAILS'
import CMO_SettingsWindow from "Components/dialogBoxes/NewDialogModels/CMO_SettingsWindow.vue";
import ALMA_SettingsWindow from "Components/dialogBoxes/ALMA_SettingsWindow.vue";
import BBW_SettingsWindow from "Components/dialogBoxes/BBW_SettingsWindow.vue";
import ATRP_SettingsWindow from "Components/dialogBoxes/ATRP_SettingsWindow.vue";
import COG_SettingsWindow from "Components/dialogBoxes/COG_SettingsWindow.vue";
import HMA_SettingsWindow from "Components/dialogBoxes/HMA_SettingsWindow.vue";
import KCW_SettingsWindow from "Components/dialogBoxes/KCW_SettingsWindow.vue";
import MOM_SettingsWindow from "Components/dialogBoxes/MOM_SettingsWindow.vue";
import VWMA_SettingsWindow from "Components/dialogBoxes/VWMA_SettingsWindow.vue";
import Ribbon_SettingsWindow from "Components/dialogBoxes/Ribbon_SettingsWindow.vue";
import KC_SettingsWindow from "Components/dialogBoxes/KC_SettingsWindow.vue";
import TSI_SettingsWindow from "Components/dialogBoxes/TSI_SettingsWindow.vue";
import MFI_SettingsWindow from "Components/dialogBoxes/MFI_SettingsWindow.vue";
import CCI_SettingsWindow from "Components/dialogBoxes/CCI_SettingsWindow.vue";
import DMI_SettingsWindow from "Components/dialogBoxes/DMI_SettingsWindow.vue";
import SWMA_SettingsWindow from "Components/dialogBoxes/SWMA_SettingsWindow.vue";

export default {
  name: 'Settings',
  components: {
    DMI_SettingsWindow,
    SWMA_SettingsWindow,
    CCI_SettingsWindow,
    MFI_SettingsWindow,
    TSI_SettingsWindow,
    KC_SettingsWindow,
    Ribbon_SettingsWindow,
    VWMA_SettingsWindow,
    MOM_SettingsWindow,
    KCW_SettingsWindow,
    HMA_SettingsWindow,
    COG_SettingsWindow,
    ATRP_SettingsWindow,
    BBW_SettingsWindow,
    ALMA_SettingsWindow,
    CMO_SettingsWindow,
    DialogBox,
    ColorPicker,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    runGeneralSetting(key, value) {
      if (key && key in this) {
        // let indicatorSettingsComputedElement = this[key];
        this[key]= value
      }
    },
    checkDarkMode() {
      this.toggleDarkMode(this.themeSettings.id).then((d) => {
      })
    },
    updateProp(id) {
      if (!this.propUpdates.includes(id)) {
        this.changesNotSaved = true
        this.propUpdates.push(id)
      }
    },
    ...mapActions([
      'setIndicatorDefaultSettings',
      'saveUserSettings',
      'toggleDarkMode',
    ]),
    addElem(type) {
      if (
          this.userMultiSettings[type] &&
          this.userMultiSettings[type].length > 0
      ) {
        let n = {}
        n[type] = this.userMultiSettings[type]
        let newObj = JSON.stringify(this.userDataMap[type])
        let newObjParse = JSON.parse(newObj)
        n[type].push(newObjParse)
        this.$store.commit('SET_INDICATOR_MULTI_SETTINGS', n[type])
        this.changesNotSaved = true
        this.updateProp(type)
      }
    },
    remElem(type, id) {
      let n = {}
      n[type] = this.userMultiSettings[type]
      n[type].splice(id, 1)
      this.$store.commit('SET_INDICATOR_MULTI_SETTINGS', n[type])
      this.changesNotSaved = true
      this.updateProp(type)
    },
    GeneralTab() {
      this.isGeneralTabActive = true;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = false;
      this.isNewIndicator = false;
    },
    IndicatorTab() {
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = true;
      this.isThemeTabActive = false;
      this.isNewIndicator = false;
    },
    ThemeTab() {
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = true;
      this.isNewIndicator = false;
    },
    PlusTab() {
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = false;
      this.isNewIndicator = true;
    },
    async onSave() {
      if (this.changesNotSaved) {
        let res = await this.saveUserSettings()
        if (res) {
          this.$store.commit('USER_SET_UPDATE_CHARTS', this.propUpdates)
          this.propUpdates = []
        }
        this.changesNotSaved = false
      }
      this.$emit('change', !open)
    },
    async onReset() {
      this.$store.commit('RESET_CHART_SETTINGS_DEFAULTS')
      let res = await this.saveUserSettings()
      if (res) {
        this.$store.commit('USER_SET_UPDATE_CHARTS', this.propUpdates)
        this.propUpdates = []
      }
      this.changesNotSaved = false
      this.propUpdates = []
      this.$emit('change', !open)
    },
    onClose() {
      this.changesNotSaved = false
      // this.$store.commit('USER_SET_UPDATE_CHARTS')
      // this.$store.commit('RESET_CHART_SETTINGS', {chartSettings: this.defaultChartSettings})
      // this.$store.commit('RESET_DEFAULT_THEME', defaultValues)
      this.propUpdates = []
      this.$emit('change', !open)
    },
    disableCustomFonts: function (d) {
      if (d) {
        this.$store.commit('UPDATE_DEFAULT_THEME', 'classic')
      }
      this.isCustomTheme = !d
      this.propChange()
    },
    disableTheme: function (d) {
      if (d) {
        this.$store.commit(
            'UPDATE_CUSTOM_THEME',
            JSON.parse(JSON.stringify(DEFAULT_THEME_SETTINGS.settings))
        )
      }
      this.propChange()
    },
    propChange() {
      this.changesNotSaved = true
    },
    changeCustomSettings: function (data, prop, type) {
      let payload = this.themeSettings.settings
      payload[prop] = data
      this.propChange()
      this.$store.commit('UPDATE_CUSTOM_THEME', payload)
    },
  },

  data() {
    return {
      inputBackColor: 'white',
      userDataMap: {
        sma: {period: 30, color: '#165ba8'},
        smavolume: {period: 30, color: '#165ba8'},
        obvvolume: {period: 1, color: '#165ba8'},
        wma: {period: 30, color: '#165ba8'},
        xma: {period: 30, color: '#165ba8'},
        smaobv: {period: 30, color: '#165ba8'},
      },
      propUpdates: [],
      multiItems: 0,
      changesNotSaved: false,
      theme_type: 1,
      selected_theme: 1,
      default_themes: [
        {
          text: 'Classic',
          value: 'classic',
        },
        {
          text: 'Dark',
          value: 'dark',
        },
        {
          text: 'Grey',
          value: 'grey',
        },
      ],
      custom_theme: {
        themeFont: '',
        themePrim: '',
        themeSecond: '',
      },
      showVolumeBars: true,
      settings: {
        sma: 14,
        tci: 21,
      },
      isGeneralTabActive: true,
      isIndicatorTabActive: false,
      isNewIndicator: false,
      isThemeTabActive: false,
    }
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        // this.inputFontColor = "#A2A2A2"
        // this.inputBorderColor = '1px solid blue'
      } else {
        this.inputBackColor = 'white'
        // this.inputFontColor = this.themeFontColor.color
        // this.inputBorderColor = '1px solid blue'
      }
    },
    showVolumeBars: function (newValue) {
      this.$store.state.dc.set('chart.settings', {
        showVolume: newValue,
      })
    },
    settings: {
      handler: function (newSettings) {
        this.setIndicatorDefaultSettings(newSettings)
      },
      deep: true,
    },
    themeSettings(n, o) {
    },
  },
  computed: {
    ...mapGetters([
      'onlyMatHasAccess',
      'getUserSettingData',
      'defaultIndicatorSettings',
      'defaultChartSettings',
      'chartSettings',
      'protraderGeneralSettings',
      'themeSettings',
      'userThemeSettings',
      'defaultThemeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'isThemePrimary',
      'userMultiSettings',
      'getUserEmail',
      'themeDarkMode',
    ]),
    /** @returns {String} */
    ...GeneralSettingsComputed,
    ...IndicatorSettingsComputed,
    primaryThemeId: {
      get() {
        return this.themeSettings.id
      },
      set(value) {
        this.propChange()
        this.$store.commit('UPDATE_DEFAULT_THEME', value)
      },
    },
    themeSettingsSettingsFont: {
      get() {
        let themeSettings = this.themeSettings
        let settings = themeSettings.settings
        let themeDetailDefault = THEME_DETAILS[themeSettings.id]
        return settings && settings.font
            ? settings.font
            : themeDetailDefault && themeDetailDefault.font
                ? themeDetailDefault.font
                : ''
      },
    },
    themeSettingsSettingsPrimary: {
      get() {
        let themeSettings = this.themeSettings
        let settings = themeSettings.settings
        let themeDetailDefault = THEME_DETAILS[themeSettings.id]
        return settings && settings.primary
            ? settings.primary
            : themeDetailDefault && themeDetailDefault.primary
                ? themeDetailDefault.primary
                : ''
      },
    },
    themeSettingsSettingsSecondary: {
      get() {
        let themeSettings = this.themeSettings
        let settings = themeSettings.settings
        let themeDetailDefault = THEME_DETAILS[themeSettings.id]
        return settings && settings.secondary
            ? settings.secondary
            : themeDetailDefault && themeDetailDefault.secondary
                ? themeDetailDefault.secondary
                : ''
      },
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;

} */

div[role='listbox'] {
  background-color: v-bind(inputBackColor) !important;
}

/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
.ui.form select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
input[type="number"]{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
} */
</style>
