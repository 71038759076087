<template>
  <div id="health-container">
    <div id="progress-bar-container">
      <div class="progress-bar-child progress"></div>
      <div class="progress-bar-child mask" :style="healthBarEmptyArea"></div>
    </div>
    <sui-button @click="openDialog" icon="info" secondary size="mini" attached="right" />
    <sui-modal v-model="dialogvisible">
      <sui-modal-header>Pro Trader Health Report</sui-modal-header>
      <sui-modal-content>
        <div>
          
          <!-- <img src='/images/healthBar.png' height="504" width="500"> -->
          <div style="width: 600px; padding: 10px;">
            <p>Runs several test(detailed below) over the stock to determine its overall helth. As this stock does not
              pay a divident, the tests take a shorter time frame to look for more rapid price movements.</p>
            <hr />

            <div style="display: flex; justify-content: space-between; margin-top:7px; margin-bottom:7px;">
              <div>
                <h4>Test 1: Exponential moving averages</h4>
                <p>Examines whether the stock has closed above the 34 and 55 day exponential moving averages. If the
                  stock has closed above both, it receives full points, oterwise it receives no points.</p>
              </div>
              <div>
                <h3>{{this.allTest?.test1}}/100</h3>
              </div>
            </div>
            <hr />

            <div style="display: flex; justify-content: space-between; margin-top:7px; margin-bottom:7px;">
              <div>
                <h4>Test 2: On-balance volume</h4>
                <p>Examines the average on-balance volume of the stock over the last 13 days versus the 21 days prior.
                  If the latest average is higher than the prior average, the stock receives full points, otherwise it
                  receives no points.</p>
              </div>
              <div>
                <h3>{{this.allTest?.test2}}/100</h3>
              </div>
            </div>
            <hr />

            <div style="display: flex; justify-content: space-between; margin-top:7px; margin-bottom:7px;">
              <div>
                <h4>Test 3: Price</h4>
                <p>Examines the current price trend of the stock using the Trend channel Indicator over 21 days. If the
                  trend is up, the stock receives full points, if it is sideways, it receives half points, and if it is
                  down it receives no points.</p>
              </div>
              <div>
                <h3>{{this.allTest?.test3}}/100</h3>
              </div>
            </div>
            <hr />

            <div style="display: flex; justify-content: space-between; margin-top:7px; margin-bottom:7px;">
              <div>
                <h4>Test 4: Volume</h4>
                <p>Checks whether the stock has traded more than 2,500,000 shares over the past 5 days. If it has, it
                  will receive a perfect score for this test. Anything lower than 2,500,000 will be reflacted
                  proportionately in the score.</p>
              </div>
              <h3>{{this.allTest?.test4?.toFixed(2)}}/20</h3>
            </div>

            <hr />

            <div style="display: flex; justify-content: space-between; margin-top:7px; margin-bottom:7px;">
              <div>
                <h4>Test 5: Value</h4>
                <p>Checks whether the stock has traded more than $ 1,500,000 worth of shares over the past 5 days. If it
                  has, It will receive a perfect score for this test. Anything lower than $ 1,500,000 will be reflacted
                  proportionately in the score.</p>
              </div>
              <div>
                <h3>{{this.allTest?.test5?.toFixed(2)}}/20</h3>
              </div>
            </div>

          </div>
        </div>
      </sui-modal-content>
    </sui-modal>


  </div>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapGetters } from "vuex";

export default {

  name: 'HealthBar',
  components: {
    DialogBox
  },
  data() {
    return {
      dialogvisible: false
    }
  },
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    allTest: {
      type: Object,
      default: {
        test1: 0,
        test2: 0,
        test3: 0,
        test4: 0,
        test5: 0
      }
    }

  },
  computed: {
    ...mapGetters(['themeDarkMode']),
    healthBarEmptyArea() {
      return this.themeDarkMode ? { left: `${(this.percentage * 100) / 340}%`, background: '#505050', borderColor: '#505050', color: '#A2A2A2' } : { left: `${(this.percentage * 100) / 340}%` }
    },
  },
  methods: {
    closeDialog() {
      this.dialogvisible = false;
    },
    openDialog() {
      this.dialogvisible = true;
    }
  },
}
</script>

<style scoped>
#health-container {
  margin-top: 1rem;
  display: flex;
  height: 35px;
}

#progress-bar-container {
  width: 90%;
  overflow: hidden;
  border: 1px solid #2e2f2f;
  position: relative;
}

.progress-bar-child {
  width: 100%;
  height: 100%;
}

.progress {
  color: white;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  font-family: 'Segoe UI';
  animation-direction: reverse;
  background: rgb(207, 12, 12);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,
      rgba(217, 35, 15, 1) 0,
      rgba(70, 148, 8, 1) 85%);
}

.mask {
  position: absolute;
  background-color: #fff;
  width: 100%;
  top: 0;
}

/* .timelapse {
  animation-name: timelapse;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.86, 0.05, 0.4, 0.96);
} */

/* @keyframes timelapse {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
} */
</style>