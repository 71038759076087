<template>
  <div>
    <sui-form>
      <sui-form-field
        :error="formData.accountBalance !== null && !formData.accountBalance"
      >
        <label>Account Balance</label>
        <input
          placeholder="Account Balance"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="formData.accountBalance"
        />
      </sui-form-field>
      <sui-form-field
        :error="formData.stockCode !== null && !formData.stockCode"
      >
        <label>Stock Code</label>
        <div style="border-top: none; width: 100%">
          <symbol-search-box
            :flexStart="true"
            :value="formData.stockCode"
            @input="onSymbolChanged"
            :manualNavigation="false"
          />
        </div>
      </sui-form-field>
      <sui-form-field
        :error="formData.currentPrice !== null && !formData.currentPrice"
      >
        <label>Current Price</label>
        <input
          placeholder="Current Price"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="formData.currentPrice"
        />
      </sui-form-field>
      <sui-form-field
        :error="formData.purchasePrice !== null && !formData.purchasePrice"
      >
        <label>Purchase Price</label>
        <input
          placeholder="Purchase Price"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="formData.purchasePrice"
        />
      </sui-form-field>
    </sui-form>
  </div>
</template>

<script>
import SymbolSearchBox from '../../../components/SymbolSearchBox.vue'
import symbolService from '../../../services/symbol'
export default {
  name: 'AccountBalance',
  model: {
    prop: 'isValidated',
    event: 'change',
  },
  components: {
    SymbolSearchBox,
  },
  props: {
    isValidated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onSymbolChanged(symbol) {
      this.formData.stockCode = symbol
      // fetch the close price
      this.formData.currentPrice = await symbolService.getSymbolClosePrice(
        symbol
      )
      this.formData.purchasePrice = this.formData.currentPrice
    },
  },
  computed: {
    /** @returns {Object} */
    formData: {
      get() {
        return this.$store.state.tadePlanner.accountBalanceData
      },
      set(value) {
        this.$store.commit('UPDATE_TRADE_PLANNER_STATE', {
          key: 'accountBalanceData',
          data: value,
        })
      },
    },
  },
  watch: {
    formData: {
      handler: async function (newValue) {
        for (const fieldValues of Object.values(newValue)) {
          if (!fieldValues) return this.$emit('change', false)
        }
        this.$emit('change', true)
      },
      deep: true,
    },
  },
}
</script>

<style></style>
