<template>
  <dialog-box
    size="mini"
    modalHeader="Add Watch"
    :open="open"
    :action="false"
    style="z-index: 110"
  >
    <!--      <h3 class="">-->
    <sui-form :loading="showLoader" size="small">
      <div class="ui center aligned header positionStyle fontStyle">
        <br />
        <h4
          :style="themeDarkMode ? { color: '#A2A2A2' } : {}"
          class="ui header fontStyle"
          style="font-size: 14px"
        >
          Please enter a name for your new watchlist:
        </h4>
        <br />

        <input
          type="text"
          v-model="newWatchName"
          @keypress.enter="createWatches"
          :style="
            themeDarkMode
              ? {
                  background: '#505050 !important',
                  borderColor: '#151515',
                  color: '#A2A2A2',
                }
              : {}
          "
        />
        <template v-if="duplicationError">
          <br />
          <h4
            class="ui red header"
            style="font-size: 14px"
            :style="themeDarkMode ? { color: '#A2A2A2' } : {}"
          >
            A watchlist with that name already exists
          </h4>
          <br />
        </template>
      </div>
    </sui-form>
    <template v-slot:action>
      <sui-modal-actions
        :style="
          themeDarkMode
            ? {
                background: '#151515 !important',
                borderColor: '#151515',
                color: '#A2A2A2',
              }
            : {}
        "
      >
        <div>
          <sui-button
            negative
            :disabled="!(newWatchName && newWatchName.trim())"
            @click.native="createWatches"
            :style="themeSecondaryColor"
            >Create
          </sui-button>
          <sui-button
            :disabled="showLoader"
            @click.native="toggleWatchesModalInner"
            :style="
              themeDarkMode
                ? {
                    background: '#505050 !important',
                    borderColor: '#151515',
                    color: '#A2A2A2',
                  }
                : {}
            "
            >Cancel
          </sui-button>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteFavouriteList from './DeleteFavouriteList.vue'
import DialogBox from '../../../baseComponents/DialogBox'
export default {
  name: 'WatchlistScanPopup',
  components: { DeleteFavouriteList, DialogBox },
  model: {
    prop: 'open',
    event: 'change',
  },
  computed: {
    ...mapGetters([
      'watchlistPopupGetter',
      'scans',
      'userSavedScans',
      'themeSecondaryColor',
      'themePrimaryColor',
      'themeFontColor',
      'themeDarkMode',
    ]),
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    showWatchesModal: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    duplicationError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FavouriteName: '',
      newWatchName: '',
      FavouritePopupParent: false,
    }
  },
  methods: {
    createWatches(event) {
      event.preventDefault();
      if(!(this.newWatchName && this.newWatchName.trim()))return;
      if (this.newWatchName.length) {
        let newWatchName = this.newWatchName
        this.newWatchName = ''
        this.$emit('createWatches', newWatchName)
      }
    },
    toggleWatchesModalInner() {
      this.newWatchName = ''
      this.$emit('toggleWatchesModalInner')
    },
  },
}
</script>

<style scoped>
.Favourite-Popup {
  height: 200px;
  width: 350px;
  box-shadow: 0px 0.2px 20px 2px #afa7a7;
  z-index: 106;
  background-color: #ffffff;
  position: relative;
  top: 10px;
}

.popup-firstChild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;

  padding-bottom: 10px;
  box-sizing: border-box;
}

.popup-secondChild {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 40%;
  width: 100%;
  padding: 8px 13px;
  gap: 4px;
}

input {
  width: 80%;
  height: 30%;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0.2px 1.2px 1.5px #dddddd;
}
.FavouritePopupParent {
  display: flex;
  justify-content: center;
  background: rgba(10, 10, 04, 0.5);
  width: 100%;
  height: 100%;
  z-index: 102;
  position: absolute;
}
</style>
