<template>
  <pattern-base-modal
    class="font-Size"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    @deletedScan="deletedScan"
    @changeScanSettings="changeScanSettings"
    @ResetScanDefaultSettings="ResetScanDefaultSettings"
    header="Fundamentals"
    scanName="Fundamentals"
    :valid="isValid"
    :scanType="id"
    :id="id"
    customStyle=""
    :isLoading="isLoading"
  >
    <sui-header dividing :style="themeFontColor">Measures</sui-header>

    <!--    <div class="input-container"></div>-->
    <div class="input-container fontSize">
      <input
        type="checkbox"
        id="Earning_yield"
        v-model="settings.earningYield.isActive"
        @input="handleUserChoice"
      />
      <label for="Earning_yield">Earning yield</label>

      <input
        type="radio"
        id="EY_morethan"
        name="earning-yield-type"
        value="more"
        :style="{ cursor: 'pointer' }"
        v-model="settings.earningYield.type"
        @input="handleUserChoice"
      />
      <label for="EY_morethan" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >more than</label
      >
      <input
        type="radio"
        id="EY_lessthan"
        name="earning-yield-type"
        value="less"
        :style="{ cursor: 'pointer' }"
        v-model="settings.earningYield.type"
        @input="handleUserChoice"
      />
      <label for="EY_lessthan" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >less than</label
      >
      <sui-form-field inline class="textField">
        <input
          type="number"
          v-model="settings.earningYield.value"
          @input="handleUserChoice"
        />
        %
      </sui-form-field>
    </div>

    <div class="input-container fontSize">
      <input
        id="P_E_ratio"
        type="checkbox"
        v-model="settings.peRatio.isActive"
        @input="handleUserChoice"
      />
      <label for="P_E_ratio">P/E ratio</label>
      <input
        id="P_E_morethan"
        type="radio"
        name="pe-type"
        value="more"
        v-model="settings.peRatio.type"
        @input="handleUserChoice"
      />
      <label
        for="P_E_morethan"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >more than</label
      >
      <input
        id="P_E_lessthan"
        type="radio"
        name="pe-type"
        value="less"
        v-model="settings.peRatio.type"
        @input="handleUserChoice"
      />
      <label
        for="P_E_lessthan"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >less than</label
      >
      <sui-form-field inline class="textField">
        <input
          type="number"
          v-model="settings.peRatio.value"
          @input="handleUserChoice"
        />%
      </sui-form-field>
    </div>

    <div class="input-container fontSize">
      <input
        id="DY"
        type="checkbox"
        v-model="settings.dividendYield.isActive"
        @input="handleUserChoice"
      />
      <label for="DY">Dividend yield</label>
      <input
        id="DY_morethan"
        type="radio"
        name="dividend-yield-type"
        value="more"
        v-model="settings.dividendYield.type"
        @input="handleUserChoice"
      />
      <label for="DY_morethan" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >more than</label
      >
      <input
        id="DYlessthan"
        type="radio"
        name="dividend-yield-type"
        value="less"
        v-model="settings.dividendYield.type"
        @input="handleUserChoice"
      />
      <label for="DYlessthan" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >less than</label
      >
      <sui-form-field inline class="textField">
        <input
          type="number"
          v-model="settings.dividendYield.value"
          @input="handleUserChoice"
        />
        %
      </sui-form-field>
    </div>

    <div class="input-container fontSize">
      <input
        id="WFC"
        type="checkbox"
        v-model="settings.frankedPct.isActive"
        @input="handleUserChoice"
      />
      <label for="WFC">With franking credits:</label>

      <input
        id="WFC_only100"
        type="radio"
        name="franking-type"
        value="100"
        v-model="settings.frankedPct.type"
        @input="handleUserChoice"
      />
      <label for="WFC_only100" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >100% only</label
      >
      <input
        id="WFC_anycredit"
        type="radio"
        name="franking-type"
        value="any"
        v-model="settings.frankedPct.type"
        @input="handleUserChoice"
      />
      <label
        for="WFC_anycredit"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >any credit</label
      >
    </div>

    <sui-header dividing :style="themeFontColor" class="Capitalisation-font"
      >Capitalisation</sui-header
    >
    <sui-form-fields inline class="Capitalisation-font fontSize">
      <sui-form-field class="Capitalisation-font">
        <sui-checkbox
          v-model="settings.capitalisation.topSymbols.isActive"
          @click="handleUserChoice"
        />
      </sui-form-field>
      <label :style="themeFontColor" class="Capitalisation-font"
        >Must be in the top</label
      >

      <sui-form-field>
        <input
          type="number"
          v-model="settings.capitalisation.topSymbols.value"
          @input="handleUserChoice"
          @keypress="preventDecimal"
        />
      </sui-form-field>
      <sui-form-field> stocks by the market cap </sui-form-field>
    </sui-form-fields>

    <sui-form-fields inline class="Capitalisation-font fontSize">
      <sui-form-field>
        <sui-checkbox
          v-model="settings.capitalisation.sharesIssued.isActive"
          @click="handleUserChoice"
        />
      </sui-form-field>
      <label :style="themeFontColor">Shares issued</label>

      <sui-form-field>
        <input
          id="SI_morethan"
          type="radio"
          name="shares-issues-type"
          value="more"
          v-model="settings.capitalisation.sharesIssued.type"
          @input="handleUserChoice"
        />
        <label :style="themeFontColor" for="SI_morethan">more than</label>
      </sui-form-field>
      <sui-form-field>
        <input
          id="SI_lessthan"
          type="radio"
          name="shares-issues-type"
          value="less"
          v-model="settings.capitalisation.sharesIssued.type"
          @input="handleUserChoice"
        />
        <label :style="themeFontColor" for="SI_lessthan">less than</label>
      </sui-form-field>
      <sui-form-field>
        <input
          type="number"
          v-model="settings.capitalisation.sharesIssued.value"
          @input="handleUserChoice"
        />
      </sui-form-field>
      <sui-form-field> million </sui-form-field>
    </sui-form-fields>

    <sui-form-fields inline class="Capitalisation-font fontSize">
      <sui-form-field>
        <sui-checkbox
          v-model="settings.capitalisation.marketCap.isActive"
          @click="handleUserChoice"
        />
      </sui-form-field>
      <label :style="themeFontColor">Market Cap</label>
      <sui-form-field>
        <input
          id="MC_morethan"
          type="radio"
          name="market-cap-type"
          value="more"
          v-model="settings.capitalisation.marketCap.type"
          @input="handleUserChoice"
        />
        <label :style="themeFontColor" for="MC_morethan">more than</label>
      </sui-form-field>
      <sui-form-field>
        <input
          id="MC_lessthan"
          type="radio"
          name="market-cap-type"
          value="less"
          v-model="settings.capitalisation.marketCap.type"
          @input="handleUserChoice"
        />
        <label :style="themeFontColor" for="MC_lessthan">less than</label>
      </sui-form-field>
      <sui-form-field>
        <input
          type="number"
          v-model="settings.capitalisation.marketCap.value"
          @input="handleUserChoice"
        />
      </sui-form-field>
      <sui-form-field> million </sui-form-field>
    </sui-form-fields>

    <sui-header dividing :style="themeFontColor"> Returns</sui-header>
    <sui-form-fields inline class="Capitalisation-font fontSize">
      <sui-form-field>
        <sui-checkbox
          v-model="settings.returns.isActive"
          @click="handleUserChoice"
        />
      </sui-form-field>
      <label :style="themeFontColor" class="Capitalisation-font">Return</label>
      <sui-form-field>
        <input
          id="R_morethan"
          type="radio"
          name="returns-type"
          value="more"
          v-model="settings.returns.type"
          @input="handleUserChoice"
        />
        <label
          :style="themeFontColor"
          for="R_morethan"
          class="Capitalisation-font"
          >more than</label
        >
      </sui-form-field>
      <sui-form-field>
        <input
          id="R_lessthan"
          type="radio"
          name="returns-type"
          value="less"
          v-model="settings.returns.type"
          @input="handleUserChoice"
        />
        <label
          :style="themeFontColor"
          for="R_lessthan"
          class="Capitalisation-font"
          >less than</label
        >
      </sui-form-field>
      <sui-form-field>
        <input
          type="number"
          v-model="settings.returns.value"
          @input="handleUserChoice"
        />
      </sui-form-field>
      <sui-form-field> % for the last </sui-form-field>
      <sui-form-field>
        <sui-dropdown
          placeholder=""
          selection
          :style="themeDarkMode ? { color: '#A2A2A2' } : {}"
          :options="monthList"
          v-model="settings.returns.month"
          @input="handleUserChoice"
        />
      </sui-form-field>
    </sui-form-fields>
  </pattern-base-modal>
</template>

<script>
const DEFAULT_SETTINGS = {
  earningYield: {
    isActive: false,
    type: 'more',
    value: 5,
  },
  peRatio: {
    isActive: false,
    type: 'more',
    value: 20,
  },
  dividendYield: {
    isActive: false,
    type: 'more',
    value: 5,
  },
  frankedPct: {
    isActive: false,
    type: '100',
  },
  capitalisation: {
    topSymbols: {
      isActive: false,
      value: 20,
    },
    sharesIssued: {
      isActive: false,
      type: 'more',
      value: 5,
    },
    marketCap: {
      isActive: false,
      type: 'more',
      value: 5,
    },
  },
  returns: {
    isActive: false,
    type: 'more',
    value: 100,
    month: 'return12m',
  },
}
import CustomImageCheckBox from 'BaseComponents/CustomImageCheckBox.vue'
import PatternBaseModal from './PatternBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseCheckBox from 'BaseComponents/BaseCheckBox'
import ScanBottomForm from './ScanBottomForm.vue'
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_FILTER_SETTINGS,
  DEFAULT_EVENT_SETTINGS,
} from '../../../settings/USER_SETTINGS_DEFAULT'
export default {
  components: {
    PatternBaseModal,
    CustomImageCheckBox,
    BaseCheckBox,
    ScanBottomForm,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'Fundementals',
  methods: {
    ...mapActions([
      'updateMarketAccordingTODataSettings',
      'setFundementalsScanSettings',
      'sendFundementalScanRequest',
      'saveScannersScanRequest',
      'cancelScans',
      'deleteScanSettings',
      'getUserScanList',
      'setSelectedMarket',
    ]),
    async saveScanSettings(name) {
      await this.saveScannersScanRequest({
        scanName:name,
        scanType:'fundementals'
      })
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
    changeScanSettings(val) {
      this.updateMarketAccordingTODataSettings({ ...val, id: this.id })
      this.settings = val.settings
    },
    async deletedScan(selectedScan) {
      await this.deleteScanSettings(selectedScan)
      this.$store.commit('SCAN_DELETE_POPUP', false)
    },
    ResetScanDefaultSettings() {
      let val = {
        dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
      }
      this.updateMarketAccordingTODataSettings({ ...val, id: this.id })
      this.settings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    async onScanClick() {
      this.isLoading = true
      await this.sendFundementalScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.cancelScans()
      this.isLoading = false
    },
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
    },
  },
  data() {
    return {
      inputBackColor: 'white',
      id: 'fundementals',
      isLoading: false,
      selectedMonth: 'return12m',
      monthList: [
        {
          text: '1 month',
          value: 'return1m',
        },
        {
          text: '3 months',
          value: 'return3m',
        },
        {
          text: '6 months',
          value: 'return6m',
        },
        {
          text: '12 months',
          value: 'return12m',
        },
      ],
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
    }
  },
  computed: {
    ...mapGetters([
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'userChoice',
      'themeDarkMode',
    ]),
    /** @returns {Boolean} */
    isValid() {
      return (
        Object.values(this.settings).some((state) => state.isActive) ||
        Object.values(this.settings.capitalisation).some(
          (state) => state.isActive
        )
      )
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        // this.inputFontColor = "#A2A2A2"
      } else {
        this.inputBackColor = 'white'
        // this.inputFontColor = this.themeFontColor.color
      }
    },
    settings: {
      handler: function (newSettings) {
        this.setFundementalsScanSettings(newSettings)
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
div[role='listbox'] {
  background-color: v-bind(inputBackColor) !important;
}
/* .item{
  color:blue !important; 
} */

.input-container {
  font-size: 13.5px;
}
.Capitalisation-font {
  font-size: 13.5px;
}
.fontSize {
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width: 870px) {
  .fontSize {
    font-size: 11px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 510px) {
  .fontSize {
    font-size: 10px;
    margin-bottom: 7px;
  }
}
@media screen and (max-width: 400px) {
  .ui.form .inline.field > input {
    width: 40px;
  }
}
@media screen and (max-width: 460px) {
  .ui.form .inline.fields .field > input {
    width: 40px;
  }
}
</style>
