<template>
  <sui-modal v-model="open" :closable="false" width="2com">
    <sui-modal-header>
      <div
        class="ui container child-one"
        style="width: 80%; height: 150px; overflow-y: scroll"
      >
        <sui-step-group
          ordered
          step-number="six"
          size="mini"
          style="width: 100% !important"
        >
          <sui-step
            :completed="currentStep > 0"
            description="Account Balance"
          />
          <sui-step :completed="currentStep > 1" description="Position Size" />
          <sui-step :completed="currentStep > 2" description="Entry Signal" />
          <sui-step
            :completed="currentStep > 3"
            description="Confirmation Signals"
          />
          <sui-step
            :completed="currentStep > 4"
            description="Risk Management"
          />
          <sui-step :completed="false" description="Exit" />
        </sui-step-group>
      </div>
    </sui-modal-header>
    <sui-modal-content scrolling
      ><!-- main content -->
      <account-balance v-if="currentStep === 0" v-model="validated[0]" />
      <position-size v-if="currentStep === 1" v-model="validated[1]" />
      <entry-signal v-if="currentStep === 2" />
      <confirmation-signal v-if="currentStep === 3" />
      <risk-management v-if="currentStep === 4" />
      <exit-signal v-if="currentStep === 5" />
    </sui-modal-content>
    <sui-modal-actions
      v-if="action"
      style="display: flex; justify-content: space-between"
      :style="{ ...themePrimaryColor, ...themeFontColor }"
    >
      <div>
        <sui-button
          attached="left"
          :disabled="currentStep === minStep"
          @click.native="onClickBackButton"
          secondary
          >Back</sui-button
        >
        <sui-button
          attached="right"
          :style="themeSecondaryColor"
          @click.native="onClickNextButton"
          :disabled="currentStep === maxStep || !validated[currentStep]"
          >Next</sui-button
        >
      </div>
      <div>
        <sui-button
          v-if="currentStep === maxStep"
          color="green"
          @click.native="onClickSaveButton"
          >Save</sui-button
        >
        <sui-button secondary @click.native="onClickCancelButton"
          >Cancel</sui-button
        >
      </div>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import AccountBalance from './tradePlanner/AccountBalance.vue'
import ConfirmationSignal from './tradePlanner/ConfirmationSignal.vue'
import EntrySignal from './tradePlanner/EntrySignal.vue'
import ExitSignal from './tradePlanner/ExitSignal.vue'
import PositionSize from './tradePlanner/PositionSize.vue'
import RiskManagement from './tradePlanner/RiskManagement.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AccountBalance,
    PositionSize,
    EntrySignal,
    ConfirmationSignal,
    RiskManagement,
    ExitSignal,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: true,
    },
  },
  name: 'TradePlannerStepperDialog',
  data() {
    return {
      currentStep: 0,
      minStep: 0,
      maxStep: 5,
      validated: [false, false, true, true, true, true],
    }
  },
  methods: {
    ...mapActions(['saveTradePlan']),
    onClickNextButton() {
      this.currentStep++
    },
    onClickBackButton() {
      this.currentStep--
    },
    onClickSaveButton() {
      this.saveTradePlan().then(() => {
        this.currentStep = this.minStep
      })
      this.$emit('onClose')
    },
    onClickCancelButton() {
      this.$emit('onClose')
      this.currentStep = this.minStep
    },
  },
  watch: {
    open(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$emit('onClose')
      }
    },
  },
  computed: {
    ...mapGetters([
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
    ]),
  },
}
</script>

<style>
/* .ui.group .ordered{
 width:20px
} */
@media screen and (max-width: 320px) {
  /* .ui.modal .scrolling.content {
  box-sizing: border-box;
  max-height: calc(5vh) !important;
} */
  /* .child-one{
 height:200px; 
 overflow-y: scroll;
} */
}

/* @media screen and (min-width:1000px) {
  .tradeplanner-container{
  width: 20%
}
} */
/* .tradeplanner-container{
  width: 200px
} */
/* .ui.modal {
  width: 200px;
}
.ui.modal .scrolling.content {
  box-sizing: border-box;
  max-height: calc(60vh) !important;
} */
</style>
