<template>
  <pattern-base-modal class="fontSize" v-model="open" @onClose="$emit('change', !open)" @stop="onScanStop"
    @ok="onScanClick" @saveScanSettings="saveScanSettings" @deletedScan="deletedScan"
    @changeScanSettings="changeScanSettings" @ResetScanDefaultSettings="ResetScanDefaultSettings" header="Indicators"
    scanName="Indicators" :scanType="id" :id="id" :valid="isValid" customStyle="" :isLoading="isLoading">
    <multiple-checker class="fontSize" @selectAll="updateAllSelections(true)" @clearAll="updateAllSelections(false)" />
    <!-- Close above/below -->
    <div class="input-container fontSize">
      <base-check-box label="" v-model="settings.crossesAndAboveBelow.isActive" @click="handleUserChoice" />
      <select type="select" v-model="settings.crossesAndAboveBelow.left" :style="selectWidth"
        @change="handleUserChoice">
        <option value="close">Close</option>
        <option value="ma">MA</option>
        <option value="wma">WMA</option>
        <option value="xma">XMA</option>
      </select>

      <sui-input @input="handleUserChoice" :style="selectWidth" v-if="settings.crossesAndAboveBelow.left !== 'close'"
        type="number" tabindex="1" class="hidden" v-model="settings.crossesAndAboveBelow.leftPeriod" @keypress="preventDecimal" />

      <select type="select" v-model="settings.crossesAndAboveBelow.operatorType" :style="selectWidth"
        @change="handleUserChoice">
        <option value="above">is Above</option>
        <option value="below">is Below</option>
        <option value="crossAbove">Crossed Above</option>
        <option value="crossBelow">Crossed Below</option>
      </select>

      <select type="select" v-model="settings.crossesAndAboveBelow.right" :style="selectWidth"
        @change="handleUserChoice">
        <option value="close">Close</option>
        <option value="ma">MA</option>
        <option value="wma">WMA</option>
        <option value="xma">XMA</option>
      </select>

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" v-if="settings.crossesAndAboveBelow.right !== 'close'" type="number" tabindex="1" class="hidden"
        v-model="settings.crossesAndAboveBelow.rightPeriod" @input="handleUserChoice" @keypress="preventDecimal" />
    </div>

    <!-- ROC -->
    <div class="input-container fontSize">
      <base-check-box label="ROC " v-model="settings.ROC.isActive" @click="handleUserChoice" />

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" v-model="settings.ROC.period" @input="handleUserChoice" @keypress="preventDecimal" />
      <input type="radio" id="rocMode~overbought" name="rocMode" value="overbought" :style="{ cursor: 'pointer' }"
        v-model="settings.ROC.mode" @input="handleUserChoice" />
      <label for="rocMode~overbought" :style="{ ...themeFontColor, cursor: 'pointer' }">Overbought</label>
      <input type="radio" id="rocMode~oversold" name="rocMode" value="oversold" :style="{ cursor: 'pointer' }"
        v-model="settings.ROC.mode" @input="handleUserChoice" />
      <label for="rocMode~oversold" :style="{ ...themeFontColor, cursor: 'pointer' }">Oversold</label>
    </div>

    <!-- RSI -->
    <div class="input-container fontSize">
      <base-check-box label="RSI " v-model="settings.RSI.isActive" @click="handleUserChoice" />

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" v-model="settings.RSI.period" @input="handleUserChoice" @keypress="preventDecimal" />

      <input type="radio" id="rsiMode~overbought" name="rsiMode" value="overbought" :style="{ cursor: 'pointer' }"
        v-model="settings.RSI.mode" @input="handleUserChoice" />
      <label for="rsiMode~overbought" :style="{ ...themeFontColor, cursor: 'pointer' }">Overbought</label>
      <input type="radio" id="rsiMode~oversold" name="rsiMode" value="oversold" :style="{ cursor: 'pointer' }"
        v-model="settings.RSI.mode" @input="handleUserChoice" />
      <label for="rsiMode~oversold" :style="{ ...themeFontColor, cursor: 'pointer' }">Oversold</label>
    </div>

    <!-- RSR -->
    <div class="input-container fontSize">
      <base-check-box label="RS Above" v-model="settings.RSR.isActive" @click="handleUserChoice" />

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" v-model="settings.RSR.period" @input="handleUserChoice" @keypress="preventDecimal" />

    </div>

    <!-- STOC -->
    <div class="input-container fontSize">
      <base-check-box label="STOC " v-model="settings.STOC.isActive" @click="handleUserChoice" />

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" v-model="settings.STOC.period" @input="handleUserChoice" @keypress="preventDecimal" />
      <span>Period</span>

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" v-model="settings.STOC.kPercent" @input="handleUserChoice" @keypress="preventDecimal" />
      <span>%K</span>

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" v-model="settings.STOC.dPercent" @input="handleUserChoice" @keypress="preventDecimal" />
      <span>%D</span>

      <input type="radio" id="stocMode~overbought" name="stocMode" value="overbought" :style="{ cursor: 'pointer' }"
        v-model="settings.STOC.mode" @input="handleUserChoice" />
      <label for="stocMode~overbought" :style="{ ...themeFontColor, cursor: 'pointer' }">Overbought</label>
      <input type="radio" id="stocMode~oversold" name="stocMode" value="oversold" :style="{ cursor: 'pointer' }"
        v-model="settings.STOC.mode" @input="handleUserChoice" />
      <label for="stocMode~oversold" :style="{ ...themeFontColor, cursor: 'pointer' }">Oversold</label>
    </div>

    <!-- Bands -->
    <div class="input-container fontSize">
      <base-check-box label="Bollinger Band of period " v-model="settings.BANDS.isActive" @click="handleUserChoice" />

      <sui-input :style="[
    selectWidth,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" type="number" tabindex="1" class="hidden" @input="handleUserChoice" v-model="settings.BANDS.period" @keypress="preventDecimal" />

      <input type="radio" id="bbMode~top" name="bbMode" value="top" :style="{ cursor: 'pointer' }"
        @input="handleUserChoice" v-model="settings.BANDS.breakMode" />
      <label for="bbMode~top" :style="{ ...themeFontColor, cursor: 'pointer' }">Top Break</label>
      <input type="radio" id="bbMode~bottom" name="bbMode" value="bottom" :style="{ cursor: 'pointer' }"
        @input="handleUserChoice" v-model="settings.BANDS.breakMode" />
      <label for="bbMode~bottom" :style="{ ...themeFontColor, cursor: 'pointer' }">Bottom Break</label>
    </div>

    <!-- events -->
    <events-filter @isValid="onEventsSelect" :dialogId="dialogId" :id="id" />
  </pattern-base-modal>
</template>

<script>
const DEFAULT_SETTINGS = {
  crossesAndAboveBelow: {
    isActive: false,
    left: 'close',
    leftPeriod: 14,
    operatorType: 'above',
    right: 'ma',
    rightPeriod: 14,
  },
  ROC: {
    isActive: false,
    period: 10,
    mode: 'overbought',
  },
  RSI: {
    isActive: false,
    period: 18,
    mode: 'overbought',
  },
  RSR: {
    isActive: false,
    period: 80
  },
  STOC: {
    isActive: false,
    period: 15,
    kPercent: 3,
    dPercent: 3,
    mode: 'overbought',
  },
  BANDS: {
    isActive: false,
    period: 21,
    breakMode: 'top',
  },
}
import CustomImageCheckBox from 'BaseComponents/CustomImageCheckBox.vue'
import PatternBaseModal from './PatternBaseModal.vue'
import EventsFilter from './EventsFilter'
import MultipleChecker from '../../../baseComponents/MultipleChecker.vue'
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_FILTER_SETTINGS,
  DEFAULT_EVENT_SETTINGS,
} from '../../../settings/USER_SETTINGS_DEFAULT'
import { mapActions, mapGetters } from 'vuex'
import BaseCheckBox from '../../../baseComponents/BaseCheckBox.vue'
export default {
  components: {
    PatternBaseModal,
    CustomImageCheckBox,
    EventsFilter,
    MultipleChecker,
    BaseCheckBox,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'Indicators',
  methods: {
    ...mapActions([
      'updateMarketAccordingTODataSettings',
      'setIndicatorsScanSettings',
      'sendIndicatorsScanRequest',
      'cancelScans',
      'saveScannersScanRequest',
      'deleteScanSettings',
      'getUserScanList',
      'setSelectedMarket',
    ]),
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
    },
    changeScanSettings(val) {
      this.updateMarketAccordingTODataSettings({ ...val, id: this.id })
      this.settings = val.settings
    },

    async deletedScan(selectedScan) {
      await this.deleteScanSettings(selectedScan)
    },
    ResetScanDefaultSettings() {
      let val = {
        dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
      }
      this.updateMarketAccordingTODataSettings({ ...val, id: this.id })
      this.settings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    async saveScanSettings(name) {
      await this.saveScannersScanRequest({
        scanName:name,
        scanType:'indicators'
      })
      
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
    async onScanClick() {
      this.isLoading = true
      await this.sendIndicatorsScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.cancelScans()
      this.isLoading = false
    },
    updateAllSelections(state) {
      this.handleUserChoice()
      this.settings.crossesAndAboveBelow.isActive = state
      this.settings.ROC.isActive = state
      this.settings.RSI.isActive = state
      this.settings.RSR.isActive = state
      this.settings.STOC.isActive = state
      this.settings.BANDS.isActive = state
    },
    srcResolutionFunction(label) {
      const path = label
        .replace('-', '_')
        .split(' ')
        .reverse()
        .map((w) => w.toLowerCase())
        .reduce((curr, prev) => (prev && curr ? `${prev}_${curr}` : prev), '')
      return '/images/patterns/' + path + '.svg'
    },
    toCamelCase(name) {
      const pascal = name.replaceAll(' ', '')
      return pascal[0].toLowerCase() + pascal.substring(1)
    },
    updateCheckedPatterns(label, state) {
      // this.checkedPatterns = { ...this.checkedPatterns, [label]: state }
      this.settings[this.toCamelCase(label)] = state
    },
    onEventsSelect(value) {
      this.isEventsSelected = value
    },
  },
  data() {
    return {
      id: 'indicators',
      dialogId: 'indicatorEvent',
      isLoading: false,
      isEventsSelected: false,
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
    }
  },
  computed: {
    ...mapGetters(['themeFontColor', 'userChoice', 'themeDarkMode']),
    /** @returns {Boolean} */
    isValid() {
      if (this.isEventsSelected) return true
      if (
        this.settings.crossesAndAboveBelow.left !== 'close' &&
        this.settings.crossesAndAboveBelow.left ===
        this.settings.crossesAndAboveBelow.right &&
        this.settings.crossesAndAboveBelow.leftPeriod ===
        this.settings.crossesAndAboveBelow.rightPeriod
      )
        return false
      if (
        this.settings.crossesAndAboveBelow.left === 'close' &&
        this.settings.crossesAndAboveBelow.left ===
        this.settings.crossesAndAboveBelow.right
      )
        return false
      return Object.values(this.settings).some((state) => state.isActive)
    },
    /** @returns {String} */
    selectWidth() {
      return 'width: 12%'
    },
  },
  watch: {
    settings: {
      handler: function (newSettings) {
        this.setIndicatorsScanSettings(newSettings)
      },
      deep: true,
    },
  },
}
</script>

<style>
/* .input-container{
  font-size: 20px;
}
.fontSize{
  font-size: 14px;
} */
</style>
