/**
 * @constant {{ DATE: {Number}, OPEN: {Number}, HIGH: {Number}, LOW: {Number}, CLOSE: {Number}, VOLUME: {Number}, VALUE: {Number} }}
 */
export const symbolIndexes = {
  /** @constant {Number} DATE time index in the main chart data tOHLCV array */
  DATE: 0,

  /** @constant {Number} OPEN open price index in the main chart data tOHLCV array */
  OPEN: 1,

  /** @constant {Number} HIGH high price index in the main chart data tOHLCV array */
  HIGH: 2,

  /** @constant {Number} LOW low price index in the main chart data tOHLCV array */
  LOW: 3,

  /** @constant {Number} CLOSE close price index in the main chart data tOHLCV array */
  CLOSE: 4,

  /** @constant {Number} VOLUME volume price index in the main chart data tOHLCV array */
  VOLUME: 5,

  /** @constant {Number} VALUE value price index in the main chart data tOHLCV array */
  VALUE: 7
}
