import { getSPXData } from '../../../services/symbol';
import store from '../../../store/index'
import { parseToChartCandleData } from '../../../utils';
export default class RSR {
  constructor() {

  }

  /**
   *
   * @param {Map<DateTime, Candle>} candlesData
   * @param {Number} endDate
   * @param {Number} length
   */
  async Calculate() {
    if (store.getters.spxData.length == 0) {     

      const spx_data = await getSPXData()
      let [parsedData, ohlcvMap] = parseToChartCandleData(spx_data.data)
      store.commit('SET_SPX', parsedData)
      
      return parsedData
    }
    else{
      return store.getters.spxData
    }
  }

}
