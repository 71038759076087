<template>
  <div style="padding: 16px">
    <sui-form>
      <h3>Select one or more of the exit signals</h3>
      <sui-form-field>
        <sui-checkbox label="Gap Filling" v-model="formData.gapFilling" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Pattern Target" v-model="formData.patternTarget" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Moving Average Breach" v-model="formData.movingAverageBreach" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Moving Average Crossover" v-model="formData.movingAverageCrossover" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Break of Support" v-model="formData.breakOfSupport" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Break of Resistance" v-model="formData.breakOfResistance" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Fibonacci Target" v-model="formData.fibonacciTarget" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Other" v-model="formData.other" />
      </sui-form-field>
    </sui-form>
  </div>
</template>

<script>
export default {
  computed: {
    /** @returns {Object} */
    formData: {
      get() {
        return this.$store.state.tadePlanner.exitSignalData;
      },
      set(value) {
        this.$store.commit("UPDATE_TRADE_PLANNER_STATE", {
          key: "exitSignalData",
          data: value,
        });
      },
    },
  },
  name: "ExitSignal",
};
</script>

<style></style>
