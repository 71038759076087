<template>
  <dialog-box :modalHeader="header" :open="open" :action="false">
    <div style="width: 25vw; padding: 0; margin: 0">
      <sui-form @submit.prevent="ok" style="width: 90%; padding: 0; margin: 0;">
        <textarea 
        :style="themeDarkMode ? {background:'#151515 !important',borderColor:'#505050',color:'#A2A2A2'}:{}"
        v-model="text" style="font-size: 2rem"></textarea>
      </sui-form>
    </div>
    <template v-slot:action >
      <sui-modal-actions :style="themeDarkMode ? {background:'#151515 !important',borderColor:'#505050',color:'#A2A2A2'}:{}">
        <sui-button :disabled="!valid" negative @click.prevent.native="ok" :style="themeSecondaryColor">
          OK
        </sui-button>
        <sui-button @click.prevent.native="onClose" secondary
          >Cancel</sui-button
        >
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TextSettings',
  components: {
    DialogBox
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: 'Enter Text'
    },
    initialText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.text = this.initialText
  },
  data() {
    return {
      text: '',
      dark_Theme_Col: ''
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    ok() {
      this.$emit('ok', this.text)
    }
  },
  computed: {
    ...mapGetters(['themeDarkMode', 'themePrimaryColor', 'themeSecondaryColor']),
    valid() {
      return this.text.length > 0
    }
  },
  watch:{
    // themeDarkMode(val){
    //   if(val){
    //     this.dark_Theme_Col = '#151515'
    //   }
    //   else{
    //     this.dark_Theme_Col = this.themePrimaryColor.backgroundColor
    //   }

    // }
  }
}
</script>

<style>
/* .actions{
  background-color: v-bind(dark_Theme_Col) !important;
} */
</style>
