<template>
    <dialog-box modalHeader="Search for Historical Darvas Boxes" :open="isOpen" :action="false">

        <sui-form :loading="isLoading" size="mini">

            <div style="height: 25vh; padding: 8px; ">
                <div class="input-container space-between fontSize">
                    <span> Scan Type:</span>
                    <select type="select" v-model="settings.scanType" class="small-input">
                        <option v-for="scanType in scanTypes" :key="scanType.value" :value="scanType.value">
                            {{ scanType.text }}
                        </option>
                    </select>
                </div>

                <div class="input-container space-between fontSize">
                    <span> Scan period </span>
                    <input class="small-input" type="number" v-model="settings.scanPeriod" @keypress="preventDecimal" />
                </div>

                <div class="input-container space-between fontSize">
                    <span> Top confirmation period </span>
                    <input class="small-input" type="number" v-model="settings.topConfirmationPeriod"
                        @keypress="preventDecimal" />
                </div>

                <div class="input-container space-between fontSize">
                    <span> Bottom confirmation period </span>
                    <input class="small-input" type="number" v-model="settings.bottomConfirmationPeriod"
                        @keypress="preventDecimal" />
                </div>

                <div class="input-container space-between fontSize">
                    <span> Percentage risk buffer </span>
                    <input class="small-input" type="number" v-model="settings.percentageRiskBuffer" />
                </div>

                <div class="input-container space-between fontSize">
                    <base-check-box label="Limit total risk to" v-model="settings.limitTotalRisk.isActive" />
                    <input class="small-input" type="number" v-model="settings.limitTotalRisk.valuePct" />
                </div>

                <div class="input-container fontSize">
                    <base-check-box label="Volume increased over" v-model="settings.volumeIncreasedOver.isActive" />
                    <input class="small-input" type="number" step="0.01" min="0.01" max="100.0"
                        v-model="settings.volumeIncreasedOver.percent" />
                    <span>% on previous</span>
                    <input class="small-input" type="number" step="1" min="1"
                        v-model="settings.volumeIncreasedOver.previousBars" @keypress="preventDecimal" />
                    <span>bar(s)</span>
                </div>
                <!-- <div class="input-container fontSize">
                    <base-check-box label="In a box" v-model="settings.inBox.isActive" />
                    <input type="radio" id="box_anyBox" name="box" value="anyBox" :style="{ cursor: 'pointer' }"
                        v-model="settings.inBox.type" />
                    <label for="box_anyBox" :style="{ ...themeFontColor, cursor: 'pointer' }">Any Box</label>
                    <input type="radio" id="box_1stBox" name="box" value="1stBox" :style="{ cursor: 'pointer' }"
                        v-model="settings.inBox.type" />
                    <label for="box_1stBox" :style="{ ...themeFontColor, cursor: 'pointer' }">1st Box</label>
                </div> -->


            </div>
        </sui-form>
        <template v-slot:action>
            <sui-modal-actions :style="{ ...themePrimaryColor, ...themeFontColor }">
                <div class="bottom_layout bottom_layout_Scan">
                    <div :style="'display:flex;  justify-content: space-between; width:100%'" class="">
                        <div>
                            <sui-button icon="undo" key="onResetGurus" secondary @click="ResetScanDefaultSettings" :disabled="isLoading"/>
                        </div>
                        <div>
                            <sui-button style="color: white" :style="themeSecondaryColor" @click.native="runScan" :disabled="isLoading">
                                Ok
                            </sui-button>
                            <sui-button @click.native="onClose" secondary :disabled="isLoading">
                                Cancel
                            </sui-button>
                        </div>
                    </div>
                </div>
            </sui-modal-actions>
        </template>
    </dialog-box>

</template>

<script>
import DialogBox from "@/baseComponents/DialogBox.vue";

import BaseCheckBox from '@/baseComponents/BaseCheckBox.vue'
import { mapActions, mapGetters } from 'vuex'


const DEFAULT_SETTINGS = {
    scanType: 'long',
    scanPeriod: 55,
    topConfirmationPeriod: 3,
    bottomConfirmationPeriod: 3,
    percentageRiskBuffer: 2.0,
    limitTotalRisk: {
        isActive: false,
        valuePct: 20.0,
    },
    volumeIncreasedOver: {
        isActive: false,
        percent: 300,
        previousBars: 1,
    },
    inBox: {
        isActive: false,
        type: 'anyBox', // 'anyBox' | '1stBox'
    },

}
export default {
    name: "DarvasBoxDrawing",
    components: {
        DialogBox,
        BaseCheckBox
    },
    methods: {
        ...mapActions([
            'sendDarvasBoxDrawingRequest',
            'changeSymbolToScannedSymbol'
        ]),
        onClose() {
            this.$emit("darvasBoxClose");
        },
        preventDecimal(event) {
            if (event.key === '.' || event.key === ',' || event.key === '-') {
                event.preventDefault();
            }
        },
        ResetScanDefaultSettings() {
            this.settings = DEFAULT_SETTINGS
        },
        async runScan() {
            this.isLoading =true;
            this.$emit("darvasBoxDelete");
            const { data } = await this.sendDarvasBoxDrawingRequest(this.settings)
            if (data?.data) {

                const timeFrame = this.timeFrame
                const settings = this.settings
                const isDrawing = true
                const scanType = 'darvasScan'
                this.changeSymbolToScannedSymbol({
                    symbolData: data,
                    timeFrame,
                    isDrawing,
                    scanType,
                    settings,
                }).then((d) => { })
                this.isOpen = false
                this.isLoading =false
            }
            else {
                this.isOpen = false
                this.isLoading =false
                this.$emit("darvasBoxNotFound");
            }
        },

    },
    data() {
        return {
            isOpen: false,
            settings: DEFAULT_SETTINGS,
            isLoading: false,
            scanTypes: [
                {
                    text: 'Long',
                    value: 'long',
                },
                {
                    text: 'Short',
                    value: 'short',
                },
            ],

        };
    },
    computed: {
        ...mapGetters(["themeDarkMode", "themeFontColor", "themeSecondaryColor", "themePrimaryColor", "symbolName", "timeFrame", "getDarvasBoxSettings"]),
    },

    mounted() {
        this.isOpen = true;
        if (this.getDarvasBoxSettings) {
            this.settings = { ...this.settings, ...this.getDarvasBoxSettings };
        }
    },
};
</script>

<style></style>