// -------- Production extension index ---------
//      ! THIS FILE WAS AUTO-GENERATED !
//
// Do not commit this file, the final index is
// compiled by the repo owner, use index_dev.js to
// experiment: 'npm run compile'

import SettingsWin from './SettingsWin.vue'
import Main from './main.js'
Main.__name__ = 'settings-win'

const widgets = { SettingsWin }
const components = {}
const overlays = {}
const colorpacks = {}
const skins = {}

const Pack = {
  widgets,
  SettingsWin,
  components,
  overlays,
  colorpacks,
  skins,
  Main
}

export default Pack

export { widgets, SettingsWin, components, overlays, colorpacks, skins, Main }
