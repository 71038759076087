<template>
  <div class="check-box-container">
    <input type="checkbox" :name="label" v-model="localState" @click="$emit('click',$event.target.checked)"/>
    <label :for="label" style="flex-flow:wrap">{{ label }}</label>

  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: 'checkbox'
    },
    checked: {
      type: Boolean,
      default: false
    },
    fieldRef: {
      type: String
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      localState: false
    }
  },
  created() {
    if(this.checked){
      this.localState = this.checked
    }
  },
  name: 'BaseCheckBox',
  watch: {
    localState(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue,this.fieldRef,this.type)
      }
    },
    checked(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localState = newValue
      }
    },
    
  }
}
</script>

<style>

.check-box-container {
  display: flex;
  align-items: center;
  /*accent-color: #e72510 !important;*/
}
.check-box-container > * {
  margin-left: 0.5rem;
}


</style>