<template>
  <div>
    <div v-if="scanDeletePopup" class="Delete-FavouritePopupParent" style="">
      <!-- <div> -->

      <div class="Delete-Favourite-Popup">
        <!-- <div > -->
        <div class="Delete-popup-firstChild">
          <sui-form :loading="deleteFavScanLoader">
            <p>
              Are you sure you want to delete your
              <span style="font-weight: bolder">{{
                scanName || deleteFavScanItem || deleteFavScanName
              }}</span>
              scan from favourites?
            </p>
          </sui-form>
        </div>
        <hr />
        <div class="Delete-popup-secondChild">
          <sui-button-group>
            <sui-button
              style="background-color: #e72510; color: white"
              @click.once="DeleteList()"
              >Yes</sui-button
            >
          </sui-button-group>
          <sui-button-group>
            <sui-button secondary @click="HideDeletePopup()">No</sui-button>
          </sui-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DeleteFavouriteList',
  props: ['deleteFavScanItem','scanName'],
  computed: { ...mapGetters(['scanDeletePopup', 'deleteFavScanLoader']) },
  data() {
    return {
      deleteFavScanName: '',
    }
  },
  methods: {
    DeleteList() {
      this.deleteFavScanName = this.deleteFavScanItem
      this.$store.commit('SET_DEL_FAV_SCAN_LOADER', true)
      this.$emit('SureDeleteFavScanItem', true)
    },
    HideDeletePopup() {
      this.$store.commit('SCAN_DELETE_POPUP', false)
    },
  },
}
</script>

<style scoped>
.Delete-Favourite-Popup {
  height: 170px;
  width: 320px;
  background-color: #ffffff;
  box-shadow: 0px 0.2px 20px 2px #afa7a7;
  position: relative;
  top: 10px;
}

.Delete-popup-firstChild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.Delete-popup-secondChild {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 40%;
  width: 100%;
  background-color: transparent;
  padding: 8px 13px;
  gap: 4px;
}

.Delete-FavouritePopupParent {
  display: flex;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 04, 0.5);
  z-index: 105;
  position: absolute;
}
</style>
