<template>
  <guru-base-modal
  class="fontSize"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    header="Big Candle"
    :valid="isValid"
    :isLoading="isLoading"
    :scanType="scanType"
    id="bigCandle"
  >
    <div class="input-container fontSize">
      <span> Find a candle </span>
      <input
        class="small-input"
        type="number"
        min="1"
        v-model="settings.candleSizeMultiplier"
      />
      <span> times larger than previous </span>
      <input
        class="small-input"
        type="number"
        min="1"
        v-model="settings.previousCandlePeriod"
          @keypress="preventDecimal"
      />
      <span> candles </span>
    </div>
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'BigCandle',
  data() {
    return {
      isLoading: false,
      scanType: "bigCandleScan"
    }
  },
  methods: {
    ...mapActions([
      'setBigCandleScanSettings',
      'sendBigCandleScanRequest',
      'cancelGuruScans',
      'updateIntraDayModeState',
      'saveguruScanRequest',
      'getUserScanList'
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.updateIntraDayModeState(false)
      await this.sendBigCandleScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
    },
    async saveScanSettings(name) {
      await this.saveguruScanRequest({
        scanName:name,
        scanType:'bigCandle'
      })
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return (
        this.settings.candleSizeMultiplier > 0 &&
        this.settings.previousCandlePeriod > 0
      )
    },
    settings: {
      get() {
        return this.gurusScanSettings.bigCandle
      },
      set(value) {
        this.setBigCandleScanSettings(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container{
  font-size: 13.5px;
}

.fontSize{
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width:870px){
.fontSize{
  font-size: 11px;
  margin-bottom: 5px;
}
}
@media screen and (max-width:510px){
.fontSize{
  font-size: 10px;
  margin-bottom: 7px;
}
}
</style>
