<template>
  <div>
    <sui-form>
      <sui-form-field>
        <label>Entry Price</label>
        <input
          placeholder="Entry Price"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="formData.entryPrice"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Stop Loss</label>
        <input
          placeholder="Stop Loss"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="formData.stopLoss"
        />
      </sui-form-field>
      <!-- auto calculated -->
      <sui-form-field>
        <label>Risk Per Share($)</label>
        <input
          placeholder="Risk Per Share($)"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          disabled
          :value="riskPerShare"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Portfolio Risk(%)</label>
        <input
          placeholder="Portfolio Risk(%)"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="formData.portFolioRisk"
        />
      </sui-form-field>
      <!-- auto calculated -->
      <sui-form-field>
        <label>Portfolio Risk($)</label>
        <input
          placeholder="Portfolio Risk($)"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          disabled
          :value="portFolioRiskInCash"
        />
      </sui-form-field>
      <sui-form-field :error="isLargePositionSizeError">
        <label>Position Size</label>
        <input
          placeholder="Position Size"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="positionSize"
        />
        <div v-if="isLargePositionSizeError" style="color: red">Reduce the position size</div>
      </sui-form-field>
      <!-- auto calculated -->
      <sui-form-field>
        <label>Trade Risk($)</label>
        <input
          placeholder="Trade Risk($)"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          disabled
          :value="tradeRisk"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Cost of a position</label>
        <input
          placeholder="Cost of a position"
          class="n-input"
          type="number"
          min="0"
          step="0.01"
          v-model="costOfPosition"
        />
      </sui-form-field>
    </sui-form>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'isValidated',
    event: 'change'
  },
  props: {
    isValidated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /** @returns {Object} */
    formData: {
      get() {
        const frmData = this.$store.state.tadePlanner.positionSize
        if (!frmData.entryPrice) {
          frmData.entryPrice = this.accountBalanceData.purchasePrice
        }
        return frmData
      },
      set(value) {
        this.$store.commit('UPDATE_TRADE_PLANNER_STATE', {
          key: 'positionSize',
          data: value
        })
      }
    },
    /** @returns {Object} */
    accountBalanceData() {
      return this.$store.state.tadePlanner.accountBalanceData
    },
    /** @returns {Number} */
    riskPerShare() {
      if (!this.formData.entryPrice || !this.formData.stopLoss) return ''
      return (this.formData.entryPrice - this.formData.stopLoss).toFixed(3)
    },
    /** @returns {Number} */
    portFolioRiskInCash() {
      if (
        !this.accountBalanceData.accountBalance ||
        !this.formData.portFolioRisk
      ) {
        return ''
      }
      return (
        this.accountBalanceData.accountBalance *
        this.formData.portFolioRisk *
        0.01
      ).toFixed(3)
    },
    positionSize: {
      get(){
        return Math.round(this.formData.positionSize)
      },
      set(value) {
        this.formData.positionSize = Math.round(value)
        this.formData.costOfPosition = Math.round(this.formData.positionSize * this.formData.entryPrice)
      }
    },
    /** @returns {Number} */
    tradeRisk() {
      if (!this.formData.positionSize || !this.riskPerShare) {
        return ''
      }
      return (this.formData.positionSize * this.riskPerShare).toFixed(3)
    },
    costOfPosition: {
      get() {
        return this.formData.costOfPosition
      },
      set(value) {
        if(!this.formData.entryPrice) return
        this.formData.positionSize = Math.floor(value / this.formData.entryPrice)
      }
    },
    isLargePositionSizeError(){
      return this.formData.costOfPosition > this.accountBalanceData.accountBalance
    }
  },
  watch: {
    formData: {
      handler: function(newValue) {
        for (const fieldValues of Object.values(newValue)) {
          if (!fieldValues) return this.$emit('change', false)
        }
        this.$emit('change', true)
      },
      deep: true
    },
    portFolioRiskInCash(newValue) {
      if (!newValue || !this.riskPerShare) return ''    
      this.positionSize = this.formData.portFolioRisk * 0.01 * this.accountBalanceData.accountBalance / this.riskPerShare
    }
  },
  name: 'PositionSize'
}
</script>

<style></style>
