export const currentSymbolDefaults = {
    symbol: '',
    companyName: '',
    timeFrame:  localStorage.getItem('SET_TIME_FRAME')||'D',
    assetID: 0,
    metaData: {
        exchange: 'ASX',
        category: 'Equity',
        sector: 'Financials',
        marketCap: 0,
        peRatio: 0,
        eps: 0,
        earningYield: 0,
        tradingBasis: '',
        dividendRate: 0,
        dividendYield: 0,
        return1m: 0,
        return3m: 0,
        return6m: 0,
        return12m: 0,
        frankedPct: 0,
        issue: 0,
    },
    classificationInfo:{
        exchange: 'ASX',
        sector: 'Financials',
        industry: 'Financials',
        subIndustry: 'Financials',
    },
    health: 0,
    data: [],
}