<template>
  <div class="ui grid  center aligned ">
      <div class="fouteen wide column ">
      <div class="ui segment loadingData">
        <div class="ui active inverted dimmer">
        <div class="ui text loader">Loading</div>
        </div>
    </div>
        <p></p>
    </div>
  </div>
</template>

<script>
export default {
        name : "WatchesLoader"
}
</script>

<style>
.loadingData{
    height: 200px; 
}
</style>