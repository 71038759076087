var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-box',{staticStyle:{"z-index":"110"},attrs:{"size":"mini","modalHeader":"Add Watch","open":_vm.open,"action":false},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('sui-modal-actions',{style:(_vm.themeDarkMode
          ? {
              background: '#151515 !important',
              borderColor: '#151515',
              color: '#A2A2A2',
            }
          : {})},[_c('div',[_c('sui-button',{style:(_vm.themeSecondaryColor),attrs:{"negative":"","disabled":!(_vm.newWatchName && _vm.newWatchName.trim())},nativeOn:{"click":function($event){return _vm.createWatches.apply(null, arguments)}}},[_vm._v("Create ")]),_c('sui-button',{style:(_vm.themeDarkMode
              ? {
                  background: '#505050 !important',
                  borderColor: '#151515',
                  color: '#A2A2A2',
                }
              : {}),attrs:{"disabled":_vm.showLoader},nativeOn:{"click":function($event){return _vm.toggleWatchesModalInner.apply(null, arguments)}}},[_vm._v("Cancel ")])],1)])]},proxy:true}])},[_c('sui-form',{attrs:{"loading":_vm.showLoader,"size":"small"}},[_c('div',{staticClass:"ui center aligned header positionStyle fontStyle"},[_c('br'),_c('h4',{staticClass:"ui header fontStyle",staticStyle:{"font-size":"14px"},style:(_vm.themeDarkMode ? { color: '#A2A2A2' } : {})},[_vm._v(" Please enter a name for your new watchlist: ")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newWatchName),expression:"newWatchName"}],style:(_vm.themeDarkMode
            ? {
                background: '#505050 !important',
                borderColor: '#151515',
                color: '#A2A2A2',
              }
            : {}),attrs:{"type":"text"},domProps:{"value":(_vm.newWatchName)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.createWatches.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.newWatchName=$event.target.value}}}),(_vm.duplicationError)?[_c('br'),_c('h4',{staticClass:"ui red header",staticStyle:{"font-size":"14px"},style:(_vm.themeDarkMode ? { color: '#A2A2A2' } : {})},[_vm._v(" A watchlist with that name already exists ")]),_c('br')]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }