<template>
    <svg  version="1.1" id="Layer_1" xmlns="&ns_svg;" xmlns:xlink="&ns_xlink;" width="20.014" height="20.002"
	 viewBox="0 0 20.014 20.002" overflow="visible" enable-background="new 0 0 20.014 20.002" xml:space="preserve">
<line class="no-fill" :stroke="color" stroke-width="2" x1="1" y1="1" x2="1.04" y2="20"/>
<line class="no-fill" :stroke="color" stroke-width="2" stroke-linecap="round" x1="1" y1="1" x2="18.994" y2="7.499"/>
<line class="no-fill" :stroke="color" stroke-width="2" x1="1.02" y1="10.54" x2="19.014" y2="17.038"/>
<line class="no-fill" :stroke="color" stroke-width="2" stroke-linecap="round" x1="18.994" y1="7.499" x2="19.014" y2="17.038"/>
</svg>
  </template>
  <script>
  export default {
    name: "bullish_flag",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
  </script>