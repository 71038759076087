<template>
  <dialog-box
    modalHeader="Trailing Stop"
    :open="isOpen"
    @onClose="onClose"
    @ok="updateData"
  >
  <!-- themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:themeFontColor -->
  <!-- themeDarkMode ? {color:'#A2A2A2'}:themeFontColor -->
    <div style="height: 30vh; padding: 8px; ">
      <sui-form style="font-size: 14px;">
        <sui-form-field>
          <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Entry price</label>
          <sui-form-field>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              placeholder="Entry Price"
              step="0.01"
              min="0"
              v-model="entryPrice"
            />
          </sui-form-field>
        </sui-form-field>

        <sui-form-fields>
          <sui-form-field width="eight">
            <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Trailing Type</label>
            <sui-dropdown
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"              placeholder="Trailing Type"
              selection
              :options="trailingType"
              v-model="selectedTrailingType"
            />
          </sui-form-field>
          <sui-form-field
            v-if="selectedTrailingType === 'step-price'"
            width="three"
          >
            <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Step Price</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="stepPrice"
              placeholder="Step Price"
              min="0"
              step="0.01"
              v-model="stepPrice"
            />
          </sui-form-field>

          <sui-form-field width="three" v-if="selectedTrailingType === 'value'">
            <label>Position Size</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="positionSize"
              placeholder="Position Size"
              value="1000"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === 'value'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">and</h3>
          <sui-form-field width="three" v-if="selectedTrailingType === 'value'">
            <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Risk Value</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="riskedValue"
              placeholder="Risk Value"
              value="500"
            />
          </sui-form-field>

          <!-- % -->
          <sui-form-field
            width="three"
            v-if="selectedTrailingType === '%value'"
          >
            <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Position Size</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="positionSize"
              placeholder="Position Size"
              value="1000"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === '%value'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">and</h3>
          <sui-form-field
            width="three"
            v-if="selectedTrailingType === '%value'"
          >
            <label>Risk %</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="riskedValue"
              placeholder="Risk Value %"
              value="10"
            />
          </sui-form-field>

          <!-- atr -->
          <sui-form-field width="three" v-if="selectedTrailingType === 'atr'">
            <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Day Period</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="atrPeriod"
              placeholder="ATR Period"
              value="10"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === 'atr'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">and</h3>
          <sui-form-field width="three" v-if="selectedTrailingType === 'atr'">
            <label>Multiplier</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              name="multiplier"
              placeholder="Multiplier"
              value="5"
            />
          </sui-form-field>
        </sui-form-fields>

        <sui-form-field
          v-if="
            selectedTrailingType === 'atr' ||
            selectedTrailingType === 'step-price'
          "
        >
          <label :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">Stop Price</label>
          <sui-form-field>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
              placeholder="Stop Price"
              step="0.01"
              min="0"
              v-model="stopPrice"
            />
          </sui-form-field>
        </sui-form-field>
      </sui-form>
    </div>
  </dialog-box>
</template>

<script>
import DialogBox from "@/baseComponents/DialogBox.vue";
import { mapActions, mapGetters } from "vuex";
import {chartEvents} from "../../chartEvents";

export default {
  name: "TrailingStop",
  components: {
    DialogBox,
  },
  props: {
    trialingStopData:{
      type:Object,
      default: () => {}
    }
  },
  methods: {
    onClose() {
      this.$emit("hideTrailingStop");
    },
    updateData() {
      let identity = "UpdateTrailingStopSettings"+this.trialingStopData?.$uuid;
      // let identity = "UpdateTrailingStopSettings";
      chartEvents.$emit(identity,{
        stepPrice:this.stepPrice,
        stopPrice:this.stopPrice,
        entryPrice:this.entryPrice,
        uuid:this.trialingStopData?.$uuid,
      });
      this.$emit("hideTrailingStop");
      
    },
  },
  data() {
    return {
      isOpen:false,
      entryPrice:0,
      stopPrice:0,
      stepPrice:0,
      trailingType: [
        { text: "based on step price", value: "step-price" },
        { text: "$ value of a parcel", value: "value" },
        { text: "% value of a parcel", value: "%value" },
        { text: "ATR", value: "atr" },
      ],
      selectedTrailingType: "step-price",
    };
  },
  computed: {
    ...mapGetters([ "themeDarkMode", "themeFontColor", "themeSecondaryColor", "themePrimaryColor"]),
  },
  created() {
    if(this.$props?.trialingStopData){
      this.stepPrice = this.$props?.trialingStopData?.stepPrice
      this.entryPrice = this.$props?.trialingStopData?.entryPrice
      this.stopPrice = this.$props?.trialingStopData?.stopPrice
    }
  },
  mounted() {
    if(this.$props?.trialingStopData){
      this.isOpen = true;
    }
  }
};
</script>

<style></style>
