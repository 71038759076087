<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="currentColor"
  >
    <circle cx="9" cy="14" r="1"></circle>
    <circle cx="4" cy="14" r="1"></circle>
    <circle cx="14" cy="14" r="1"></circle>
    <circle cx="19" cy="14" r="1"></circle>
    <circle cx="24" cy="14" r="1"></circle>
  </svg>
</template>

<script>
export default {
  name: 'DottedLine'
}
</script>