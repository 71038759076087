<template>
  <guru-base-modal v-model="open" @onClose="$emit('change', !open)" @stop="onScanStop" @ok="onScanClick"
    @saveScanSettings="saveScanSettings" header="Higher High Higher Low" :valid="isValid" customStyle=""
    :scanType="scanType" id="hhhl" :isLoading="isLoading">
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'HigherHighHigherLow',
  data() {
    return {
      scanType: "hhhl",
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'sendHigherHighHigherLowPatternRequest',
      'cancelGuruScans',
      'setHhhlSettings',
      'saveguruScanRequest',
      'getUserScanList'
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendHigherHighHigherLowPatternRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    async saveScanSettings(name) {
      await this.saveguruScanRequest({
        scanName: name,
        scanType: 'hhhl'
      })
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return true
    },
    settings: {
      get() {
        return this.gurusScanSettings.hhhl
      },
      set(value) {
        this.setHhhlSettings(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
