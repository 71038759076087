<template>
  <dialog-box class="fontSize" modalHeader="Favourite Scan" :action="false" :open="open" @onClose="onClose"
    @ok="onClose" size="small" @SureDeleteFavScanItem="SureDeleteFavScanItem" @HideDeletePopup="HideDeletePopup"
    :deleteFavScanItem="deleteFavScanItem" :showDeleteDialog="showDeleteDialog">
    <div>
      <sui-segment :loading="isLoading" :inverted="themeDarkMode"
        :style="themeDarkMode ? { 'background-color': '#151515', border: '1px solid #2C2929' } : {}">
        <sui-form>
          
        <div v-if="favouriteScanList.length > 0">

          <sui-form-fields grouped>
            <sui-grid divided="vertically" :columns="3">
              <sui-grid-column v-for="(scan, index) in favouriteScanList">
                <!-- <sui-form-fields inline> -->
                  <div class="input-container fontSize">
                  <input type="radio" :id="`Favourite_scan~${scan.scanName}_${index}`" :name="`Favourite_scan`"
                    :value="scan.id" :style="{ cursor: 'pointer' }" v-model="selectedFavScan" />
                  <label :for="`Favourite_scan~${scan.scanName}_${index}`" :style="getRadioProps" class="fontSize">
                    {{ scan.scanName }}
                  </label>
                </div>
                <!-- </sui-form-fields> -->
              </sui-grid-column>
            </sui-grid>
          </sui-form-fields>
          <DataSettings :favouriteScanId="selectedFavScan" type="favourite" v-if="selectedFavScan"/>

        </div>
        <div v-else>
          <h3>There are no favourite scans saved.</h3>
        </div>
        </sui-form>
      </sui-segment>
    </div>
    <template v-slot:action>
      <sui-modal-actions class="favs-action-bar" :style="{ ...themePrimaryColor, ...themeFontColor }">
        <div>
          <sui-button secondary icon="delete" @click="Delete()" :disabled="!valid || isLoading" data-tooltip="Delete Scan"
          data-position="bottom center" data-inverted=""></sui-button>
        </div>
        <div>

        </div>
        <div>
          <sui-button style="color:white;" :disabled="!valid || isLoading" :style="themeSecondaryColor" @click.native="runScan">
            Scan
          </sui-button>
          <sui-button @click.native="onClose" secondary>Cancel</sui-button>
        </div>
      </sui-modal-actions>
    </template>



  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import TradePlannerStepperDialog from './TradePlannerStepperDialog.vue'
import { mapGetters, mapActions } from 'vuex'
import DataSettings from './DataSettings.vue';
export default {
  name: 'FavouriteScan',
  components: {
    DialogBox,
    TradePlannerStepperDialog,
    DataSettings
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      selectedFavScan: null,
      deleteFavScan: false,
      showDeleteDialog: false,
      deleteFavScanItem: '',
    }
  },

  methods: {
    ...mapActions([
      'runAndUpdateScanSettings',
      'deleteScanSettings',
      'getUserScanList'
    ]),
    onClose() {
      this.$emit('change', !open)
    },
    async runScan() {


      this.isLoading = true
      const index = this.userSavedScans.findIndex((x) => { return x.id == this.selectedFavScan })
      await this.runAndUpdateScanSettings(index)
      await this.getUserScanList()
      this.isLoading = false
      this.$emit('change', !open)

    },
    Delete() {
      
      if (this.selectedFavScan) {
        this.$store.commit(
          'FAV_SELECTED_SCAN_ID',
          this.selectedFavScan
        )
        this.deletedScan(this.userSavedScans.find(x => x.id === this.selectedFavScan).scanName)
        this.$store.commit('SCAN_DELETE_POPUP', true)
      }

    },
    SureDeleteFavScanItem(check) {
      this.deleteFavScan = check
      this.deletedScan()
    },
    HideDeletePopup() {
      this.showDeleteDialog = false
    },
    async deletedScan(selectedScanName) {
      this.deleteFavScanItem = selectedScanName
      this.showDeleteDialog = true
      if (this.deleteFavScan) {        
        await this.deleteScanSettings(this.favSelectedScanId)
        this.$store.commit('SCAN_DELETE_POPUP', false)
        this.selectedFavScan = null
      }
      this.deleteFavScan = false
    },

  },

  computed: {
    ...mapGetters([
      'getScanFavListTypes',
      'themeFontColor',
      'themeSecondaryColor',
      'themeDarkMode',
      'userSavedScans',
      'getScanFavListTypes',
      'getGuruFavListTypes',
      'themePrimaryColor',
      'themeFontColor',      
      'favSelectedScanId',
    ]),
    getRadioProps() {
      return {
        ...this.themeFontColor,
        cursor: 'pointer',
        marginRight: '.8em',
        fontWeight: 'normal',
      }
    },
    favouriteScanList() {
      const guruFavScans = this.userSavedScans.filter((scan) => (this.getScanFavListTypes.includes(scan.scanType) || this.getGuruFavListTypes.includes(scan.scanType)));
      return guruFavScans;
    }
  },
  watch: {
    selectedFavScan(newVal, oldVal) {
      if (newVal != null) {
        this.valid = true
      }
      else {
        this.valid = false
      }

    }
  }
}
</script>

<style>
.favs-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.fontSize {
  font-size: 15.5px;
}
</style>
