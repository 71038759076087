<template>
  <dialog-box
    modalHeader=""
    :open="open"
    :action="false"
    :scrolling="false"
    style="border-radius: 10px !important"
  >
    <div
      style="
        height: 60vh;
        padding: 8px;
        font-size: 13.5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <div class="ui" style="height: 10vh; display: flex; align-items: center">
        <!-- <div class="two wide column"></div> -->
        <div class="sixteen wide column" style="flex-grow: 1">
          <sui-input
            style="height: 35px"
            placeholder="Filtered"
            icon=""
            fluid
            v-model="filteredtext"
            v-on:keyup="keyUpSearch"
          />
        </div>
        <sui-button
          id="clearSearchId"
          class="right-header-button"
          icon="trash alternate"
          ref="clearSearch"
          secondary
          @click="clearSymbolSearch"
        />

        <!-- <div class="two wide column"></div> -->
      </div>
      <sui-segment
        :loading="loading"
        :style="
          themeDarkMode
            ? { 'background-color': '#151515', border: '1px solid #2C2929' }
            : {}
        "
      >
        <div
          class="ui grid"
          style="height: 52vh"
          :style="
            themeDarkMode
              ? { 'background-color': '#151515', border: '1px solid #2C2929' }
              : {}
          "
        >
          <!--        <sui-loader content="Loading..." />-->

          <div class="four wide column" style="height: 52vh; overflow-y: scroll;">
            <div
              class="ui middle aligned selection list"
              style="margin: 0"
              :style="
                metadata.selected
                  ? { ...themeSecondaryColor }
                  : { ...themeFontColor }
              "
              v-for="(metadata, i) of markets"
              :key="metadata.id"
              v-show="metadata.show"
            >
              <div class="item">
                <div class="content">
                  <div
                    class="header"
                    :style="
                      metadata.selected ? { color: 'white' } : themeFontColor
                    "
                    @click.prevent="
                      changeMarketState(i, 'markets', 'showCategory')
                    "
                    :ref="`marketDiv_${metadata.code}`"
                  >
                    {{ metadata.desc }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="four wide column" style="height: 52vh">
            <div
              style="margin: 0"
              class="ui middle aligned selection list"
              :style="
                metadata.selected
                  ? { ...themeSecondaryColor, ...themeFontColor }
                  : { ...themeFontColor }
              "
              v-for="(metadata, i) of categoryByMarket"
              :key="metadata.id"
              v-show="showCategory && metadata.show"
            >
              <a
                class="item"
                :ref="`categoryDiv_${metadata.desc}`"
                @click.prevent="
                  updateContract(i, metadata.desc, 'category', 'showContracts')
                "
              >
                <div class="content">
                  <div
                    class="header"
                    :style="
                      metadata.selected ? { color: 'white' } : themeFontColor
                    "
                  >
                    {{ metadata.title }}
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div
            class="eight wide column"
            style="
              overflow-y: scroll !important;
              height: 50vh;

              /* background-color: red; */
              /* flex-basis: 60%; */
              flex-grow: 1;
            "
          >
            <sui-table basic="very" compact="very" :key="3">
              <sui-table-body>
                <sui-table-row>
                  <sui-table-cell>
                    <sui-list v-show="showContracts">
                      <sui-list-item
                        v-for="(symbol, i) of symbolData"
                        :key="i"
                        @click="symbolChange(i, symbol)"
                        :style="
                          symbol.selected
                            ? {
                                cursor: 'pointer',
                                color: 'white',
                                ...themeSecondaryColor,
                              }
                            : {
                                cursor: 'pointer',
                                ...themeFontColor,
                                padding: '5px',
                              }
                        "
                      >
                        <template v-if="symbol.selected">
                          <div
                            :style="{
                              display: 'flex',
                              gap: '.5vw',
                              color: 'white',
                              ...themeSecondaryColor,
                            }"
                          >
                            <div style="flex-basis: 20%; text-align: left">
                              {{ getSymbolName(symbol) }}
                            </div>
                            <div>{{ getCompanyName(symbol) }}</div>
                          </div>
                        </template>
                        <template v-if="!symbol.selected">
                          <div
                            class="header"
                            :style="{
                              display: 'flex',
                              ...themeFontColor,
                              gap: '.5vw',
                            }"
                          >
                            <div style="font-weight: bolder; flex-basis: 20%">
                              {{ getSymbolName(symbol) }}
                            </div>
                            <div style="flex-basis: 80%">
                              {{ getCompanyName(symbol) }}
                            </div>
                          </div>
                        </template>
                      </sui-list-item>
                    </sui-list>
                  </sui-table-cell>
                </sui-table-row>
              </sui-table-body>
            </sui-table>
          </div>
        </div>
      </sui-segment>
    </div>
    <template v-slot:action>
      <sui-modal-actions :style="{ ...themePrimaryColor, ...themeFontColor }">
        <sui-button
          :disabled="cannotUpdateSymbol"
          :style="themeSecondaryColor"
          @click.native="onSaveSettings"
          >OK
        </sui-button>
        <sui-button
          @click.native="closeModal"
          style="
            background-color: #414343;
            filter: none;
            border: 1px solid #2e2f2f;
            opacity: 1;
            font-weight: bold;
            color: white;
          "
          >Cancel</sui-button
        >
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapActions, mapGetters } from 'vuex'
import { fetchSymbolByName } from '../../services/symbol'
import { marketsData } from 'Data/markets'
import getChildListeners from 'semantic-ui-vue/src/lib/mixins/SemanticUIVueMixin/getChildListeners'

const categoryList = [
  {
    show: true,
    id: 0,
    desc: '0-9',
    title:'0-9',
    keys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    selected: false,
  },
  { show: true, id: 1, desc: 'A-B',title: 'A-B', keys: ['A', 'B'], selected: false },
  {
    show: true,
    id: 2,
    desc: 'C-F',
    title:'C-F',
    keys: ['C', 'D', 'E', 'F'],
    selected: false,
  },
  {
    show: true,
    id: 3,
    desc: 'G-M',
    title:'G-M',
    keys: ['G', 'H', 'I', 'J', 'K', 'L', 'M'],
    selected: false,
  },
  {
    show: true,
    id: 4,
    desc: 'N-R',
    title:'N-R',
    keys: ['N', 'O', 'P', 'Q', 'R'],
    selected: false,
  },
  {
    show: true,
    id: 5,
    desc: 'S-Z',
    title:'S-Z',
    keys: ['S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selected: false,
  },
  {
    show: true,
    id: 6,
    desc: 'sp',
    title:'@-#-%-&',
    keys: ['@', '#', '%', '&'],
    selected: false,
  },
]
const localMarket = marketsData.map((m) => {
  return { ...m, show: true, categoryList }
})

const getLocalMarketClone = () => {
  return JSON.parse(JSON.stringify(localMarket))
}
export default {
  name: 'SearchSymbol',
  components: {
    DialogBox,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    keyUpSearch(e) {
      const ignoredKeyCodes = [13,35, 36, 37, 38, 39, 40, 16, 17]
      const { keyCode, target } = e
      const searchText = target.value.trim()
      if (searchText === '') {
        if (this.searchMode) {
          this.filteredText(searchText)
        }
        this.searchMode = false
        return
      } else {
        this.searchMode = true
      }

      if (ignoredKeyCodes.indexOf(keyCode) > -1) {
        return
      }
      this.activateSearch = true
      this.filteredText(searchText)
    },
    async onSaveSettings() {
      // this.$emit('change', !open)

      this.onClose()
      this.$emit(
        'selectSymbolModalData',
        this.selectedSymbol + '~' + this.selectedMarket
      )
    },
    getSymbolName(symbol) {
      let res = ''
      let symbolName = symbol.symbol
      if (symbolName) {
        let symbolNameParts = symbolName.replace(".au","").replace(".ca","")
        if (symbolNameParts.length) {
          res = symbolNameParts
        }
      }
      return res
    },
    getCompanyName(symbol) {
      let res = ''
      let companyName = symbol.companyName
      if (companyName) {
        if (companyName.length > 30) {
          res = `${companyName.substring(0, 30)} ...`
        } else {
          res = companyName
        }
      }
      return res
    },
    onClose() {
      this.$emit('change', !open)
    },
    closeModal() {
      if (this.changesNotSaved) {
        this.$store.commit('RESET_CHART_SETTINGS', {
          chartSettings: this.defaultChartSettings,
        })
        this.$store.commit('UPDATE_CHART_STATE', new Date().getTime())
      }
      // this.settings = this.defaultIndicatorSettings
      this.onClose()
    },
    changeMarketState(id, prop, changeProp) {
      this[prop] = this[prop].map((v, i) => {
        if (i === id) {
          v.selected = true
        } else {
          v.selected = false
        }
        return v
      })
      this[changeProp] = true
      this.selectedMarket = this[prop][id].code
      this.symbolData = []
      let filterMarket = this.markets.find(
        (m) => m.code === this.selectedMarket
      )
      if (
        filterMarket &&
        filterMarket.categoryList &&
        filterMarket.categoryList.length
      ) {
        let selectedRange = filterMarket.categoryList[0].desc
        let divRefs = this.$refs[`categoryDiv_${selectedRange}`]
        if (divRefs && divRefs[0]) {
          divRefs[0].click()
        }
      }

      // this.resetCat()
      // if(market)
    },
    async updateContract(id, desc) {
      // this.changeState(id, 'category', 'showContracts')
      //  updateText
      this.selectedRange = desc
      let allMarkets = this.markets
      if (allMarkets && allMarkets.length) {
        let selectedMarket = allMarkets.find(
          (m) => m.code === this.selectedMarket
        )
        if (selectedMarket) {
          let categoryList = selectedMarket.categoryList
          categoryList = categoryList.map((v, i) => {
            if (i === id) {
              v.selected = true
            } else {
              v.selected = false
            }
            return v
          })
          selectedMarket.categoryList = categoryList
          // this.markets =allMarkets.map( m => {
          //   if(m => m.code === this.selectedMarket){
          //     // return {...m,categoryList}
          //     m.categoryList = categoryList
          //   }
          //   return m
          // })
        }
      }

      if (this.filteredtext && this.filteredtext.length) {
        this.activateSearch = false
      }
      await this.filteredText(desc, false)
    },
    clearSymbolSearch() {
      this.filteredtext = ''
      this.filtered2 = []
      this.symbolData = []
      this.activateSearch = true
      this.markets = getLocalMarketClone()
      // this.category= JSON.parse(JSON.stringify(categoryList))
    },
    async filteredText(text, updateText = true) {
      let isRange = !updateText
      if (this.activateSearch) {
        this.loading = true
        let filtered2 = await fetchSymbolByName(
          this,
          text,
          false,
          isRange,
          isRange ? this.selectedMarket : '',
          true,
          true
        )
        if (typeof filtered2 !== 'string') {
          this.filtered2 = filtered2
          this.loading = false
          this.filterData(updateText)
        } else {
          let textIsNotExist = text === null || text.length === 0
          if (textIsNotExist) {
            this.loading = false
            this.clearSymbolSearch()
          }
        }
      } else {
        this.filterData(updateText)
      }

    },
    filterData(updateText) {
      let filterData = this.filtered2
      if (filterData) {
        let selectedRange = this.category.find(
          (d) => d.desc === this.selectedRange
          )
        let selectedMarketData = filterData[this.selectedMarket]
        let filterMarkets = []
        let firstMarketKey
        let firstCategory
        if (updateText) {
          let marketKeys = Object.keys(filterData)
          if (marketKeys.length) {
            for (const marketKey of marketKeys) {
              let marketData = filterData[marketKey]
              if (!firstMarketKey) firstMarketKey = marketKey
              let keys = Object.keys(marketData)

              let localMarketClone = getLocalMarketClone()
              let filterMarket = localMarketClone.find(
                (m) => m.code === marketKey
              )
              if (filterMarket) {
                let categoryList = filterMarket.categoryList
                let filteredCatList = categoryList.filter((m) => {
                  let categoryKeys = m.keys
                  // let result = categoryKeys.includes(keys)
                  let result = categoryKeys.find((element) =>
                    keys.includes(element)
                  )
                  return result
                })
                // filteredCatList = Object.assign({},filteredCatList)
                if (filteredCatList.length) {
                  filterMarket.categoryList = filteredCatList
                  firstCategory = filteredCatList[0].desc
                }

                filterMarkets.push(filterMarket)
              }
            }
          }


          // categoryList.filter(c => )
          this.markets = filterMarkets

          // if(firstCategory)
          // this.selectedRange = firstCategory
        }
        let alphaCategoryData = []
        if (selectedRange) {
          let keys = selectedRange.keys
          for (const selectedMarketDataKey in selectedMarketData) {
            if (keys.includes(selectedMarketDataKey)) {
              alphaCategoryData.push(
                ...selectedMarketData[selectedMarketDataKey]
              )
            }
          }
        }

        this.symbolData = alphaCategoryData
        this.showCategory = true
        this.showContracts = true

        if (firstMarketKey) {
          this.selectedMarket = firstMarketKey
          setTimeout(() => {
            this.selectMarketRef(this.selectedMarket)
          }, 300)
        }
      }
    },
    symbolChange(id, obj) {
      this.selectedSymbol = obj.symbol
      this.symbolData = this.symbolData.map((v, i) => {
        if (i === id) {
          v.selected = true
          this.cannotUpdateSymbol = false
        } else {
          v.selected = false
        }
        return v
      })
    },
    selectMarketRef(selectedMarket) {
      let marketRef = this.$refs[`marketDiv_${selectedMarket}`]
      if (marketRef && marketRef[0]) {
        marketRef[0].click()
      }
    },
  },
  data() {
    return {
      cannotUpdateSymbol: true,
      searchMode: false,
      activateSearch: true,
      loading: false,
      filtered2: [],
      filteredtext: '',
      selectedMarket: 'ASX',
      selectedRange: '0-9',
      selectedSymbol: '',
      markets: getLocalMarketClone(),
      category: JSON.parse(JSON.stringify(categoryList)),
      symbolData: [
        // { code: 'AAF', name: 'Symbol Name', selected: false },
      ],
      userData: {},
      multiItems: 0,
      changesNotSaved: false,
      showCategory: true,
      showContracts: false,
      theme_type: 1,
      selected_theme: 1,
      darkBackColor: 'white',
      darkFontColor: 'black',
      darkBorderColor: '#eee',
    }
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.darkBackColor = '#505050'
        this.darkFontColor = '#A2A2A2'
        this.darkBorderColor = '#505050'
      } else {
        this.darkBackColor = 'white'
        this.darkFontColor = 'black'
        this.darkBorderColor = '#eee'
      }
    },
    open(n, o) {
      if (n) {
        this.selectMarketRef(this.selectedMarket)
      }
    },
  },
  computed: {
    ...mapGetters([
      'getUserSettingData',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'themeDarkMode',
    ]),
    categoryByMarket() {
      if (this.markets && this.markets.length) {
        let selectedCategory = this.markets.find(
          (m) => m.code === this.selectedMarket
        )
        return selectedCategory && selectedCategory.categoryList
          ? selectedCategory.categoryList
          : []
      }
      return []
    },
  },
  mounted() {
  },
}
</script>

<style>
.symbolList {
  display: flex;
  justify-content: space-between;
}

.symbolList button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 100;
  width: 100%;
  font-size: 12px;
}

.symbolList button:hover {
  background-color: red;
}

.selected-chart {
  /* background-color: red; */
  color: #ffffff !important;
  border-bottom: 0px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.header {
  font-size: 12.3px;
}

.ui.fluid.input > input {
  background-color: v-bind(darkBackColor);
  color: v-bind(darkFontColor);
  border: 1px solid v-bind(darkBorderColor);
}
</style>
