<template>
  <dialog-box :scanName="scanName" :modalHeader="header" :open="open" :action="false"
    :deleteFavScanItem="deleteFavScanItem" :showDeleteDialog="showDeleteDialog" @saveScanSettings="saveScanSettings"
    @SureDeleteFavScanItem="SureDeleteFavScanItem" @HideDeletePopup="HideDeletePopup" class="fontSize">
    <div v-bind:style="customStyle" class="fontSize" :class="{ 'dimColor': isLoading && themeDarkMode }">
      <sui-form :loading="isLoading" size="mini">
        <slot />
        <div v-if="scanName !== 'Sams Pattern'">
        <sui-header dividing :style="themeFontColor" class="fontSize">Filters</sui-header>
        <div class="input-container fontSize" v-if="scanName !== 'Fundamentals'">
          <base-check-box label="" v-model="settings.crossesAndAboveBelow.isActive" @click="handleUserChoice" />
          <select type="select" v-model="settings.crossesAndAboveBelow.left" :style="selectWidth"
            @change="handleUserChoice">
            <option value="close">Close</option>
            <option value="ma">MA</option>
            <option value="wma">WMA</option>
            <option value="xma">XMA</option>
          </select>

          <sui-input @input="handleUserChoice" :style="selectWidth"
            v-if="settings.crossesAndAboveBelow.left !== 'close'" type="number" tabindex="1" class="hidden"
            v-model="settings.crossesAndAboveBelow.leftPeriod"  @keypress="preventDecimal"/>

          <select type="select" v-model="settings.crossesAndAboveBelow.operatorType" :style="selectWidth"
            @change="handleUserChoice">
            <option value="above">is Above</option>
            <option value="below">is Below</option>
            <option value="crossAbove">Crossed Above</option>
            <option value="crossBelow">Crossed Below</option>
          </select>

          <select type="select" v-model="settings.crossesAndAboveBelow.right" :style="selectWidth"
            @change="handleUserChoice">
            <option value="close">Close</option>
            <option value="ma">MA</option>
            <option value="wma">WMA</option>
            <option value="xma">XMA</option>
          </select>

          <sui-input :style="[
              selectWidth,
              themeDarkMode ? { 'background-color': '#505050' } : {},
            ]" 
            v-if="settings.crossesAndAboveBelow.right !== 'close'" type="number" tabindex="1" class="hidden" v-model="settings.crossesAndAboveBelow.rightPeriod" @input="handleUserChoice" @keypress="preventDecimal" />
        </div>
        <!-- 1st filter -->
        <div class="input-container fontSize">
          <base-check-box @click="handleUserChoice" label="Price range in dollars from"
            v-model="settings.priceRangeDollar.isActive" />
          <sui-input type="number" tabindex="1" class="hidden" :style="[
    textBoxStyle,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" v-model="settings.priceRangeDollar.start" @input="handleUserChoice" />
          <label>to</label>
          <sui-input type="number" tabindex="1" class="hidden" :style="[
    textBoxStyle,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" v-model="settings.priceRangeDollar.end" @input="handleUserChoice" />
        </div>

        <!-- 2nd filter -->
        <div class="input-container fontSize">
          <base-check-box label="Ignore results that have less than" v-model="settings.ignoreResults.isActive"
            @click="handleUserChoice" />
          <sui-input type="number" tabindex="1" class="hidden" v-model="settings.ignoreResults.minDays" :style="[
    textBoxStyle,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" @input="handleUserChoice" @keypress="preventDecimal"/>
          <span>day(s) of data</span>
        </div>

        <!-- 3rd filter -->
        <div class="input-container fontSize">
          <base-check-box label="Ignore results that have no trading range for the last day"
            v-model="settings.isIgnoreResultsWithNoRangeForLastDay" @click="handleUserChoice" />
        </div>

        <!-- 4th filter -->
        <div class="input-container fontSize">
          <base-check-box label="Only show results that have traded in the past"
            v-model="settings.onlyShowResultsThatHaveTraded.isActive" @click="handleUserChoice" />
          <sui-input type="number" tabindex="1" class="hidden" v-model="settings.onlyShowResultsThatHaveTraded.pastDays"
            :style="[
    textBoxStyle,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" @input="handleUserChoice"  @keypress="preventDecimal"/>
          <label>day(s)</label>
        </div>

        <!-- 5th filter -->
        <div class="input-container fontSize">
          <base-check-box label="Ignore results that have value less than"
            v-model="settings.ignoreResultsWithValueLess.isActive" @click="handleUserChoice" />
          <sui-input type="number" tabindex="1" class="hidden" v-model="settings.ignoreResultsWithValueLess.value"
            :style="[
    textBoxStyle,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" @input="handleUserChoice" />
        </div>

        <!-- 6th filter -->
        <div class="input-container fontSize">
          <base-check-box label="Ignore results that have volume less than"
            v-model="settings.ignoreResultsWithVolumeLess.isActive" @click="handleUserChoice" />
          <sui-input type="number" tabindex="1" class="hidden" v-model="settings.ignoreResultsWithVolumeLess.volume"
            :style="[
    textBoxStyle,
    themeDarkMode ? { 'background-color': '#505050' } : {},
  ]" @input="handleUserChoice" />
        </div>

        <!-- 6th filter -->
        <div class="input-container fontSize">
          <base-check-box label="Exclude intra-day data" v-model="settings.isExcludeIntraDayData"
            @click="handleUserChoice" />
        </div>
        </div>
        <!-- data settings -->
        <data-settings :id="id" type="scanner" />
      </sui-form>
    </div>

    <template v-slot:action>
      <sui-modal-actions :style="{ ...themePrimaryColor, ...themeFontColor }">
        <div class="bottom_layout bottom_layout_Scan">
          <div>
            <ScanBottomForm :valid="valid" :scanType="scanType" :hideFav="hideFav"
              @changeScanSettings="changeScanSettings" @deletedScan="deletedScan"
              @ResetScanDefaultSettings="ResetScanDefaultSettings" />
          </div>
          <div :style="hideFav ? 'display:flex;  justify-content: space-between; width:100%' : ''" class="">
            <div>
              <sui-button icon="undo" key="onResetGurus" secondary @click="ResetScanDefaultSettings"
                v-if="showResetter" />
            </div>
            <div>
              <sui-button :disabled="!valid" :style="themeSecondaryColor" @click.native="stopScan" v-if="isLoading">
                Stop
              </sui-button>
              <sui-button style="color: white" :disabled="!valid" :style="themeSecondaryColor" @click.native="startScan"
                v-if="!isLoading">
                Scan
              </sui-button>
              <sui-button @click.native="onClose" secondary>
                Cancel
              </sui-button>
            </div>
          </div>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import DataSettings from '../DataSettings.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseCheckBox from '@/baseComponents/BaseCheckBox.vue'
import ScanBottomForm from './ScanBottomForm.vue'

export default {
  name: 'PatternBaseModal',
  components: {
    DialogBox,
    DataSettings,
    BaseCheckBox,
    ScanBottomForm,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'Patterns',
    },
    scanName: {
      type: String,
      default: "",
    },
    scanType: {
      type: String,
      default: '',
    },
    hideFav: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: 'height: 25vh;',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showResetter: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        this.inputFontColor = '#A2A2A2'
        this.inputBorderColor = '1px solid blue'
      } else {
        this.inputBackColor = 'white'
        this.inputFontColor = this.themeFontColor.color
        this.inputBorderColor = '1px solid blue'
      }
    },
  },
  methods: {
    ...mapActions([
      'setScanFilterSettings',
      'setScanFilterSettingsOld',
      'resetGurusSettings',
      'getUserScanList',
    ]),
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
  } ,
    SureDeleteFavScanItem(check) {
      this.deleteFavScan = check
      this.deletedScan()
    },
    HideDeletePopup() {
      this.showDeleteDialog = false
    },
    deletedScan(selectedScanName) {
      this.deleteFavScanItem = selectedScanName
      this.showDeleteDialog = true
      if (this.deleteFavScan) {
        this.$emit('deletedScan', this.favSelectedScanId)
      }
      this.deleteFavScan = false
    },
    onClose() {
      this.$emit('onClose')
    },
    stopScan() {
      this.$emit('stop')
    },
    startScan() {
      this.$emit('ok')
    },
    saveScanSettings(name) {
      this.$emit('saveScanSettings', name)
    },
    changeScanSettings(val) {
      this.$emit('changeScanSettings', val)
    },
    ResetScanDefaultSettings() {
      this.$emit('ResetScanDefaultSettings')
    },
    onResetGurus() {
      this.resetGurusSettings()
    },
  },
  data() {
    return {
      inputFontColor: 'black',
      inputBorderColor: '1px solid blue',
      inputBackColor: 'white',
      deleteFavScan: false,
      showDeleteDialog: false,
      deleteFavScanItem: '',
      settingsData: {
        crossesAndAboveBelow: {
          isActive: false,
          left: 'close',
          leftPeriod: 14,
          operatorType: 'above',
          right: 'ma',
          rightPeriod: 14,
        },
        priceRangeDollar: {
          isActive: false,
          start: 0,
          end: 0,
        },
        ignoreResults: {
          isActive: false,
          minDays: 280,
        },
        isIgnoreResultsWithNoRangeForLastDay: false,
        onlyShowResultsThatHaveTraded: {
          isActive: false,
          pastDays: 10,
        },
        ignoreResultsWithValueLess: {
          isActive: false,
          value: 25000,
        },
        ignoreResultsWithVolumeLess: {
          isActive: false,
          volume: 25000,
        },
        isExcludeIntraDayData: false,
      },
    }
  },

  computed: {
    ...mapGetters([
      'filterSettingsForScan',
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'favSelectedScanId',
      'userChoice',
      'themeDarkMode',
    ]),
    modalID() {
      return this.$props.id
    },
    textBoxStyle() {
      return 'width: 10%'
    },
    settings: {
      get() {
        if (this.modalID) {
          let filterSettingsForScan = this.filterSettingsForScan(this.modalID)
          if (!filterSettingsForScan) {
            let value = JSON.parse(JSON.stringify(this.settingsData))
            this.setScanFilterSettings({
              id: this.modalID,
              settings: value,
            }).then((d) => {
              return this.filterSettingsForScan(this.modalID)
            })
          }
          return filterSettingsForScan || this.settingsData
        }

        return this.filterSettings || this.settingsData
      },
      set(value) {
        if (this.modalID) {
          this.setScanFilterSettings({ id: this.modalID, settings: value })
        } else this.setScanFilterSettingsOld(value)
      },
    },
    selectWidth() {
      return 'width: 12%'
    },
  },
}
</script>

<style scoped>
.input-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 13.5px;
}

.input-container>* {
  margin-right: 0.3rem;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fontSize {
  font-size: 13.5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 870px) {
  .fontSize {
    font-size: 11px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 510px) {
  .fontSize {
    font-size: 10px;
    margin-bottom: 7px;
  }
}

@media screen and (max-width: 456px) {
  .bottom_layout_Scan {
    align-items: center;
    /* background: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.bottom_layout {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.dimColor {
  opacity: 0.08;
}
</style>
