<template>
  <div v-if="label === 'Multiple Inside Bar'">
    <div class="image-check-box-container">
      <i class="icon">
        <component class="imgg" :is="componentRef" v-if="componentRef" :color="themeDarkMode ? '#9b9f9b' : '#000000'" />
      </i>
      <base-check-box label="Scan for " v-model="checked" @click="handleUserChoice" />
      <sui-input type="number" tabindex="1" class="hidden"
        :style="[themeDarkMode ? { 'background-color': '#505050' } : {}]" style="width: 10%"
        @input="handleUserChoice"  v-model="multipleVal"  @keypress="preventDecimal"/>
      <span> Multiple Inside Bars</span>
    </div>
  </div>
  <div v-else-if="label === 'Gt Multiple Inside Bar'">
    <div class="image-check-box-container">
      <i class="icon">
        <component class="imgg" :is="componentRef" v-if="componentRef" :color="themeDarkMode ? '#9b9f9b' : '#000000'" />
      </i>
      <base-check-box label="Scan for > " v-model="checked" @click="handleUserChoice" />
      <sui-input type="number" tabindex="1" class="hidden"
        :style="[themeDarkMode ? { 'background-color': '#505050' } : {}]" style="width: 10%"
        @input="handleUserChoice"  v-model="gtMultipleVal" @keypress="preventDecimal"/>
      <span> Inside Bars</span>
    </div>
  </div>
  <div v-else>
    <div class="image-check-box-container">
      <i class="icon">
        <component class="imgg" :is="componentRef" v-if="componentRef" :color="themeDarkMode ? '#9b9f9b' : '#000000'" />
        <!--      <img class="imgg" :src="path" />-->
      </i>
      <base-check-box v-if="haveCheck" v-model="checked" :label="label" @click="handleUserChoice" />
    </div>
  </div>
</template>

<script>
// style="max-height: 100%; max-width: 100%; "
import SvgIconList from "./index";
import BaseCheckBox from 'BaseComponents/BaseCheckBox.vue'
import { mapGetters } from 'vuex'

export default {
  components: { BaseCheckBox },
  name: 'CustomImageCheckBox',
  methods: {
    handleUserChoice() {
      if (this.userChoice) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
    },
  },
  props: {
    srcResolutionFunction: {
      type: Function,
    },
    label: {
      type: String,
      default: '',
    },
    updateState: {
      type: Function,
    },
    updateMultipleInsideInputState: {
      type: Function,
    },
    fromProps: {
      type: Boolean,
    },
    fromPropsMultipleInput: {
      type: Number,
    },
    fromPropsGtMultipleInput: {
      type: Number,
    },
    haveCheck: {
      type: Boolean,
      default: true
    },
    
  },
  data() {
    return {
      checked: false,
      multipleVal:2,
      gtMultipleVal:5,
      componentRef: undefined,
    }
  },
  watch: {
    checked(newValue) {
      this.updateState(this.label, newValue)
    },
    multipleVal(newVal){
      this.updateMultipleInsideInputState('multipleInsideBarInput',newVal)
    },
    gtMultipleVal(newVal){
      this.updateMultipleInsideInputState('gtMultipleInsideBarInput',newVal)
    },
    fromProps(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checked = newVal
      }
    },
    
    fromPropsMultipleInput(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.multipleVal = newVal
      }
    },
    fromPropsGtMultipleInput(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.gtMultipleVal = newVal
      }
    },
  },
  computed: {
    ...mapGetters(['userChoice', 'themeDarkMode']),
    path() {
      // let definePath = `./${srcResolutionFunction}.vue`;
      return this.srcResolutionFunction(this.label)
    },
  },
  async mounted() {
    this.componentRef = SvgIconList.find(d => d.name === this.path)
  }
}
</script>

<style>
.image-check-box-container {
  display: flex;
  align-items: center;
}

.image-check-box-container>* {
  /* margin-left: 0.5rem; */
}

.imgg {
  width: 12px;
  height: 12px;
}

@media screen and (max-width: 360px) {
  .imgg {
    width: 8px;
    height: 10px;
  }
}
</style>
