<template>
  <pattern-base-modal
    class="Chart-Pattern-imgs"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    @deletedScan="deletedScan"
    @changeScanSettings="changeScanSettings"
    @ResetScanDefaultSettings="ResetScanDefaultSettings"
    header="Chart Patterns"
    scanName="Chart Patterns"
    :scanType="id"
    :id="id"
    :valid="isValid"
    customStyle="height: max-content; width: 100%"
    :isLoading="isLoading"
  >
    <multiple-checker
      class="fontSize"
      @selectAll="updateAllSelections(true)"
      @clearAll="updateAllSelections(false)"
    />
    <sui-grid divided="vertically" :columns="3" class="fontSize">
      <sui-grid-column>
        <CustomSvgCheckBox
          class="fontSize"
          v-for="checkBox of col1"
          :key="checkBox"
          :label="checkBox"
          :srcResolutionFunction="srcResolutionFunction"
          :updateState="updateCheckedPatterns"
          :fromProps="settings[toCamelCase(checkBox)]"
        />
      </sui-grid-column>

      <sui-grid-column>
        <CustomSvgCheckBox
          class="fontSize"
          v-for="checkBox of col2"
          :key="checkBox"
          :label="checkBox"
          :srcResolutionFunction="srcResolutionFunction"
          :updateState="updateCheckedPatterns"
          :fromProps="settings[toCamelCase(checkBox)]"
        />
      </sui-grid-column>

      <sui-grid-column>
        <CustomSvgCheckBox
          class="fontSize"
          v-for="checkBox of col3"
          :key="checkBox"
          :label="checkBox"
          :srcResolutionFunction="srcResolutionFunction"
          :updateState="updateCheckedPatterns"
          :fromProps="settings[toCamelCase(checkBox)]"
        />
      </sui-grid-column>
    </sui-grid>

    <!-- events -->
    <events-filter
      @isValid="onEventsSelect"
      :dialogId="dialogId"
      class="fontSize"
      :id="id"
    />
  </pattern-base-modal>
</template>

<script>
const DEFAULT_SETTINGS = {
  saucerBottom: false,
  doubleBottom: false,
  bearishKeyReversal: false,
  bullishPennant: false,
  bearishFlag: false,
  ascendingTriangle: false,
  doubleTop: false,
  triPointResistance: false,
  bearishPennant: false,
  descendingTriangle: false,
  bullishKeyReversal: false,
  triPointSupport: false,
  bullishFlag: false,
}
import CustomImageCheckBox from 'BaseComponents/CustomImageCheckBox.vue'
import CustomSvgCheckBox from '../../../svg_icon/CustomSvgCheckBox.vue'
import PatternBaseModal from './PatternBaseModal.vue'
import EventsFilter from './EventsFilter'
import { mapActions, mapGetters } from 'vuex'
import MultipleChecker from '../../../baseComponents/MultipleChecker.vue'
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_EVENT_SETTINGS,
  DEFAULT_FILTER_SETTINGS,
} from '../../../settings/USER_SETTINGS_DEFAULT'

export default {
  components: {
    PatternBaseModal,
    CustomImageCheckBox,
    CustomSvgCheckBox,
    EventsFilter,
    MultipleChecker,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'ChartPatterns',
  methods: {
    ...mapActions([
      'updateMarketAccordingTODataSettings',
      'setPatternsScanSettings',
      'setSelectedMarket',
      'sendPatternsScanRequest',
      'cancelScans',
      'saveScannersScanRequest',
      'deleteScanSettings',
      'getUserScanList',
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendPatternsScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    changeScanSettings(val) {
      this.updateMarketAccordingTODataSettings({ ...val, id: this.id })
      this.settings = val.settings
    },
    async deletedScan(selectedScan) {
      await this.deleteScanSettings(selectedScan)
      this.$store.commit('SCAN_DELETE_POPUP', false)
    },
    ResetScanDefaultSettings() {
      let val = {
        dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
      }
      this.updateMarketAccordingTODataSettings({ ...val, id: this.id })
      this.settings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    async saveScanSettings(name) {      
      await this.saveScannersScanRequest({
        scanName:name,
        scanType:'patterns'
      })
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
    onScanStop() {
      this.cancelScans()
      this.isLoading = false
    },
    updateAllSelections(state) {
      this.settings = {
        saucerBottom: state,
        doubleBottom: state,
        bearishKeyReversal: state,
        bullishPennant: state,
        bearishFlag: state,
        ascendingTriangle: state,
        doubleTop: state,
        triPointResistance: state,
        bearishPennant: state,
        descendingTriangle: state,
        bullishKeyReversal: state,
        triPointSupport: state,
        bullishFlag: state,
      }
      this.handleUserChoice()
    },
    srcResolutionFunction(label) {
      return label
        .replace('-', '_')
        .split(' ')
        .reverse()
        .map((w) => w.toLowerCase())
        .reduce((curr, prev) => (prev && curr ? `${prev}_${curr}` : prev), '')
    },
    toCamelCase(name) {
      const pascal = name.replace('-', '').replaceAll(' ', '')
      return pascal[0].toLowerCase() + pascal.substring(1)
    },
    updateCheckedPatterns(label, state) {
      // this.checkedPatterns = { ...this.checkedPatterns, [label]: state }
      this.settings[this.toCamelCase(label)] = state
    },
    onEventsSelect(value) {
      this.isEventsSelected = value
    },
  },
  data() {
    return {
      inputBackColor: 'white',
      id: 'patterns',
      isLoading: false,
      dialogId: 'chartEvent',
      col1: [
        'Saucer Bottom',
        'Double Bottom',
        'Bearish Key Reversal',
        'Bullish Pennant',
        'Bearish Flag',
      ],
      col2: [
        'Ascending Triangle',
        'Double Top',
        'Tri-Point Resistance',
        'Bearish Pennant',
      ],
      col3: [
        'Descending Triangle',
        'Bullish Key Reversal',
        'Tri-Point Support',
        'Bullish Flag',
      ],
      isEventsSelected: false,
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
    }
  },
  computed: {
    /** @returns {Boolean} */
    ...mapGetters(['userChoice', 'themeDarkMode']),
    isValid() {
      return (
        this.isEventsSelected ||
        Object.values(this.settings).some((state) => state)
      )
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        // this.inputFontColor = "#A2A2A2"
      } else {
        this.inputBackColor = 'white'
        // this.inputFontColor = this.themeFontColor.color
      }
    },
    settings: {
      handler: function (newSettings) {
        this.setPatternsScanSettings(newSettings)
      },
      deep: true,
    },
  },
}
</script>

<style>
.ui.input > input {
  background-color: v-bind(inputBackColor) !important;
  /* color:v-bind(inputFontColor) !important; */
}
.ui.form select {
  background-color: v-bind(inputBackColor) !important;
  /* color:v-bind(inputFontColor) !important; */
}
/* option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
input{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
} */

/*  */

/*input[type="radio"] {
  accent-color: #e72510 !important;
} */

.fontSize {
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width: 870px) {
  .fontSize {
    font-size: 11px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 510px) {
  .fontSize {
    font-size: 10px;
    margin-bottom: 7px;
  }
}
</style>

<style scoped>
.input-container {
  font-size: 10px !important;
}
</style>
