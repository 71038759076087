<script>
import { Overlay, Tool } from 'trading-vue-js'
import Pin from './primitives/pin'
import Math2 from './primitives/math.js'
import { mapGetters } from 'vuex'

import ToolSavingMixins from 'Tools/ToolSavingMixins'
export default {
  name: 'TextTool',
  mixins: [Overlay, Tool, ToolSavingMixins],
  methods: {
    meta_info() {
      return { author: 'LakshanPerera', version: '1.0.0' }
    },
    tool() {

      let prevSettings = localStorage.getItem('FibonacciRetrace')
      if (prevSettings) {
        prevSettings = JSON.parse(prevSettings)
      }
      //{"color":"#2346A757","lineWidth":2,"lineStyle":"dashed","lineColor":"#6BA8ECFF"}
      const defaultColor = prevSettings?.color || '#23a776'
      const defaultFontSize = prevSettings?.fontSize || 20
      return {
        // Descriptor for the tool
        group: 'Text', //icon: Icons['segment.png'],
        type: 'Label',
        hint: 'Text label',
        data: [], // Default data
        settings: {
          text: 'Text goes here',
          x: 50,
          y: 50,
          color: defaultColor,
          fontSize: defaultFontSize,
          settingsList: ['color', 'fontSize', 'settings'],
          firstRun: true,
        }, // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      this.pins.push(new Pin(this, 'p1'))
      this.registerMouseEvent(this)
      this.set_state('finished')
      this.$emit('drawing-mode-off')

      
      if (this.$props.settings.firstRun) {
        this.$emit('object-selected')
      }
      // this.$emit('object-selected')
    },
    /**
     * @param {CanvasRenderingContext2D} ctx
     */
    draw(ctx) {
      if (!this.p1) return

      const { t2screen, $2screen } = this.layout
      const fontSize = this.sett.fontSize || 20
      const fontColor = this.sett.color || 'dodgerblue'
      const fontFamily = 'Arial'
      const text = this.sett.text || 'Text goes here!'

      const offset = 5
      const x = t2screen(this.p1[0]) || this.sett.x
      const y = ($2screen(this.p1[1]) || this.sett.y) + fontSize + offset

      // draw text
      ctx.font = `${fontSize}px ${fontFamily}`
      ctx.fillStyle = fontColor

      let lineHeight = fontSize
      const lines = text.split('\n')
      let width = 0
      for (let i = 0; i < lines.length; i++) {
        ctx.fillText(lines[i], x, y + i * lineHeight)
        const textMeasures = ctx.measureText(lines[i])
        width = Math.max(textMeasures.width, width)
        // lineHeight = textMeasures.actualBoundingBoxAscent
      }
      // ctx.fillText(text, x, y)
      if (this.showBackPanel) {
        ctx.globalAlpha = 0.5
        ctx.fillRect(
          x - offset,
          y - lineHeight - offset,
          width + offset,
          (lineHeight + offset) * lines.length
        )
      }
      // y is the bottom point of the text
      this.collisions.push(
        this.make(
          [x - offset, y - lineHeight - offset],
          [x + width, y + (lineHeight + offset) * Math.max(lines.length - 1, 0)]
        )
      )

      this.render_pins(ctx)
    },
    use_for() {
      return ['TextTool']
    },
    make(p1, p2) {
      return (x, y) => {
        return Math2.is_within_rectangle([x, y], p1, p2)
      }
    },
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters([
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
    ]),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    color() {
      return this.sett.color || '#42b28a'
    },
    text() {
      return this.sett.text || 'Text goes here'
    },
    showBackPanel() {
      return false
    },
  },
  created() {
    this.updateSettingsState(this)
  },
}
</script>
