<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="none"
    class="rotate-180"
  >
    <path
      stroke="currentColor"
      d="M4.5 13.5H24m-19.5 0L8 17m-3.5-3.5L8 10"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'DottedLine'
}
</script>

<style>
.rotate-180 {
  transform: rotate(180deg);
  pointer-events: none;
}
</style>