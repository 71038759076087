export { symbolIndexes } from './symbol'
export { TimeFrameMap } from './timeFrameMapping'
const CompareSymbolColorMap = { 
    SubColors: [
        "#2780E3",
        "#FF7518",
        "#9954BB",
        "#3FB632",
        "#FF0039"
    ],
    MainColor:"#2780E3"
}
export {CompareSymbolColorMap}
