<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
    <path stroke="currentColor" d="M4 13.5h20"></path>
  </svg>
</template>

<script>
export default {
  name: 'SolidLine'
}
</script>