<template>
  <div
    class="wrapper"
    style="width: 100%; height: 100%"
    v-click-outside="onClickOutside"
    v-if="isSettingsPicker"
  >
    <sui-button
      @click.prevent="open = !open"
      size="mini"
      icon="eye dropper"
      :style="style"
    />
    <div style="position: absolute; z-index: 100001" v-if="open">
      <sketch-picker
        :value="value"
        @input="onUpdateColor"
        :preset-colors="presetColors"
      ></sketch-picker>
    </div>
  </div>
  <div
    class="wrapper"
    style="width: 100%; height: 100%"
    v-click-outside="onClickOutside"
    v-else
  >
    <div @click.prevent="open = !open" class="btn-color" :style="style"></div>
    <div style="position: absolute; z-index: 100001" v-if="open">
      <sketch-picker
        :value="value"
        @input="onUpdateColor"
        :preset-colors="presetColors"
      ></sketch-picker>
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
export default {
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    isSettingsPicker: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    fieldRef: {
      type: String,
    },
    type: {
      type: String,
    },
    invisibleBackground: {
      type: Boolean,
    },
  },
  data() {
    return {
      color: {},
      borderColor: {},
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      open: false,
    }
  },
  methods: {
    onUpdateColor(color) {
      
      const { r, g, b, a } = color.rgba
      this.$emit('input', color.hex8, this.fieldRef, this.type)
    },
    onUpdateborderColor(borderColor) {
      
      const { r, g, b, a } = borderColor.rgba
      this.$emit('input', borderColor.hex, this.fieldRef, this.type)
    },
    onClickOutside(event) {
      this.open = false
    },
  },
  computed: {
    /** @returns {String} */
    style() {
      if (this.invisibleBackground) return 'width: 100%; height: 100%'
      return `background: ${this.value}`
    },
    /** @returns {[String]} */
    presetColors() {
      return [
        '#2780e3',
        '#6ba8ec',
        '#165ba8',
        '#ff7518',
        '#ffa365',
        '#cb5200',
        '#9954bb',
        '#ba8bd1',
        '#6f378b',
        '#dee327',
        '#e8ec6b',
        '#a4a816',
        '#ff0039',
        '#ff4d74',
        '#b30028',
        '#3fb618',
        '#61e436',
        '#28720f',
        '#000001',
        '#242425',
        '#48484a',
        '#6c6c6f',
        '#909094',
        '#b4b4b9',
        '#d8d8de',
        '#ffffff',
      ]
    },
  },
  created() {
    
  },
}
</script>

<style scoped>
.btn-color {
  width: 42px;
  height: 42px;
  border-radius: 0.5rem;
  cursor: pointer;
}
.wrapper {
  position: relative;
}
</style>
