<template>
  <div
    class="f-h"
    :style="
      themeDarkMode
        ? {
            background: '#151515 !important',
            borderColor: '#505050',
            color: '#A2A2A2',
          }
        : {}
    "
  >
    <!-- <error-dialog
      :open="isDialogOpen"
      :on-click-yes="onClickYes"
      :on-click-no="onClickNo"
    /> -->
    <router-view />
  </div>
</template>

<script>
import HomeComponent from './components/HomeComponent.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  forgotLogout,
  logout,
  userHttp,
  MyService,
  onGetError,
} from './services/user'
import ErrorDialog from './components/ErrorDialog.vue'
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      inputBackColor: 'white',
      SelectedItem: 'white',
      // isDialogOpen: false,
    }
  },
  components: {
    HomeComponent,
    ErrorDialog,
  },
  computed: {
    ...mapGetters(['themeSettings', 'themeDarkMode']),
  },
  mounted() {
    if (this.themeDarkMode) {
      this.$store.commit('UPDATE_DEFAULT_THEME', 'dark')
    } else {
      this.$store.commit('UPDATE_DEFAULT_THEME', 'classic')
    }
    // userHttp.interceptors.response.use(null, (error) => {
    //   if (error.response && error.response.status === 550) {
    //     try {
    //       this.openErrorDialog()
    //     } catch (error) {
    //     }
    //   } else {
    //     onGetError(error)
    //   }
    // })
  },

  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        this.inputFontColor = '#A2A2A2'
        this.SelectedItem = 'white'
      } else {
        this.inputBackColor = 'white'
        this.inputFontColor = 'black'
        this.SelectedItem = 'black'

      }
    },
  },
  methods: {
    // openErrorDialog() {
    //   this.isDialogOpen = true
    // },
    // onClickYes() {
    //   logout()
    //   this.isDialogOpen = false
    // },
    // onClickNo() {
    //   debugger
    //   this.isDialogOpen = false
    //   forgotLogout()
    //   this.$router.push({ path: 'forget-password' })
    // },
  },
}
</script>

<style>
#app {
  user-select: none;
}
.menu,
.transition option {
  background-color: v-bind(inputBackColor) !important;
  /* color:v-bind(inputFontColor) !important; */
}
.ui.selection.dropdown {
  color: v-bind(inputFontColor) !important;
}

.ui.selection.dropdown .menu > .item {
  color: v-bind(SelectedItem) !important;
}
/* .text{
  color:v-bind(SelectedItem) !important;

} */

input[type='number']:hover::-webkit-inner-spin-button,
input[type='number']:hover::-webkit-outer-spin-button {
  opacity: 0.4;
}

input[type='number']:focus::-webkit-inner-spin-button,
input[type='number']:focus::-webkit-outer-spin-button {
  opacity: 0.4;
}

/* .ui.selection.dropdown.visible{
  color:red !important;
} */
.ui.form select {
  background-color: v-bind(inputBackColor) !important;
  color: v-bind(inputFontColor) !important;
}

option {
  background-color: v-bind(inputBackColor) !important;
  color: v-bind(inputFontColor) !important;
}
select {
  background-color: v-bind(inputBackColor) !important;
  color: v-bind(inputFontColor) !important;
}
input[type='number'] {
  background-color: v-bind(inputBackColor) !important;
  color: v-bind(inputFontColor) !important;
  /* border: v-bind(inputBorderColor) !important; */
  box-shadow: inset 0 0 0 0 transparent;
}
</style>
